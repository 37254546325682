import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IResumenReembolso } from '@common/interfaces/IResumenReembolso';
import { formateoValor } from '@common/utils/utils';
import { ReembolsoActualService } from '@common/services/reembolso-actual.service';

@Component({
  selector: 'app-summary-table',
  templateUrl: './summary-table.component.html',
  styleUrls: ['./summary-table.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SummaryTableComponent implements OnInit {
  @Input() resumenesReembolsos: IResumenReembolso[] = [];
  public formatearValor: (valor: number) => string = formateoValor;
  public montoTotalSolicitado = 0;

  constructor(private reembolso: ReembolsoActualService) {}

  ngOnInit(): void {
    this.resumenesReembolsos = [];
    this.montoTotalSolicitado = 0;

    this.reembolso.reembolsos.forEach((reembolso) => {
      this.resumenesReembolsos.push(reembolso.reembolsoResumen);
      this.montoTotalSolicitado += reembolso.reembolsoResumen.montoSolicitado;
    });
  }

  trackByReembolso(_: number, reembolso: IResumenReembolso): string {
    return `${reembolso.nombrePrestacion}_${reembolso.fecha.toISOString()}_${
      reembolso.valorPrestaciones
    }_${reembolso.montoSolicitado}`;
  }
}
