<div class="contenedor">
  <div class="archivo">
    <div class="doc_name_file" *ngIf="!reingreso.esReingreso">
      <label class="archivo__nombre">{{ tipoArchivo.nombre }}</label>
      <label class="archivo__tipo" *ngIf="tipoArchivo.esRequerido; else opcional">&nbsp;(Obligatorio)</label>
      <ng-template #opcional>
        <label class="archivo__tipo">&nbsp;(Opcional)</label>
      </ng-template>
    </div>
    <div class="archivo__contenido">
      <img class="icono" src="assets/svgs/subir-archivo.svg" />
      <button class="boton" (click)="abrirCargaArchivos()">
        Adjuntar documento
      </button>
      <label class="contenido__descripcion">
        Adjunta un archivo (jpg, jpeg, png, heic o pdf) que no supere los 15MB
      </label>
    </div>
    <label class="archivo__input">
      <input
        [id]="'input__' + tipoArchivo.idTipoArchivo"
        class="input"
        [multiple]="!tipoArchivo.esUnico"
        type="file"
        accept=".jpg,.jpeg,.png,.pdf,.heic"
        (change)="enSubidaDeArchivos($event.target)"
      />
    </label>
  </div>

  <div class="adjuntos" *ngIf="listadoArchivos.length !== 0">
    <label class="adjuntos__titulo">Archivos adjuntos</label>
    <ng-container *ngFor="let archivo of listadoArchivos; trackBy: trackByArchivo">
      <div class="adjuntos__informacion">
        <img class="icono" src="assets/svgs/eye-preview.svg" (click)="togglePreview(true, archivo)" />
        <label class="adjuntos__nombre">{{ archivo.nombreArchivo }}</label>
        <img class="icono" src="assets/svgs/trash-eliminar.svg" (click)="eliminarArchivo(archivo.idArchivoSubido)" />
      </div>
    </ng-container>
  </div>
  <vs-error *ngIf="esMostrarError" (eventError)="toggleError($event)" [esMostrarError]="esMostrarError" [mensajeError]="mensajeError"></vs-error>
</div>
