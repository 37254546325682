<div
  [id]="cardPrestacion.idPrestacion"
  class="card"
  [ngClass]="{
    'card--seleccionada': idSeleccion === cardPrestacion.idPrestacion,
    'card--seleccionada--sbs':
      storage.esDesdeSBS && (idSeleccion === cardPrestacion.idPrestacion)
  }"
>
  <img
    class="card__icono"
    [ngClass]="{
      'card__icono--off': !(idSeleccion === cardPrestacion.idPrestacion)
    }"
    [src]="
      idSeleccion === cardPrestacion.idPrestacion
        ? cardPrestacion.iconoOn
        : cardPrestacion.iconoOff
    "
  />
  <p
    class="card__texto"
    [ngClass]="{
      'card__texto--seleccionada': idSeleccion === cardPrestacion.idPrestacion,
      'card__texto--seleccionada--sbs':
        storage.esDesdeSBS && (idSeleccion === cardPrestacion.idPrestacion)
    }"
  >
    {{ cardPrestacion.nombrePrestacion }}
  </p>
  <input type="number" style="display: none" />
</div>
