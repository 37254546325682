import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {

  public showModal: boolean = false;

  open() {
    this.blockBody('hidden');
    this.showModal = true;
  }

  close() {
    this.blockBody('unset');
    this.showModal = false;
  }

  blockBody(value: string) {
    const html: HTMLHtmlElement = document.getElementsByTagName('html')[0];
    html.style.overflow = value;
  }

  scrollTo(scrollTo: string) {
    const element = document.querySelector(`#${scrollTo}`);
    if (element) element.scrollIntoView();
  }
}
