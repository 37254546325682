import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { DataUsuarioService } from '@common/services/data-usuario.service';
import { ArancelService } from '@common/services/arancel.service';
import { IntValidator } from '@common/utils/validators/int.validator';
import { crearID, formateoValor, reemplazarApostrofes } from '@common/utils/utils';
import { REGEX_NUMEROS_POSITIVOS } from '@common/utils/regex';
import { IArancel, IArancelInfo } from '@common/interfaces/IArancel';
import { SharedModule } from '@common/shared.module';
import { ID_DOCTYPE } from '@common/constants';

@Component({
  selector: 'app-services-provided-detail',
  templateUrl: './services-provided-detail.component.html',
  styleUrls: ['./services-provided-detail.component.scss'],
  standalone: true,
  imports: [SharedModule ],
})
export class ServicesProvidedDetailComponent implements OnInit, OnDestroy {
  @Input() form!: UntypedFormGroup;
  @Output() eventGuardar = new EventEmitter<boolean>();
  public formArancel!: UntypedFormGroup;
  public cambiosFormulario$!: Subscription;
  public esPuedeGuardar = false;
  public montoSolicitado = 0;
  public formatearValor: (valor: number) => string = formateoValor;
  public esRequiereSesiones = false;
  public esDocumentoBoletaFactura = false;
  public esDesviado = false;
  public montoHistorico = 0;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly intValidator: IntValidator,
    private arancelService: ArancelService,
    private dataUsuario: DataUsuarioService,
    private reembolso: ReembolsoActualService,
  ) {
    this.formArancel = this.formBuilder.group({
      arancel: ['', [Validators.required]],
      sesiones: ['', [Validators.required, Validators.pattern(REGEX_NUMEROS_POSITIVOS)]],
      valor: ['', [Validators.required, this.intValidator.esIntValido]],
      bonificacion: ['', [Validators.required, this.intValidator.esIntValido]],
    });
  }

  ngOnInit(): void {
    this.esDocumentoBoletaFactura = (
      this.reembolso.documentoPrincipalEsFactura() && this.form.get('prestacion')?.value !== 5
    );

    this.cambiosFormulario$ = this.formArancel.valueChanges.subscribe((formulario: IFormularioArancel) => {
      setTimeout(() => {
          this.esRequiereSesiones = (this.arancelService.arancelSeleccionado?.RequiereSesiones === '1');
      }, 120);

      this.verificarPuedeContinuar();

      if (this.esPuedeGuardar) {
        this.montoSolicitado = this.esDocumentoBoletaFactura
          ? +((formulario.valor).replace(/\D/g,''))
          : +((formulario.valor).replace(/\D/g,'')) - +((formulario.bonificacion).replace(/\D/g,''));
      } else {
        this.montoSolicitado = 0;
      }
    });
  }

  ngOnDestroy(): void {
    try {
      this.cambiosFormulario$.unsubscribe();
    } catch (error) { console.warn('¡Suscripción no existe!'); }
  }

  public async verificarHistorico(): Promise<void> {
    const arancelEscogido: IArancelInfo | undefined = this.arancelService.arancelSeleccionado;
    const sesiones = parseInt(this.formArancel.get('sesiones')?.value, 10);
    const valor = parseInt((this.formArancel.get('valor')?.value).replace(/\D/g,''), 10);

    if (arancelEscogido && sesiones && valor) {
      await this.arancelService.validarSesiones(
        arancelEscogido.CodigoArancel,
        this.dataUsuario.usuarioConectado.rutCuerpoDv,
        valor,
        sesiones
      );

      this.esDesviado = !this.arancelService.desviadoOK;
      this.montoHistorico = this.arancelService.montoHistorico;
    } else {
      this.esDesviado = false;
    }
  }

  public verificarPuedeContinuar(): boolean {
    const puedeContinuar: boolean[] = [];

    if (this.formArancel.get('arancel')?.valid) {
      puedeContinuar.push(true);
    } else { puedeContinuar.push(false); }

    puedeContinuar.push((this.formArancel.get('valor')?.valid
      && parseInt((this.formArancel.get('valor')?.value).replace(/\D/g,''), 10) > 0
    ) || false);

    if (this.esDocumentoBoletaFactura === false) {
      if (this.formArancel.get('bonificacion')?.valid && this.formArancel.get('valor')?.valid) {
        const bonificacion = parseInt((this.formArancel.get('bonificacion')?.value).replace(/\D/g,''), 10);
        const valor = parseInt((this.formArancel.get('valor')?.value).replace(/\D/g,''), 10);
        puedeContinuar.push((valor > bonificacion));
      } else {
        puedeContinuar.push(false);
      }
    }

    if (this.arancelService.arancelSeleccionado?.RequiereSesiones === '1') {
      puedeContinuar.push((this.formArancel.get('sesiones')?.valid && this.formArancel.get('sesiones')?.value !== '') || false);
    }

    this.esPuedeGuardar = (puedeContinuar.indexOf(false) === -1);
    return this.esPuedeGuardar;
  }

  public guardarArancel(): void {
    if (!this.esPuedeGuardar) return;

    this.reembolso.agregarArancel(this.generarArancel());
    this.eventGuardar.emit(false);
  }

  public cerrarModal(): void {
    this.eventGuardar.emit(false);
  }

  private generarArancel(): IArancel {
    const arancel: IArancel = {
      id: crearID(),
      codigo: this.arancelService.arancelSeleccionado!.CodigoArancel,
      nombre: reemplazarApostrofes(this.arancelService.arancelSeleccionado!.Arancel),
      montoTotal: +((this.formArancel.get('valor')?.value).replace(/\D/g,'')),
      descuento: +((this.formArancel.get('bonificacion')?.value).replace(/\D/g,'')),
      sesiones: +(this.formArancel.get('sesiones')?.value),
      montoComparacion: this.arancelService.montoHistorico,
      flagSesionValida: this.arancelService.desviadoOK,
    }
    return arancel;
  }
}

interface IFormularioArancel {
  arancel: string;
  sesiones: number;
  valor: string;
  bonificacion: string;
}