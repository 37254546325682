<section class="modal-terminos">
  <div class="wrapper-final">
    <img class="close" (click)="respuesta(false)" src="assets/svgs/cerrar.svg">
    <div class="modal--container">
      <div class="content">
        <!-- Header. -->
        <div class="title">
          <label class="header">
            Términos y condiciones
          </label>
        </div>
        <div class="info">
          <div class="terminos">
            <div class="inner-terminos" [innerHTML]="this.terminos"></div>
          </div>
          <div class="aceptar">
            <p>He leído y acepto los términos y condiciones</p>
            <ds-switch type="s1" (onchange)="cambioRespuesta($event)">
            </ds-switch>
          </div>
        </div>
        <!--Fin Detalles  -->

        <!-- botón final-->
        <div class="botones">
          <p class="error" *ngIf="showError">Ha ocurrido un error al intentar guardar tu respuesta. Por favor vuelve a intentarlo.</p>
          <section class="terminos__botones--container">
            <span (click)="respuesta(false)">Cancelar</span>
            <vs-button texto="Continuar" type="primary" size="medium" (click)="respuesta(true)"
              [disabled]="!terminosAceptados"></vs-button>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>