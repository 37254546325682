import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderComponent } from './components/header/header.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { VsCalendarComponent } from './components/vs-calendar/vs-calendar.component';
import { VsErrorComponent } from './components/vs-error/vs-error.component';
import { VsFileComponent } from './components/vs-file/vs-file.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component'; 
import { VsInputListComponent } from './components/vs-input-list/vs-input-list.component';
import { VsInputComponent } from './components/vs-input/vs-input.component';
import { VsPreviewComponent } from './components/vs-preview/vs-preview.component';
import { VsRadioComponent } from './components/vs-radio/vs-radio.component';
import { VsSelectComponent } from './components/vs-select/vs-select.component';
import { VsSpinnerComponent } from './components/vs-spinner/vs-spinner.component';
import { VsButtonComponent } from './components/vs-button/vs-button.component';
import { JoinWithNewLinePipe } from './pipes/join-new-line.pipe';

const COMPONENTS = [
  HeaderComponent,
  StepperComponent,
  VsSelectComponent,
  VsInputComponent,
  VsCalendarComponent,
  VsFileComponent,
  FileUploaderComponent,
  VsRadioComponent,
  VsErrorComponent,
  VsPreviewComponent,
  VsInputListComponent,
  VsSpinnerComponent,
  VsButtonComponent,
  JoinWithNewLinePipe,
];

@NgModule({
  declarations: [ ...COMPONENTS ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ...COMPONENTS,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
