<div id="detailModal" class="modal__container">
  <div class="modal__content modal__shadow {{ size }}">
    <div *ngIf="showHeader" class="modal__header">
      <h3>{{ title }}</h3>
      <span (click)="closeModal()" class="close">&times;</span>
    </div>
    <div class="modal__body {{ alignContent }}" [class.modal__body--border]="borderClass">
      <ng-content select="[body]"></ng-content>
      <div class="modal-actions__container {{ alignActionBtn }}" *ngIf="primaryActionEnabled">
        <vs-button
          id="primaryActionButton"
          (click)="onPrimaryAction()"
          type="primary"
          [texto]="primaryActionBtn"
        ></vs-button>
        <span *ngIf="secondaryActionBtn" id="secondaryActionButton" class="modal-actions__link" (click)="onSecondaryAction()">
          {{ secondaryActionBtn }}
        </span>
      </div>
    </div>
  </div>
</div>
