<div class="tabs__container">
  <div
    class="tabs__button in-curse"
    [class.tabs__button--active]="inCurseChecked"
    (click)="isInCurse(true)">
    <img [src]="inCurseChecked? '/assets/svgs/in-curse-checked.svg':'/assets/svgs/in-curse.svg'" alt="Ver reembolsos en curso">
    <span>En curso</span>
  </div>
  <div
    class="tabs__button historical"
    [class.tabs__button--active]="!inCurseChecked"
    (click)="isInCurse(false)">
    <img [src]="!inCurseChecked? '/assets/svgs/historical.svg':'/assets/svgs/historical-checked.svg'" alt="Ver reembolsos históricos">
    <span>Histórico</span>
  </div>
</div>
