import { ReembolsoActualService } from './../../services/reembolso-actual.service';
import { StorageService } from './../../services/storage.service';
import { Component, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DataUsuarioService } from '../../services/data-usuario.service';
import { capitalizar } from '../../utils/utils';
import { IPrestador } from '../../interfaces/IPrestadores';
import { environment as env } from 'src/environments/environment';
import { IBeneficiario } from '../../interfaces/IBeneficiario';

@Component({
  selector: 'vs-select',
  templateUrl: './vs-select.component.html',
  styleUrls: ['./vs-select.component.scss'],
})
export class VsSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() nameControl = 'persona';
  @Input() form!: UntypedFormGroup;
  public tituloInput = '';
  public esValido = false;
  public icono = 'check-green';
  public placeholder = 'Elige una persona';
  public esListaAbierta = false;
  public listado: ILista[] = this.storage.listaIsapre;

  constructor(
    private dataUsuario: DataUsuarioService,
    private storage: StorageService,
    private reembolso: ReembolsoActualService,
  ) {}

  ngOnInit(): void {
    if (this.nameControl === 'persona') {
      this.placeholder = 'Elige una persona';
      this.tituloInput = 'Yo';
    } else if (this.nameControl === 'isapreFonasa') {
      this.placeholder = 'Selecciona Isapre o Fonasa';
      this.tituloInput = 'Isapre o Fonasa';
    }

    if (this.dataUsuario.usuarioConectado && this.nameControl === 'persona') {
      this.listado = [];
      let beneficiarios: IBeneficiario[];
      if (!this.storage.esDesdeSBS) {
        beneficiarios = this.dataUsuario.usuarioConectado.cargas.filter(
          beneficiario => beneficiario.coberturasPorPoliza?.filter(cobertura => cobertura.rutAseguradora === env.RUTS.VS)
        )
      } else {
        beneficiarios = this.dataUsuario.usuarioConectado.cargas.filter(
          beneficiario => beneficiario.coberturasPorPoliza?.filter(cobertura => cobertura.rutAseguradora === env.RUTS.SBS)
        )
      }
      beneficiarios.forEach((persona) => {
        this.listado.push({
          nombre: `${persona.nombres} ${persona.apellidos} (${persona.parentesco})`,
          id: +persona.rut,
        });
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.nameControl === 'isapreFonasa') {
        const carga: IBeneficiario = this.dataUsuario.usuarioConectado.obtenerUsuarioSeleccionado();
        const isapre : IPrestador | undefined = this.storage.listaIsapre.find(isapre => isapre.id == Number(carga?.isapreCode));
        if (isapre) {
          this.form.get("isapreFonasa")?.setValue(capitalizar(isapre.nombre));
          this.esValido = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    try {
      this.form.get(this.nameControl)?.setValue('', { emitEvent: false });
    } catch (error) { }
  }

  perdidaFocus(nameControl: string): void {
    const input = document.getElementById(this.nameControl) as HTMLInputElement;
    if (this.form.get(nameControl)?.valid) {
      input.classList.remove('input--error');
      input.classList.add('input--success');
      this.esValido = true;
    } else if (this.form.get(nameControl)?.value === '') {
      input.classList.remove('input--error');
      input.classList.remove('input--success');
      this.esValido = false;
    } else {
      input.classList.remove('input--success');
      input.classList.add('input--error');
      this.esValido = false;
    }
  }

  abrirSeleccion(): void {
    this.esListaAbierta = true;
  }

  seleccionarItem(elemento: ILista): void {
    this.form
      .get(this.nameControl)
      ?.setValue(capitalizar(elemento.nombre).split('(')[0].replace(/\ +$/, ''));

    if (this.nameControl === 'persona') {
      this.dataUsuario.usuarioConectado.cargas.forEach((carga) => {
        carga.esSeleccionado = false;
        if (+carga.rut === elemento.id) {
          carga.esSeleccionado = true;
          this.tituloInput = capitalizar(carga.parentesco);
        }
      });
    } else if (this.nameControl === 'isapreFonasa') {
      this.reembolso.idIsapre = elemento.id;
    }

    this.perdidaFocus(this.nameControl);
    setTimeout(() => {
      this.esListaAbierta = false;
    }, 1);
  }

  trackByLista(_: number, lista: ILista): number {
    return lista.id;
  }
}

interface ILista {
  nombre: string;
  id: number;
}
