import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { SharedModule } from '@common/shared.module';
import { ModalService } from '@common/services/modal.service';
import { ServicesProvidedTableComponent } from '../../services-provided-table/services-provided-table.component';

@Component({
  selector: 'paso-aranceles',
  templateUrl: './paso-aranceles.component.html',
  styleUrls: ['./paso-aranceles.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    ServicesProvidedTableComponent
  ],
})
export class PasoArancelesComponent implements OnInit, OnDestroy {
  @Input() form!: UntypedFormGroup;
  @Input() porcentaje: number = -1;
  public aranceles$!: Subscription;
  public textoBoton = 'Agregar detalle';
  public esMostrarAgregarDetalle = false;
  public esMostrarTabla = false;

  constructor(
    private reembolso: ReembolsoActualService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.aranceles$ = this.reembolso.listadoAranceles$.subscribe(
      (aranceles) => {
        if (aranceles.length > 0) {
          this.esMostrarTabla = true;
          this.textoBoton = 'Agregar otro detalle';
          this.form.get('aranceles')?.setValue(true);
        } else {
          this.esMostrarTabla = false;
          this.textoBoton = 'Agregar detalle';
          this.form.get('aranceles')?.setValue(false);
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.aranceles$.unsubscribe();
  }

  mostrarModalDetalle(): void {
    this.modalService.modalDetalle = true;
  }
}