<div class="stepper">
  <!-- Ícono de Progreso. -->
  <img
    *ngIf="porcentajeProgeso === -1"
    class="stepper__icon"
    src="assets/svgs/check-waiting.svg"
  />
  <img
    *ngIf="porcentajeProgeso >= 0 && porcentajeProgeso < 100"
    class="stepper__icon"
    [src]="!storage.esDesdeSBS ? 'assets/svgs/check-in-progress.svg' : 'assets/svgs/check-in-progress-sbs.svg'"
  />
  <img
    *ngIf="porcentajeProgeso === 100"
    class="stepper__icon"
    src="assets/svgs/check-green.svg"
  />

  <!-- Barra Stepper. -->
  <div [id]="'stepper__bar--' + id" class="stepper__bar"></div>
</div>
