import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Observable, Subscription } from 'rxjs';
import * as JWT from 'jwt-decode';
import CryptoJS from 'crypto-js';

import { TokenData } from '@common/interfaces';
import {
  AuthenticationService,
  DataUsuarioService,
  AppInsightsService,
} from '@common/services';
import {
  GENERIC_ERROR_MESSAGE,
  INSUREDS_ERROR,
  TOKEN_ERROR,
} from '@common/constants';
import { environment as ENV } from '@environments/environment';
import { GenericMessage } from '@core/models';
import { DateKey } from '@claims/features/historical/constants';
import { CommonModule } from '@angular/common';
import { MessageBoxComponent } from '@common/components';

const TEST_SBS_TOKEN = 'none';
const SBS_KEY = 'esSBS';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  standalone: true,
  imports: [CommonModule, MessageBoxComponent],
})
export class AuthComponent implements OnInit {
  public showError: boolean = false;
  public errorMessage: GenericMessage = GENERIC_ERROR_MESSAGE;

  private _temporizador$: Observable<number> | undefined;
  private _temporizador: Subscription | undefined;

  private route = inject(ActivatedRoute);
  private authService = inject(AuthenticationService);
  private dataUsuarioService = inject(DataUsuarioService);
  private router = inject(Router);
  private readonly insightsService = inject(AppInsightsService);

  constructor(private document: Document, private winRef: Window) {}

  ngOnInit(): void {
    this.checkParameters();
  }

  async checkParameters(): Promise<void> {
    const token: string | null = this.route.snapshot.queryParamMap.get('token');
    const esSBS: string | null = this.route.snapshot.queryParamMap.get(SBS_KEY);
    const fromSBS = esSBS === 'true';

    this.configByOrigin(fromSBS);

    if (!token) {
      this.onServiceError(TOKEN_ERROR);
    } else if (token !== TEST_SBS_TOKEN) {
      const isAuthenticated = await this.authenticate(token, fromSBS);
      if (isAuthenticated) this.loadData();
      else this.onServiceError(TOKEN_ERROR);
    } else if (token === TEST_SBS_TOKEN) {
      this.router.navigate(['/login']);
    }
  }

  private async authenticate(
    token: string,
    fromSBS: boolean
  ): Promise<boolean> {
    try {
      this.showError = false;
      const bytes: any = CryptoJS.AES.decrypt(token, ENV.ENC_KEY);
      const decryptedToken = decodeURIComponent(
        fromSBS ? token : bytes.toString(CryptoJS.enc.Utf8)
      );
      sessionStorage.setItem('refresh_token', decryptedToken);
      this.authService.esDesdeSitioPrivado = true; // TODO: revisar, debería ser !fromSBS??
      const refreshToken: boolean = await this.authService.refreshToken();
      return refreshToken;
    } catch (error) {
      return false;
    }
  }

  private async loadData(): Promise<void> {
    try {
      this.showError = false;
      sessionStorage.removeItem(DateKey);
      const token: string | null = sessionStorage.getItem('ssoToken');
      const userInfo: TokenData = JWT(token);
      const success: boolean = await this.dataUsuarioService.InsuredData(
        userInfo.preferred_username
      );
      if (success) {
        this.router.navigate(['/historial']);
        this.insightsService.trackActiveUser();
      } else {
        this.onServiceError();
      }
    } catch (error) {
      this.onServiceError();
    } finally {
      this.scrollTop();
    }
  }

  private onServiceError(message: string = INSUREDS_ERROR): void {
    this.showError = true;
    console.error(message);
  }

  // Config Views
  private configByOrigin(fromSBS: boolean): void {
    this.setFavIcon(fromSBS);
    if (fromSBS) {
      sessionStorage.setItem(SBS_KEY, fromSBS.toString());
      document.getElementsByTagName('body')[0].classList.add('sbs');
      this.document.getElementsByTagName('html')[0].style.overflow = 'unset';
    } else {
      sessionStorage.removeItem(SBS_KEY);
    }
  }

  private setFavIcon(fromSBS: boolean): void {
    let link = this.document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement;
    if (!link) {
      link = this.document.createElement('link');
      link.rel = 'icon';
      this.document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = fromSBS
      ? '/assets/ico/faviconsbs.ico'
      : '/assets/ico/faviconvs.ico';
  }

  private scrollTop(): void {
    this._temporizador$ = interval(500);
    this._temporizador = this._temporizador$.subscribe(async () => {
      const altura: number = this.document.body.scrollHeight;
      this.winRef.top?.postMessage(altura, ENV.URL_SITIO_PRIVADO);
    });
  }
}
