<section class="info__container" *ngIf="claim">
  <!-- Refund date -->
  <div class="info__content">
    <span class="info__label info__label--small">
      Fecha de solicitud <br> {{ claim.issueDate | date : "dd/MM/yyyy" }}
    </span>
    <app-reimbursement-status
      class="info__value"
      [status]="claim.claimStatus"
    ></app-reimbursement-status>
  </div>

  <!-- Request number -->
  <div class="info__content">
    <span class="info__label">N° Solicitud</span>
    <span class="info__value">{{ claim.requestNumber || "Pendiente" }}</span>
  </div>

  <!-- Document number -->
  <div class="info__content" *ngIf="!claim.isMultiple && !claim.underEvaluation">
    <span class="info__label">N° Documento</span>
    <span class="info__value">
      {{ claim.documentNumber }}
    </span>
  </div>

  <!-- Beneficiary -->
  <div class="info__content" *ngIf="claim.beneficiaryName">
    <span class="info__label not-mobile">Nombre de beneficiario</span>
    <span class="info__label mobile">Beneficiario</span>
    <span class="info__value">
      {{ claim.beneficiaryName | titlecase }} /
      {{ claim.beneficiaryRelationship | titlecase }}
    </span>
  </div>

  <!-- Provider -->
  <div class="info__content" *ngIf="!claim.isMultiple && !claim.underEvaluation">
    <span class="info__label">Prestador</span>
    <span class="info__value">
      {{ claim.provider | titlecase }}
    </span>
  </div>

  <!-- Payment date -->
  <div class="info__content" *ngIf="claim.isSettled">
    <span class="info__label">Fecha de pago</span>
    <span class="info__value">
      {{
        claim.paymentDate
          ? (claim.paymentDate | date : "dd/MM/yyyy" : "UTC")
          : "Pendiente"
      }}
    </span>
  </div>

  <!-- Request amount -->
  <div class="info__content" *ngIf="claim.claimedTotalAmount !== undefined">
    <span class="info__label">Monto solicitado</span>
    <span class="info__value">
      {{ formatValue(claim.claimedTotalAmount) }}
    </span>
  </div>

  <!-- Deductible amount -->
  <div class="info__content" *ngIf="claim.deductibleTotalAmount > 0">
    <span class="info__label">Monto deducible*</span>
    <span class="info__value">
      {{ formatValue(claim.deductibleTotalAmount) }}
    </span>
  </div>

  <!-- Refunded amount -->
  <div class="info__content" *ngIf="claim.paidTotalAmount !== undefined">
    <span class="info__label" [class.highlighted]="!claim.underEvaluation">Monto reembolsado</span>
    <span class="info__value" [class.highlighted]="!claim.underEvaluation">
      {{ formatValue(claim.paidTotalAmount) }}
    </span>
  </div>
</section>
