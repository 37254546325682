import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class IntValidator {

  constructor() { }

  esIntValido: TIntValido = (control: AbstractControl) => {
    const valorInt: string = control.value;
    const MAX_INT = 2100000000;

    if (!valorInt) return null;

    if (parseInt(valorInt.replace(/\D/g,''), 10) <= MAX_INT) {
        return null;
    } else {
        return { esMayorMax: true }
    }
  }
}

type TIntValido = (control: AbstractControl) => {
  esMayorMax: boolean;
} | null;