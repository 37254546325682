import { DocAdicional, DocAtencionIsapreFonasa, DocBoletaFactura, DocBoletaFacturaOtrasPrestaciones, DocBono, DocReembAmbulatorioIsapreFonasa, DocReembolso, FormularioDental, ID_DOCTYPE } from '@common/constants';
import { IBeneficiario, IArancel, IFileUpload, IResumenReembolso, IPolizas } from '@common/interfaces';

export class Reembolso {
  private readonly _idPrestacion: number;
  private _idPrestacionVT!: number;
  private readonly _codigoIsapre: number;
  private readonly _folio: string;
  private readonly _fechaAtencion: Date;
  private readonly _idTipoDoc!: number;
  private readonly _rutPrestador: string;
  private readonly _archivosAdjuntos: IFileUpload[];
  private readonly _prestacionesAnadidas: IArancel[];
  private readonly _reembolsoResumen: IResumenReembolso;
  private readonly _montoTotalSolicitado: number;
  private readonly _montoTotalBonificacion: number;
  private readonly _montoTotalPrestacion: number;
  private readonly _beneficiarioSeleccionado: IBeneficiario;
  private readonly _idDocumentoPrincipal: string;

  // Flujo medicamentos
  private _esRecetaPermanente!: boolean;
  private _flagDescuentoAcumulado = false;
  private _descuentoAcumulado = 0;

  // Flujo consulta médica y exámenes y proc.
  private _esCopagoMayor15UF = false;
  private _esPasoPorIsapre = false; /* Solo para caso boleta/factura */

  // Flujo dentales
  private _esMasDeUnaAtencion: boolean;

  // Sesiones por arancel
  private _flagMasDeUnaSesion = false;

  constructor(
    idPrestacion: number,
    codigoIsapre: number,
    folio: string,
    fechaAtencion: Date,
    idTipoDoc: number,
    rutPrestador: string,
    beneficiario: IBeneficiario,
    archivosAdjuntos: IFileUpload[],
    prestacionesAnadidas: IArancel[],
    reembolsoResumen: IResumenReembolso,
    montoTotalSolicitado: number,
    montoTotalBonificacion: number,
    montoTotalPrestacion: number,
    esMasDeUnaAtencion: boolean,
    idDocumentoPrincipal: string = ''
  ) {
    this._idPrestacion = idPrestacion;
    this.setIdVtPrestacion(idPrestacion);
    this._codigoIsapre = codigoIsapre;
    this._folio = folio;
    this._fechaAtencion = fechaAtencion;
    this._idTipoDoc = idTipoDoc;
    this._idDocumentoPrincipal = idDocumentoPrincipal;
    this._rutPrestador = rutPrestador;
    this._beneficiarioSeleccionado = beneficiario;
    this._prestacionesAnadidas = prestacionesAnadidas;
    this._reembolsoResumen = reembolsoResumen;
    this._montoTotalSolicitado = montoTotalSolicitado;
    this._montoTotalBonificacion = montoTotalBonificacion;
    this._montoTotalPrestacion = montoTotalPrestacion;
    this._esMasDeUnaAtencion = esMasDeUnaAtencion; // <- Dentales

    // Caso flag descuento acumulado para medicamentos.
    if (this._idPrestacion === 5) {
      if (montoTotalBonificacion > 0) {
        this.flagDescuentoAcumulado = true;
        this.descuentoAcumulado = montoTotalBonificacion;
      }
    }

    // Check para flag de más de una sesión.
    this._prestacionesAnadidas.forEach((arancel) => {
      if (+arancel.sesiones > 1) {
        this.flagMasDeUnaSesion = true;
      }
    })

    if (this._idPrestacion === 3) {
      this.flagMasDeUnaSesion = esMasDeUnaAtencion;
    }

    this._archivosAdjuntos = this.reordenacionArchivos(archivosAdjuntos);
  }

  public get fechaAtencion(): Date {
    return this._fechaAtencion;
  }

  public get esRecetaPermanente(): boolean {
    return this._esRecetaPermanente;
  }

  public get getTipoDoc(): number {
    return this._idTipoDoc;
  }

  public get getRutPrestador(): string {
    return this._rutPrestador;
  }

  public get getFolio(): string {
    return this._folio;
  }

  public get getIdPrestacion(): number {
    return this._idPrestacion;
  }

  public set esRecetaPermanente(esRecetaPermanente: boolean) {
    this._esRecetaPermanente = esRecetaPermanente;
  }

  public get getMontoTotalSolicitado(): number {
    return this._montoTotalSolicitado;
  }

  public get esCopagoMayor15UF(): boolean {
    return this._esCopagoMayor15UF;
  }

  public set esCopagoMayor15UF(esCopagoMayor15UF: boolean) {
    this._esCopagoMayor15UF = esCopagoMayor15UF;
  }

  public get esPasoPorIsapre(): boolean {
    return this._esPasoPorIsapre;
  }

  public set esPasoPorIsapre(esPasoPorIsapre: boolean) {
    this._esPasoPorIsapre = esPasoPorIsapre;
  }

  public get esMasDeUnaAtencion(): boolean {
    return this._esMasDeUnaAtencion;
  }

  public set esMasDeUnaAtencion(esMasDeUnaAtencion: boolean) {
    this._esMasDeUnaAtencion = esMasDeUnaAtencion;
  }

  public get reembolsoResumen(): IResumenReembolso {
    return this._reembolsoResumen;
  }

  public get aranceles(): IArancel[] {
    const arancelesNoID: IArancel[] = this._prestacionesAnadidas;
    arancelesNoID.forEach((arancel)=> {
      delete arancel.id;
    });
    return arancelesNoID;
  }

  public get montoTotalSolicitado(): number {
    return this._montoTotalSolicitado;
  }

  public get montoTotalBonificacion(): number {
    return this._montoTotalBonificacion;
  }

  public get montoTotalPrestacion(): number {
    return this._montoTotalPrestacion;
  }

  public get rutPrestador(): string {
    return this._rutPrestador;
  }

  public get archivosAdjuntos(): IFileUpload[] {
    return this._archivosAdjuntos;
  }

  public get idPrestacion(): number {
    return this._idPrestacion;
  }

  public get idPrestacionVT(): number {
    return this._idPrestacionVT;
  }

  public get flagDescuentoAcumulado(): boolean {
    return this._flagDescuentoAcumulado;
  }

  public set flagDescuentoAcumulado(flag: boolean) {
    this._flagDescuentoAcumulado = flag;
  }

  public get descuentoAcumulado(): number {
    return this._descuentoAcumulado;
  }

  public set descuentoAcumulado(descuento: number) {
    this._descuentoAcumulado = descuento;
  }

  public get flagMasDeUnaSesion(): boolean {
    return this._flagMasDeUnaSesion;
  }

  public set flagMasDeUnaSesion(flag: boolean) {
    this._flagMasDeUnaSesion = flag;
  }

  public get codigoIsapre(): number {
    return this._codigoIsapre;
  }

  public get beneficiarioSeleccionado(): IBeneficiario {
    return this._beneficiarioSeleccionado;
  }

  public obtenerArchivoPrincipal(): IFileUpload {
    return this._idDocumentoPrincipal
      ? this._archivosAdjuntos.filter(item => item.idArchivoSubido === this._idDocumentoPrincipal)[0]
      : this._archivosAdjuntos[0];
  }

  public obtenerArchivosSecundarios(): IFileUpload[] {
    return this._idDocumentoPrincipal
      ? this._archivosAdjuntos.filter(item => item.idArchivoSubido !== this._idDocumentoPrincipal)
      : this._archivosAdjuntos.slice(1);
  }

  public obtenerRutAseguradora(poliza: number, polizas: IPolizas[]): string {
    return polizas.filter(polizaAseguradora => polizaAseguradora.poliza === `${poliza}`)[0].rutAseguradora;
  }

  public obtenerPoliza(idPrestacion: number): string {
    const SALUD = this.beneficiarioSeleccionado.coberturasPorPoliza?.filter(cobertura => cobertura.cobertura === 95)[0]?.poliza || '';
    const CAT = this.beneficiarioSeleccionado.coberturasPorPoliza?.filter(cobertura => cobertura.cobertura === 96)[0]?.poliza || '';
    const DENTAL = this.beneficiarioSeleccionado.coberturasPorPoliza?.filter(cobertura => cobertura.cobertura === 97)[0]?.poliza || '';

    switch (idPrestacion) {
      case 0:
      case 2:
      case 4:
      case 5:
        return SALUD;
      case 1:
        return CAT || SALUD;
      case 3:
        return DENTAL;
      default:
        return '';
    }
  }

  // TODO: revisar
  private reordenacionArchivos(archivos: IFileUpload[]): IFileUpload[] {
    let archivosOrdenados: IFileUpload[] = [];

    archivos.forEach((archivo) => {
      if (
        archivo.nombre.includes(DocBoletaFactura.nombre)
        || archivo.nombre.includes(DocBoletaFacturaOtrasPrestaciones.nombre)
        || archivo.nombre.includes(DocAtencionIsapreFonasa.nombre)
        || archivo.nombre.includes(DocReembAmbulatorioIsapreFonasa.nombre)
      ) {
        archivosOrdenados.unshift(archivo);
      } else {
        archivosOrdenados.push(archivo);
      }
    });

    if (this.idPrestacion === 2 || this.idPrestacion === 5) {
      const opcionales: IFileUpload[] = archivosOrdenados.filter(archivo => archivo.nombre === DocAdicional.nombre);
      archivosOrdenados = archivosOrdenados.filter(archivo => !archivo.nombre.includes(DocAdicional.nombre));
      archivosOrdenados.push(...opcionales);
    } else if (this.idPrestacion === 3) {
      const doc_solicitud: IFileUpload[] = archivosOrdenados.filter(archivo => archivo.nombre === FormularioDental.nombre);
      archivosOrdenados = archivosOrdenados.filter(archivo => !archivo.nombre.includes(FormularioDental.nombre));
      archivosOrdenados.splice(1, 0, ...doc_solicitud);
    }
    return archivosOrdenados;
  }

  setIdVtPrestacion(idPrestacion: number): void {
    const idPrestacionVT = [2, 3, 4, 5, 3, 1];
    this._idPrestacionVT = idPrestacionVT[idPrestacion];
  }
}
