<div class="header__container">
  <div class="header__image">
    <img [src]="image" alt="Solicitar reembolso" />
  </div>
  <div class="header__content">
    <h3 class="title">Reembolsos</h3>
    <div class="description">
      <p class="description description__header">
        Los plazos de pago para solicitudes de reembolso son los siguientes:
      </p>
      <p class="description description__detail">
        Gastos médicos ambulatorios, ópticos, dentales y medicamentos: 5 días hábiles desde el ingreso de la solicitud.
        <br>
        Gastos hospitalarios: 10 días hábiles desde el ingreso de la solicitud.
      </p>
    </div>
    <vs-button
      type="primary"
      texto="Solicitar un reembolso"
      [disabled]="!requestAllowed"
      (click)="makeRequest()"
    ></vs-button>
  </div>
</div>
