import { Injectable, inject } from '@angular/core';

import { environment } from '@environments/environment';
import { DataUsuarioService, StorageService } from '@common/services';
import { REIMBURSEMENT_EVENT_SBS, REIMBURSEMENT_EVENT_VS } from '../constants';
import { StringObject } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class ReimbursementService {
  private _eventsTag!: StringObject;

  private storage: StorageService = inject(StorageService);
  private dataUsuarioService: DataUsuarioService = inject(DataUsuarioService);

  constructor() {
    this.getEventTags();
  }

  public canMakeRequest(): boolean {
    const rutsAseguradoras: string[] =
      this.dataUsuarioService.usuarioConectado.obtenerRutAseguradora();
    if (!this.storage.esDesdeSBS) {
      return rutsAseguradoras.includes(environment.RUTS.VS);
    } else {
      return rutsAseguradoras.includes(environment.RUTS.SBS);
    }
  }

  public canDownLoad(): boolean {
    return !this.storage.esDesdeSBS;
  }

  private getEventTags(): void {
    this._eventsTag = this.storage.esDesdeSBS
      ? REIMBURSEMENT_EVENT_SBS
      : REIMBURSEMENT_EVENT_VS;
  }

  public get eventsTag(): StringObject {
    return this._eventsTag;
  }
}
