export const environment = {
  production: false,
  URL_BFF_BASE: 'https://vs-as-appsalud-back-des.azurewebsites.net',
  URL_SITIO_PRIVADO: 'https://vs-as-sp-clientes-des.azurewebsites.net/',
  X_IBM_CLIENT_ID: '17a58e6c-3354-42ee-b9f9-222723b8b536',
  CLIENT_ID: 'vs-web-salud',
  PRIVADO_CLIEN_ID: 'vs-site-client',
  APPINSIGHTS_CONNECTIONSTRING:
    'InstrumentationKey=dab91500-bdd6-4d64-a758-5b83eb0a39b1;IngestionEndpoint=https://brazilsouth-0.in.applicationinsights.azure.com/;LiveEndpoint=https://brazilsouth.livediagnostics.monitor.azure.com/',
  ENC_KEY: '#DB[-q;<97zg[_=~D`ajl5O<>dp:eC',
  CORREOS: { VIDA: 'contactovida@security.cl', SBS: 'contactosbs@security.cl' },
  RUTS: { SBS: '77431040-1', VS: '99301000-6' },
  AMBIENTE: 'DEV',
  URL_BFF_V2_BASE: 'https://vs-as-bff-web-onclicksalud-des.azurewebsites.net',
  X_IBM_CLIENT_ID_BFF_V2: '879d5be8-4998-451c-9226-638b97a8596c',
};
