import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SharedModule } from '@common/shared.module';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class ModalComponent {
  @Input() title: string = '';
  @Input() primaryActionBtn: string = 'Aceptar';
  @Input() secondaryActionBtn!: string;
  @Input() primaryActionEnabled: boolean = false;
  @Input() showHeader: boolean = true;
  @Input() borderClass: boolean = true;
  @Input() alignActionBtn: 'right' | 'center' = 'right';
  @Input() alignContent: 'left' | 'center' = 'left';
  @Input() size: 'full' | 'small' = 'full';
  @Output() close = new EventEmitter<boolean>();
  @Output() primaryAction = new EventEmitter<any>();
  @Output() secondaryAction = new EventEmitter<any>();

  onPrimaryAction(): void {
    this.primaryAction.emit();
  }

  onSecondaryAction(): void {
    this.secondaryAction.emit();
  }

  closeModal(): void {
    this.close.emit();
  }
}
