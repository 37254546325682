import { IArancelGroup } from './../interfaces/IArancel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ReembolsoActualService, StorageService } from '@common/services';
import { environment as ENV } from '@environments/environment';
import { IArancelInfo, IArancelService, ISessions } from '@common/interfaces';
import { capitalizar, TPrestaciones, codAranceles } from '@common/utils'

@Injectable({
  providedIn: 'root',
})
export class ArancelService {
  public aranceles: IArancelGroup = {
    'CONSULTA': [],
    'DENTAL': [],
    'EXAMENES Y PROCEDIMIENTOS': [],
    'OPTICA': [],
  };
  public respuestaFiltro!: IArancelInfo[] | undefined[];
  public arancelSeleccionado: IArancelInfo | undefined = undefined;
  private _montoHistorico = 0;
  private _desviadoOK = true;

  constructor(
    private http: HttpClient,
    private reembolso: ReembolsoActualService,
    private storage: StorageService,
  ) { }

  /**
   * @description filtro para resultados basados en
   * si la palabra se encuentra en cualquier parte del
   * nombre del arancel.
   */
  public filtroAranceles(filtro: string): any[] {
    const filtroPrestacion: TPrestaciones = codAranceles(this.reembolso.idPrestacion);

    if (filtro) {
      const resultados: IArancelInfo[] = this.aranceles[filtroPrestacion].filter((arancel) => {
        return (
          arancel.Arancel.toString().trim().toLowerCase().search(filtro) >= 0
        );
      });
      return resultados;
    } else {
      return [undefined];
    }
  }

  public filtroCodigoArancel(): boolean {
    const filtroPrestacion: TPrestaciones = codAranceles(this.reembolso.idPrestacion);

    if (this.arancelSeleccionado) {
      const valido: IArancelInfo[] = this.aranceles[filtroPrestacion].filter(arancel => arancel.CodigoArancel === this.arancelSeleccionado?.CodigoArancel);
      if (valido.length === 1) return true;
      else return false;
    } else {
      return false;
    }
  }

  /**
   * @description Valida el monto histórico de un arancel basado
   * en su código, sesión y monto ingresado por el usuario contra
   * el servicio.
   * @returns Retorna FALSE cuando encuentra algún problema al intentar
   * realizar la solicitud, de lo contrario, si se devuelve un histórico,
   * retorna un array con el monto histórico y un booleano que define si
   * existe desviación o no.
   * FALSE => Hay desviación >+21%.
   * TRUE => No existe desviación.
   */
  public async validarSesiones(
    codigoArancel: string,
    rut: string,
    montoIngresado: number,
    cantidadSesiones: number
  ): Promise<void> {
    try {
      const headers: HttpHeaders = new HttpHeaders()
        .set('x-application', ENV.CLIENT_ID)
        .set('x-transaction_id', '12345'); /* Por confirmar. */

      const infoSesiones: ISessions = (
        await this.http.get<ISessions>(
          `${ENV.URL_BFF_BASE}/BFF/Reimbursement/validate/session/insured/${rut}?TotalAmount=${montoIngresado}&NumberSessions=${cantidadSesiones}&BenefitCode=${codigoArancel}&RuleName=Sessions`,
          { headers: headers }).toPromise()
      );
      this.desviadoOK = infoSesiones.session?.validate!;
      this.montoHistorico = infoSesiones.session?.amount!;

    } catch (error) {
      console.warn(error);
      this.desviadoOK = true;
      this.montoHistorico = 0;
    }
  }
  public async obtenerAranceles(rut: string, idPrestacion: number): Promise<void> {
    const prestacion: TPrestaciones = codAranceles(idPrestacion);
    // guard clause para prevenir obtenciones repetidas de aranceles.
    if (this.aranceles[prestacion].length !== 0) return;
    try {
      const headers: HttpHeaders = new HttpHeaders()
        .set('x-application', ENV.CLIENT_ID);

      const aranceles: IArancelService = (
        await this.http.get<IArancelService>(
          `${ENV.URL_BFF_BASE}/Tariff/Insured/${rut}/Coverage/${prestacion}`,
          { headers: headers }).toPromise()
      );

      aranceles.tariffs.forEach((tariff)=>{
        const arancelinfo: IArancelInfo = {
          CodigoArancel: tariff.code,
          Arancel : capitalizar(tariff.name),
          RequiereSesiones : tariff.session.toString(),
          SiempreRechaza : tariff.reject.toString()
        }
        this.aranceles[prestacion].push(arancelinfo);
      });

      if (prestacion === 'DENTAL') {
        this.storage.otrosDentales = this.aranceles[prestacion].find(
          (arancel) => arancel.CodigoArancel === this.storage.codOtrosDentales
        )!;
      }
    } catch (error) {
      console.warn(error);
    }
  }

  public get montoHistorico(): number {
    return this._montoHistorico;
  }

  public set montoHistorico(valor: number) {
    this._montoHistorico = valor;
  }

  public get desviadoOK(): boolean {
    return this._desviadoOK;
  }

  public set desviadoOK(estado: boolean) {
    this._desviadoOK = estado;
  }
}
