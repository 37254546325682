<div class="table__container table-desktop">
  <div slot="header" class="table--header">
    <span slot="element" class="text-left size-all">Tipo de reembolso</span>
    <span slot="element" class="text-left size-130px">Fecha de emisión</span>
    <span slot="element" class="text-right size-130px">Valor prestaciones</span>
    <span slot="element" class="text-right size-130px">Monto solicitado</span>
  </div>

  <div class="table-content">
    <ng-container *ngFor="let reembolso of resumenesReembolsos; trackBy: trackByReembolso">
      <div class="table--row">
        <span slot="element" class="text-left size-all">Total {{reembolso.nombrePrestacion |
          titlecase}}</span>
        <span slot="element" class="text-left size-130px">{{ reembolso.fecha | date:'dd/MM/yy' }}</span>
        <span *ngIf="reembolso.nombrePrestacion !== 'Atención Hospitalaria'" slot="element" class="text-right size-130px">{{ formatearValor(reembolso.valorPrestaciones! )}}</span>
        <span *ngIf="reembolso.nombrePrestacion === 'Atención Hospitalaria'" slot="element" class="text-right size-130px">─</span>
        <span slot="element" class="text-right size-130px">{{ formatearValor(reembolso.montoSolicitado) }} </span>
      </div>
    </ng-container>
  </div>

  <div class="table--totalizers">
    <div class="total">
      <h6>Monto total solicitado</h6>
      <h6>{{ formatearValor(montoTotalSolicitado) }}</h6>
    </div>
  </div>
</div>

<!-- tabla mobile -->
<div class="table-mobile table-mobile--container">
  <div class="table-mobile--totalizers">
    <ng-container *ngFor="let reembolso of resumenesReembolsos; trackBy: trackByReembolso">
      <div class="subtotal-resumen">
        <h6>Total {{reembolso.nombrePrestacion | titlecase}}</h6>
        <h6>{{ formatearValor(reembolso.montoSolicitado) }}</h6>
      </div>
    </ng-container>
    <div class="total">
      <h6>Monto solicitado</h6>
      <h6>{{ formatearValor(montoTotalSolicitado) }}</h6>
    </div>
  </div>
</div>