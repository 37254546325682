<section class="observations__container">
  <div
    class="observations__content"
    *ngIf="
      claim.liquidationObservations && claim.liquidationObservations.length >= 1
    "
  >
    <h4 class="observations__title">
      Observaciones de la Liquidación
    </h4>
    <p
      class="observations__detail"
      *ngFor="let observations of claim.liquidationObservations"
    >
      {{ observations }}
    </p>
  </div>

  <div class="observations__content" *ngIf="claim.additionalObservation">
    <h4 class="observations__title">Observación adicional</h4>
    <p class="observations__detail">
      {{ claim.additionalObservation }}
    </p>
  </div>
</section>
