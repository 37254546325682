import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import * as JWT from 'jwt-decode';

import { formatearRut } from '@common/utils';
import { TokenData } from '@common/interfaces';
import {
  AuthenticationService,
  DataUsuarioService,
  AppInsightsService,
} from '@common/services';
import { DateKey } from '@claims/features/historical/constants';
import { INSUREDS_ERROR, TOKEN_ERROR } from '@common/constants';
import { SharedModule } from '@common/shared.module';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [ SharedModule ]
})
export class LoginComponent implements OnInit {
  public rut = '';
  public password = '';
  public showError = false;
  public disabledLogin = false;
  public fromSBS = false;

  private router = inject(Router);
  private authService = inject(AuthenticationService);
  private dataUsuarioService = inject(DataUsuarioService);
  private readonly appInsightsService = inject(AppInsightsService);

  constructor() {
    const fromSBS: string | null = sessionStorage.getItem('esSBS');
    this.fromSBS = fromSBS! === 'true';
  }

  ngOnInit(): void {
    this.appInsightsService.trackPageView('Login');
  }

  onFocus(): void {
    this.showError = false;
    this.disabledLogin = false;
  }

  onKeyPress(event: KeyboardEvent): void {
    const input: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
    const rut: string = input.value;
    input.value = formatearRut(rut);
  }

  initSession(): void {
    if (!this.rut || !this.password || this.disabledLogin) return;
    this.disabledLogin = true;
    this.setBodyStyle('wait');
    sessionStorage.removeItem(DateKey);

    this.authService.getToken(this.rut, this.password).subscribe(
      async (response) => {
        if (response.token.accessToken) {
          sessionStorage.setItem('ssoToken', response.token.accessToken);
          sessionStorage.setItem('refresh_token', response.token.refreshToken);
          const userInfo: TokenData = JWT(response.token.accessToken);
          await this.loadData(userInfo.preferred_username);
        } else {
          this.onServiceError(TOKEN_ERROR);
        }
      },
      (error) => {
        this.onServiceError(error);
      }
    );
  }

  private async loadData(user: string): Promise<void> {
    try {
      this.showError = false;
      const success: boolean = await this.dataUsuarioService.InsuredData(user);

      if (success) {
        this.router.navigate(['/historial']);
        this.appInsightsService.trackActiveUser();
      } else {
        this.onServiceError(INSUREDS_ERROR);
      }
    } catch (error) {
      this.onServiceError(INSUREDS_ERROR);
    } finally {
      this.setBodyStyle();
      this.setHTMLStyle();
    }
  }

  private onServiceError(error: any): void {
    console.error(error);
    this.showError = true;
  }

  private setBodyStyle(style: string = 'default'): void {
    document.body.style.cursor = style;
  }

  private setHTMLStyle(): void {
    const html: HTMLHtmlElement = document.getElementsByTagName('html')[0];
    html.style.overflow = 'unset';
  }
}
