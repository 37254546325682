<label class="container" [formGroup]="form">
  <label class="nombre" [for]="radio.id">{{ radio.nombre }}</label>
  <input
    type="radio"
    [checked]="this.form.get('documentoPrincipal')?.value === radio.id"
    [value]="radio.id"
    [formControlName]="nameControl"
  />
  <span class="checkmark"></span>
</label>
