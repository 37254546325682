import { Component, Input } from '@angular/core';
import { FormsModule, UntypedFormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { StorageService } from '@common/services/storage.service';
import { IPrestacion } from '@common/interfaces/IPrestacion';

@Component({
  selector: 'app-services-provided-card',
  templateUrl: './services-provided-card.component.html',
  styleUrls: ['./services-provided-card.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
})
export class ServicesProvidedComponent {
  @Input() cardPrestacion!: IPrestacion;
  @Input() form!: UntypedFormGroup;

  constructor(public storage: StorageService) { }
}