import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { IArancel } from '@common/interfaces/IArancel';
import { formateoValor } from '@common/utils/utils';

@Component({
  selector: 'app-services-provided-table',
  templateUrl: './services-provided-table.component.html',
  styleUrls: ['./services-provided-table.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ServicesProvidedTableComponent implements OnDestroy {
  public prestaciones: IArancel[] = [];
  public aranceles$: Subscription;
  public totalPrestaciones = 0;
  public montoReembolsar = 0;
  public formatearValor: (valor: number) => string = formateoValor;

  constructor(private reembolso: ReembolsoActualService) {
    this.aranceles$ = this.reembolso.listadoAranceles$.subscribe(
      (aranceles) => {
        this.prestaciones = aranceles;

        this.totalPrestaciones = 0;
        this.montoReembolsar = 0;
        this.prestaciones.forEach((arancel) => {
          this.totalPrestaciones += arancel.montoTotal;
          this.montoReembolsar += arancel.montoTotal - arancel.descuento;
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.aranceles$.unsubscribe();
    this.reembolso.vaciarAranceles();
  }

  eliminarArancel(prestacion: IArancel): void {
    this.reembolso.eliminarArancel(prestacion);
  }

  trackByArancel(_: number, arancel: IArancel): string {
    return arancel.codigo;
  }
}
