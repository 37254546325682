<div class="aranceles" [formGroup]="form">
  <stepper [porcentajeProgeso]="porcentaje" [id]="4"></stepper>
  <div class="aranceles__contenido">
    <header [porcentajeProgeso]="porcentaje" texto="Detalles de la prestación"></header>
    <div class="informacion transicion-de-despliegue" *ngIf="porcentaje > 0">
      <label class="informacion__texto">
        Agrega la información que aparece en tu documento como por ejemplo los tipos de medicamentos, detalles de tu atención, insumos comprados, según tu prestación.
      </label>
      <vs-button [ngClass]="{'extendido': esMostrarTabla}" (click)="mostrarModalDetalle()" [texto]="textoBoton" size="full" type="secondary"></vs-button>
      <app-services-provided-table class="transicion-de-despliegue" *ngIf="esMostrarTabla"></app-services-provided-table>
    </div>
  </div>

  <input type="checkbox" nameControl="aranceles" style="display: none;">
</div>