import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { IFile } from '../../../interfaces/IFile';
import { formateoValor} from '@common/utils/utils';
import { Reembolso } from '@common/models/reembolso';
import { ReingresoService } from '../../../services/reingreso.service';
import { StorageService } from '@common/services/storage.service';
import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { DataUsuarioService } from '@common/services/data-usuario.service';
import { SharedModule } from '@common/shared.module';
import { DocBoletaFactura, DocBono, DocReembAmbulatorioIsapreFonasa } from '@common/constants';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
  standalone: true,
  imports: [ SharedModule]
})
export class SuccessModalComponent {
  @Input() numeroSolicitado = '100000';
  @Input() ctaBancaria = '123456789';
  @Input() nombreBanco = 'Banco';
  public prestacionesAsociadas = '';
  public beneficiario = '';
  public formatearValor: (valor: number) => string = formateoValor;
  public idPrestacion = 0;
  public esMasDeUnReembolso = false;
  public rutPrestador = '';
  public tipoDocumento = '';
  public fechaActual: Date = new Date();
  public montoTotalSolicitado = 0;
  public esMostrarDatosCompletos = true;
  public detalleNumero: string = this.storage.esDesdeSBS ? 'Solicitud' : 'Siniestro';
  public tipoSolicitud: 'reembolso' | 'reingreso' = 'reembolso';

  constructor(
    private reembolso: ReembolsoActualService,
    public storage: StorageService,
    private router: Router,
    private readonly dataUsuario: DataUsuarioService,
    public reingreso: ReingresoService
  ) {
    if (this.reingreso.esReingreso) {
      this.esMostrarDatosCompletos = false;
      this.tipoSolicitud = 'reingreso';
      this.beneficiario = this.reingreso.historicoReingreso.beneficiaryName;
      this.prestacionesAsociadas = this.reingreso.historicoReingreso.claimTypeRecord;
    } else {
      try {
        this.beneficiario = this.reembolso.reembolsos[0].beneficiarioSeleccionado.nombres + ' ' + this.reembolso.reembolsos[0].beneficiarioSeleccionado.apellidos;
        this.idPrestacion = this.reembolso.reembolsos[0].idPrestacion;
        this.rutPrestador = (this.reembolso.reembolsos[0].rutPrestador).replace(/[.-]/g, '').replace( /^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
        const archivoUnico: IFile | null = this.reembolso.filtrarArchivoUnico();
        if (archivoUnico) {
          this.tipoDocumento = this.generarNombreDocumento(archivoUnico.nombre);
        }
        this.montoTotalSolicitado = this.reembolso.obtenerGastoTotal();
        this.nombreBanco = this.dataUsuario.usuarioConectado.nombreBanco;
        this.esMostrarDatosCompletos = this.esDatosCompletos(this.reembolso.reembolsos);
        this.prestacionesAsociadas = this.obtenerArrayNombresPrestaciones();
      } catch (error) {
        console.warn(error);
        this.beneficiario = 'Alejandro Salgado';
        this.prestacionesAsociadas = 'Consulta médica';
      }
    }
  }

  public finalizar(): void {
    this.router.navigate(['/historial']);
    this.storage.idSeleccionado = -1;
    this.storage.coberturaSeleccionada = undefined;
    this.storage.esOtroReembolso = false;
    this.storage.reiniciarCards();
    this.reembolso.reiniciarServicio();
    this.reingreso.reiniciarReingreso();
  }

  private esDatosCompletos(reembolsos: Reembolso[]): boolean {
    if (this.idPrestacion === 1) return false;
    else if (reembolsos.length > 1) return false;
    else return true;
  }

  private obtenerArrayNombresPrestaciones(): string {
    const nombres: string[] = [];
    this.reembolso.reembolsos.forEach((reembolso) => {
      switch (reembolso.idPrestacion) {
        case 0:
          nombres.push('consulta médica');
          break;
        case 3:
          nombres.push('atención dental');
          break;
        case 1:
          nombres.push('atención hospitalaria');
          break;
        case 2:
          nombres.push('compra de marcos y lentes');
          break;
        case 4:
          nombres.push('exámenes y procedimientos');
          break;
        case 5:
          nombres.push('medicamentos');
          break;
      }
    });

    const listaDePrestaciones: string[] = [...new Set(nombres)];
    let prestaciones = '';
    if (listaDePrestaciones.length > 1) {
      for (let index = 0; index < listaDePrestaciones.length; index++) {
        if (index === (listaDePrestaciones.length - 1)) {
          prestaciones += ' y ';
          prestaciones += listaDePrestaciones[index];
        } else if (index === (listaDePrestaciones.length - 2)) {
          prestaciones += listaDePrestaciones[index];
        } else {
          prestaciones += listaDePrestaciones[index];
          prestaciones += ', ';
        }
      }
    } else {
      prestaciones += listaDePrestaciones[0];
    }

    return prestaciones[0].toUpperCase() + prestaciones.substring(1).toLowerCase();
  }

  private generarNombreDocumento(nombreDoc: string): string {
    switch (nombreDoc) {
      case DocReembAmbulatorioIsapreFonasa.nombre:
        return 'Documento de reembolso';
      case DocBono.nombre:
        return 'Bono';
      case DocBoletaFactura.nombre:
        return 'Boleta / Factura';
      default:
        return 'No Seleccionado';
    }
  }
}
