import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ETypeFile } from '@common/enum/ETypeFile';
import { SharedModule } from '@common/shared.module';
import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { StorageService } from '@common/services/storage.service';
import { IFile, IFileUpload } from '@common/interfaces/IFile';
import { ModalsModule } from '@common/components/modals/modals.module';
import { AlertMessageComponent, WarningMessageComponent } from '@common/components';
import { ModalService } from '@common/components/modals';
import { ETypeError } from '@common/enum/ETypeError';

@Component({
  selector: 'paso-documentos',
  templateUrl: './paso-documentos.component.html',
  styleUrls: ['./paso-documentos.component.scss'],
  standalone: true,
  imports: [SharedModule, ModalsModule, AlertMessageComponent, WarningMessageComponent],
})
export class PasoDocumentosComponent implements OnInit, OnDestroy {
  @Input() form!: UntypedFormGroup;
  @Input() porcentaje: number = -1;
  public esMostrarTooltip = false;
  private _cambiosFormulario$!: Subscription;
  private _archivos$!: Subscription;
  public documentos: IFile[] = [];
  public archivosActuales: IFileUpload[] = [];
  public radios: RadioElement[] = [];
  public esCopago: boolean | undefined;
  public separatedUpload: boolean = true;
  public showWarningModal: boolean = false;
  public showFileSizeErrorModal: boolean = false;

  constructor(
    public storage: StorageService,
    private reembolso: ReembolsoActualService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.separatedUpload = true;
    this._cambiosFormulario$ = this.form.valueChanges.subscribe(() => {
      const idPrestacion = parseInt(this.form.get('prestacion')?.value, 10);
      this.agregarTipoDocumentos(idPrestacion);
    });

    this._archivos$ = this.reembolso.archivosSubidos$.subscribe((archivos) => {
      setTimeout(() => {
        this.showFileSizeErrorModal = !this.reembolso.pesoTotalValido();
        this.form.get('documentosSubidos')?.setValue(this.validarSubidaCompleta() && this.reembolso.pesoTotalValido());
      }, 200);
    });
  }

  ngOnDestroy(): void {
    try {
      this._cambiosFormulario$.unsubscribe();
      this._archivos$.unsubscribe();
    } catch (error) { console.warn('¡Suscripción no existe!'); }
  }

  public toggleTooltip(esMostrar: boolean): void {
    this.esMostrarTooltip = esMostrar;
  }

  public isMandatory(): IFile[] {
    return this.documentos.length > 0
      ? this.documentos.filter(file => file.esRequerido)
      : [];
  }

  public atLeastOne(): IFile[] {
    return this.documentos.length > 0
      ? this.documentos.filter(file => file.atLeastOne)
      : [];
  }

  public isOptional(): IFile[] {
    return this.documentos.length > 0
      ? this.documentos.filter(file => !file.esRequerido && !file.atLeastOne)
      : [];
  }

  private agregarTipoDocumentos(idPrestacion: number): void {
    if (idPrestacion === 0) {
      this.documentos = [...this.storage.ConsultaMedicaDoc];
    } else if (idPrestacion === 1) {
      this.documentos = this.separatedUpload ? [...this.storage.AtencionHospitalariaDoc] : this.documentos = [ this.storage.docHospitalario ];
    } else if (idPrestacion === 2) {
      this.documentos = [...this.storage.MarcosLentesDoc];
    } else if (idPrestacion === 4) {
      this.documentos = [...this.storage.ExamenesProcedimientosDoc];
    }
  }

  private validarSubidaCompleta(): boolean {
    return this.reembolso.validarSubidaCompleta(this.documentos);
  }

  private verificarMedicamentos(): boolean {
    const montoMedicamentos: string = this.form.get('montoMedicamentos')?.value;
    const nBoleta: string = this.form.get('nBoleta')?.value;
    const rutInstitucion: string = this.form.get('rutInstitucion')?.value;
    const fecha: string = this.form.get('fechaAtencion')?.value;
    const esReceta: boolean = this.form.get('confirmar')?.value;

    if (montoMedicamentos === '' || montoMedicamentos === '$0' ) return false;
    if (nBoleta === '') return false;
    if (rutInstitucion === '') return false;
    if (fecha === '') return false;
    if (esReceta === undefined) return false;

    return true;
  }

  public selectUploadHospitalCare(separatedUpload: boolean): void {
    if (this.separatedUpload === separatedUpload) return;
    this.showWarningModal = this.reembolso.obtenerArchivosActuales()?.length > 0
    if (!this.showWarningModal) this.separatedUpload = separatedUpload;
    this.agregarTipoDocumentos(1);
    if (this.showWarningModal) this.modalService.scrollTo('scrollTop');
  }

  public deleteFilesHospitalCare(): void {
    this.separatedUpload = !this.separatedUpload;
    this.agregarTipoDocumentos(1);
    this.reembolso.vaciarArchivos();
    this.closeWarningModal();
  }

  public closeWarningModal() {
    this.showWarningModal = false;
    this.modalService.scrollTo('selectUpload');
  }
  public closeSizeErrorModal(): void {
    this.showFileSizeErrorModal = false;
  }

  public showFileError(error: ETypeError): void {
    if (error === ETypeError.ERROR_MB) {
      this.showFileSizeErrorModal = true;
    }
  }

  trackByRadio(_: number, radio: RadioElement): ETypeFile {
    return radio.id;
  }

  trackByDocument(_: number, documento: IFile): ETypeFile {
    return documento.idTipoArchivo;
  }
}

interface RadioElement {
  id: ETypeFile;
  nombre: string;
}