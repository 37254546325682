<div class="resumen">
  <!-- Header. -->
  <div class="resumen__header">
    <label class="texto texto--izquierda">
      <strong>Detalle de reembolso de:</strong>{{ nombreBeneficiario | titlecase }}
    </label>
    <div class="volver texto--derecha">
      <img class="volver__icono" src="assets/svgs/izquierda.svg" (click)="regresar()">
      <label class="volver__texto" (click)="regresar()">Volver atrás</label>
    </div>
  </div>

  <!-- Tabla. -->
  <div class="resumen__tabla">
    <app-summary-table></app-summary-table>
  </div>

  <!-- Pregunta continuar. -->
  <div class="contenedor__continuar" *ngIf="prestaciones.length > 0">
    <div class="continuar" [formGroup]="pregunta">
      <label class="texto texto--izquierda">
        <strong>¿Quieres agregar un nuevo reembolso a esta solicitud?</strong>
      </label>
      <div class="radios texto--derecha">
        <label class="container" *ngFor="let item of preguntas; trackBy: trackByPregunta">
          <label [for]="item.valor">{{ item.label }}</label>
          <input type="radio" [checked]="this.pregunta.get('pregunta')?.value === item.valor" [value]="item.valor" formControlName="respuesta">
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div class="cards" [ngClass]="{'cards--full': prestaciones.length === 1}" *ngIf="this.pregunta.get('respuesta')?.value === true">
      <ng-container *ngFor="let prestacion of prestaciones; trackBy: trackByPrestacion">
        <app-summary-services-provided [idSeleccion]="idSeleccion" [cardPrestacion]="prestacion" (click)="seleccionCard(prestacion)"></app-summary-services-provided>
      </ng-container>
    </div>
  </div>

  <!-- Botones finalización. -->
  <div class="botones" *ngIf="prestaciones.length === 0 || this.pregunta.get('respuesta')?.valid">
    <vs-button (click)="cancelar()" texto="Cancelar" type="secondary" size="small"></vs-button>
    <vs-button (click)="continuar()" [disabled]="!esPuedePresionar" [texto]="textoBoton" type="primary" size="medium"></vs-button>
  </div>
</div>

<app-success-modal *ngIf="folio !== ''" [numeroSolicitado]="folio" [ctaBancaria]="ctaBancaria" [nombreBanco]="nombreBanco"></app-success-modal>

<app-error-modal
  *ngIf="esMostrarError"
  (primaryAction)="continue()"
  (secondaryAction)="cancelar()"
></app-error-modal>