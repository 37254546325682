import { GenericMessage } from "@core/models";

export const GENERIC_ERROR_MESSAGE: GenericMessage = {
  title: 'No se pudo cargar la información',
  message: 'Lo sentimos, pero hubo un problema al intentar cargar los datos.<br> Por favor, inténtalo más tarde.',
  retryMessage: 'Lo sentimos, pero hubo un problema al intentar cargar los datos.<br> Por favor, inténtalo nuevamente.',
  link: 'Recargar información',
};

export const TOKEN_ERROR = 'No se ha podido resfrescar el token.'
export const INSUREDS_ERROR = '¡No se pudo recuperar los datos del asegurado!';
