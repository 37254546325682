<div class="datos">
  <stepper [porcentajeProgeso]="porcentaje" [id]="2"></stepper>
  <div class="datos__contenido" [ngClass]="{'datos__contenido--min': porcentaje < 0}">
    <header [porcentajeProgeso]="porcentaje" texto="Completa los siguientes datos"></header>
    <ng-container *ngIf="porcentaje >= 0">
      <div class="datos__inputs transicion-de-despliegue" [ngClass]="{'datos__inputs--col': (this.form.get('prestacion')?.value !== 1)}">
        <ng-container [ngSwitch]="this.form.get('prestacion')?.value">
          <ng-container *ngSwitchCase="1">
            <vs-input [form]="form" nameControl="montoHospitalario"></vs-input>
          </ng-container>
          <ng-container *ngSwitchCase="5">
            <vs-input [form]="form" nameControl="rutInstitucion"></vs-input>
            <vs-calendar [form]="form" nameControl="fechaAtencion"></vs-calendar>
            <vs-input [form]="form" nameControl="nBoleta"></vs-input>
            <vs-input [form]="form" nameControl="montoMedicamentos"></vs-input>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <vs-select [form]="form" nameControl="isapreFonasa"></vs-select>
            <vs-input [form]="form" nameControl="rutInstitucion"></vs-input>
            <vs-input [form]="form" nameControl="nBoleta"></vs-input>
            <vs-calendar [form]="form" nameControl="fechaAtencion"></vs-calendar>
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="porcentaje >= 90">
        <div *ngIf="prestacionSeleccionada && prestacionSeleccionada.confirmar" class="pregunta question-despliegue" [formGroup]="form">
          <div class="pregunta__contenedor">
            <ng-container *ngIf="prestacionSeleccionada.confirmar">
              <p class="pregunta__texto">{{ prestacionSeleccionada.confirmar }}</p>
              <div class="radio__contenedor">
                <label class="container" *ngFor="let item of pregunta; trackBy: trackByPregunta">
                  <label [for]="item.valor">{{ item.label }}</label>
                  <input type="radio" [checked]="this.form.get('confirmar')?.value === item.valor" [value]="item.valor" formControlName="confirmar">
                  <span class="checkmark"></span>
                </label>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>