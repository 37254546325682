import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import {
  ReembolsoService,
  DataUsuarioService,
  AppInsightsService,
  StorageService,
  ReembolsoActualService,
} from '@common/services';
import { Reembolso, Usuario } from '@common/models';
import {
  IConsignment,
  IResponseConsignment,
  IPrestacion,
  IGasto,
  IDocument,
  IFileUpload,
} from '@common/interfaces';
import { mapCoberturas, reemplazarApostrofes } from '@common/utils';
import { HistoricalService } from '@claims/features/historical/services/historical.service';
import { SummaryTableComponent } from '../summary-table/summary-table.component';
import { SharedModule } from '@common/shared.module';
import { SummaryServicesProvidedComponent } from '../summary-services-provided/summary-services-provided.component';
import { ModalsModule } from '@common/components/modals/modals.module';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    ModalsModule,
    SummaryTableComponent,
    SummaryServicesProvidedComponent,
  ]
})
export class SummaryComponent implements OnInit, OnDestroy {
  @Output() eventVolver = new EventEmitter<boolean>();
  @Output() eventCrearNuevo = new EventEmitter<boolean>();
  @Input() form!: UntypedFormGroup;
  public cambioPregunta$!: Subscription;
  public usuario: Usuario;
  public nombreBeneficiario = '';
  public textoBoton = 'Finalizar y enviar';
  public esPuedePresionar = true;
  public esMostrarError = false;
  public idSeleccion: number = -1;
  public prestaciones: IPrestacion[] = [];
  public folio = '';
  public ctaBancaria = '';
  public nombreBanco = '';
  public pregunta: UntypedFormGroup;
  public preguntas: IPregunta[] = [
    { label: 'Sí', valor: true },
    { label: 'No', valor: false },
  ];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private reembolso: ReembolsoActualService,
    private storage: StorageService,
    private dataUsuario: DataUsuarioService,
    private consignmentService: ReembolsoService,
    private router: Router,
    private appInsightsService: AppInsightsService,
    private readonly historicalService: HistoricalService
  ) {
    this.pregunta = this.formBuilder.group({
      respuesta: ['', [Validators.required]],
    });

    this.usuario = this.dataUsuario.usuarioConectado;

    switch (this.reembolso.idPrestacion) {
      case 0:
      case 2:
      case 4:
      case 5:
        this.prestaciones.push(this.storage.cardPrestaciones[0]);
        this.prestaciones.push(this.storage.cardPrestaciones[2]);
        this.prestaciones.push(this.storage.cardPrestaciones[4]);
        this.prestaciones.push(this.storage.cardPrestaciones[5]);
        break;
      case 3:
        this.prestaciones.push(this.storage.cardPrestaciones[3]);
        break;
      default:
        break;
    }

    if (this.reembolso.idPrestacion === 1) {
      this.esPuedePresionar = true;
    }

    this.nombreBeneficiario =
      this.reembolso.reembolsos[0].beneficiarioSeleccionado.nombres +
      ' ' +
      this.reembolso.reembolsos[0].beneficiarioSeleccionado.apellidos;
  }

  ngOnInit(): void {
    this.appInsightsService.trackPageView('Resumen');
    this.cambioPregunta$ = this.pregunta.valueChanges.subscribe((pregunta) => {
      if (pregunta.respuesta === false) {
        this.textoBoton = 'Finalizar y enviar';
        this.esPuedePresionar = true;
      } else {
        this.textoBoton = 'Continuar';
        this.esPuedePresionar = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.cambioPregunta$.unsubscribe();
  }

  public regresar(): void {
    this.eventVolver.emit(false);
    this.reembolso.eliminarUltimoReembolso();
  }

  public seleccionCard(prestacion: IPrestacion): void {
    this.idSeleccion = prestacion.idPrestacion;
    this.esPuedePresionar = true;
  }

  public async continuar(): Promise<void> {
    if (!this.esPuedePresionar) return;
    if (this.textoBoton === 'Continuar') {
      this.storage.idSeleccionado = this.idSeleccion;
      this.storage.coberturaSeleccionada = this.filtrarCobertura(
        this.idSeleccion
      );
      this.storage.esOtroReembolso = true;
      this.eventCrearNuevo.emit(true);
    } else if (this.textoBoton === 'Finalizar y enviar') {
      document.body.style.cursor = 'wait';
      this.esPuedePresionar = false;
      try {
        const consignment: IConsignment = this.crearConsignment();
        const respuesta: IResponseConsignment =
          (await this.consignmentService.postConsignment(
            consignment
          )) as IResponseConsignment;
        this.dataUsuario.initialTotalInCurse = 0;
        this.folio = respuesta.remesa;
        this.ctaBancaria = respuesta.accountBankNumber;
        this.nombreBanco = respuesta.accountBankName;
        await this.historicalService.getReimbursements(true);
        if (consignment.idIsapre != 217)
          this.dataUsuario.usuarioConectado.obtenerUsuarioSeleccionado().isapreCode =
            consignment.idIsapre.toString();
        this.appInsightsService.trackFlow('Flujo completado', {
          page: '/resumen',
        });
      } catch (error) {
        console.warn(error);
        this.esMostrarError = true;
      } finally {
        document.body.style.cursor = 'default';
      }
    }
  }

  public continue(): void {
    this.esMostrarError = false;
    this.esPuedePresionar = true;
  }

  public cancelar(): void {
    this.router.navigate(['/historial']);
    this.storage.idSeleccionado = -1;
    this.storage.coberturaSeleccionada = undefined;
    this.storage.esOtroReembolso = false;
    this.storage.reiniciarCards();
    this.reembolso.reiniciarServicio();
    this.appInsightsService.trackFlow('Abandono', {
      page: '/resumen',
    });
  }

  private filtrarCobertura(idPrestacion: number): number {
    switch (idPrestacion) {
      case 0:
      case 2:
      case 4:
      case 5:
        return 95;
      case 1:
        return 96;
      case 3:
        return 97;
    }
    return -1;
  }

  private crearConsignment(): IConsignment {
    const reembolsos: Reembolso[] = this.reembolso.reembolsos;
    const consignment: IConsignment = {
      policy: reembolsos[0].obtenerPoliza(reembolsos[0].idPrestacion),
      rutInsuranceCo: reembolsos[0].obtenerRutAseguradora(
        +reembolsos[0].obtenerPoliza(reembolsos[0].idPrestacion),
        this.dataUsuario.usuarioConectado.polizas
      ),
      clasif: this.obtenerClasificacion(reembolsos[0].idPrestacion),
      codCobertura: mapCoberturas(reembolsos[0].idPrestacion),
      fechaDenuncia: new Date(),
      rutTitular: this.usuario.cargas[0].rut,
      dvRutTitular: this.usuario.cargas[0].dv,
      rutBeneficiario: reembolsos[0].beneficiarioSeleccionado.rut,
      dvRutBeneficiario: reembolsos[0].beneficiarioSeleccionado.dv,
      nombresBeneficiario: reemplazarApostrofes(
        reembolsos[0].beneficiarioSeleccionado.nombres
      ),
      apellidosBeneficiario: reemplazarApostrofes(
        reembolsos[0].beneficiarioSeleccionado.apellidos
      ),
      idIsapre: reembolsos[0].codigoIsapre,
      mailCliente: this.usuario.mailCliente,
      codigoBanco: `${this.usuario.codBanco}`,
      nombreBanco: this.usuario.nombreBanco,
      numeroCuenta: this.usuario.ctaBancaria,
      plataforma: this.obtenerPlataforma(),
      sistemaOperativo: this.storage.osActual,
      nombresTitular: reemplazarApostrofes(this.usuario.cargas[0].nombres),
      apellidosTitular: reemplazarApostrofes(this.usuario.cargas[0].apellidos),
      folioDenuncio: 0,
      montoTotal: this.reembolso.obtenerGastoTotal(),
      gastos: this.crearGastos(reembolsos),
    };
    return consignment;
  }

  private crearGastos(reembolsos: Reembolso[]): IGasto[] {
    const gastos: IGasto[] = [];
    reembolsos.forEach((reembolso) => {
      const archivoPrincipal = reembolso.obtenerArchivoPrincipal();
      const gasto: IGasto = {
        idPrestacion: reembolso.idPrestacionVT,
        folio: +reembolso.getFolio ? +reembolso.getFolio : 0,
        fecha: reembolso.fechaAtencion,
        idTipoDoc: +reembolso.getTipoDoc,
        rutPrestador: reembolso.rutPrestador,
        extension: archivoPrincipal.extension,
        base64: archivoPrincipal.base64,
        origenImagen: archivoPrincipal.origen,
        pesoKB: archivoPrincipal.pesoKB,
        diagnostico: '',
        diagnosticoMonto: 0,
        montoDocumento: +reembolso.getMontoTotalSolicitado,
        flagRecetaPermanente: reembolso.esRecetaPermanente,
        flagDescuentoAcumulado: reembolso.flagDescuentoAcumulado,
        descuentoAcumulado: +reembolso.descuentoAcumulado,
        flagDocEnvIsapre: reembolso.esPasoPorIsapre,
        flagMasDeUnaSesion: reembolso.flagMasDeUnaSesion,
        aranceles: reembolso.aranceles,
        docAdicionales: this.crearDocAdicionales(reembolso),
        docDiagnostico: [],
      };
      gastos.push(gasto);
    });
    return gastos;
  }

  private crearDocAdicionales(reembolso: Reembolso): IDocument[] {
    if (reembolso.archivosAdjuntos.length === 1) return [];
    const docAdicionales: IDocument[] = [];
    const docsSecundarios: IFileUpload[] =
      reembolso.obtenerArchivosSecundarios();
    for (let index = 0; index < docsSecundarios.length; index++) {
      const doc: IDocument = {
        base64: docsSecundarios[index].base64,
        extension: docsSecundarios[index].extension,
        origenImagen: docsSecundarios[index].origen,
        pesoKB: docsSecundarios[index].pesoKB,
      };
      docAdicionales.push(doc);
    }
    return docAdicionales;
  }

  private obtenerClasificacion(idPrestacion: number): number {
    let clasificacion: number;
    switch (idPrestacion) {
      case 0:
      case 2:
      case 4:
      case 5:
        clasificacion = 1;
        break;
      case 3:
        clasificacion = 2;
        break;
      case 1:
        clasificacion = 3;
        break;
      default:
        clasificacion = -1;
        break;
    }
    return clasificacion;
  }

  private obtenerPlataforma(): string {
    if (
      'maxTouchPoints' in window.navigator &&
      window.navigator.maxTouchPoints > 0
    ) {
      return 'MOVIL';
    }
    return 'DESKTOP';
  }

  trackByPregunta(_: number, item: IPregunta): boolean {
    return item.valor;
  }

  trackByPrestacion(_: number, prestacion: IPrestacion): number {
    return prestacion.idPrestacion;
  }
}

interface IPregunta {
  label: string;
  valor: boolean;
}
