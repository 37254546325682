<div class="datos">
  <stepper [porcentajeProgeso]="porcentaje" [id]="2"></stepper>
  <div class="datos__contenido" [ngClass]="{'datos__contenido--min': porcentaje < 0}">
    <header [porcentajeProgeso]="porcentaje" texto="Completa los siguientes datos"></header>
    <ng-container *ngIf="porcentaje >= 0">
      <div class="datos__inputs transicion-de-despliegue" [ngClass]="{'datos__inputs--col': (this.form.get('prestacion')?.value !== 1)}">
        <ng-container [ngSwitch]="this.form.get('prestacion')?.value">
          <ng-container *ngSwitchCase="1">
            <vs-input [form]="form" nameControl="montoHospitalario"></vs-input>
          </ng-container>
          <ng-container *ngSwitchCase="5">
            <vs-input [form]="form" nameControl="rutInstitucion"></vs-input>
            <vs-calendar [form]="form" nameControl="fechaAtencion"></vs-calendar>
            <vs-input [form]="form" nameControl="nBoleta"></vs-input>
            <vs-input [form]="form" nameControl="montoMedicamentos"></vs-input>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <vs-select [form]="form" nameControl="isapreFonasa"></vs-select>
            <vs-input [form]="form" nameControl="rutInstitucion"></vs-input>
            <vs-input [form]="form" nameControl="nBoleta"></vs-input>
            <vs-calendar [form]="form" nameControl="fechaAtencion"></vs-calendar>
          </ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="porcentaje >= 90 && this.form.get('prestacion')?.value !== 5">
        <question
          *ngIf="prestacionSeleccionada && prestacionSeleccionada.confirmar"
          [form]="form"
          [question]="prestacionSeleccionada.confirmar"
        ></question>
      </ng-container>
    </ng-container>
  </div>
</div>