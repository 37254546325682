import { Component, Input } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { Historical } from '@core/models';
import { ReimbursementStatusComponent } from '../reimbursement-status/reimbursement-status.component';
import { formateoValor } from '@common/utils';

@Component({
  selector: 'app-reimbursement-info',
  templateUrl: './reimbursement-info.component.html',
  styleUrls: ['./reimbursement-info.component.scss'],
  standalone: true,
  imports: [ReimbursementStatusComponent, CommonModule],
  providers: [DatePipe],
})
export class ReimbursementInfoComponent {
  @Input() claim!: Historical;

  public formatValue: (valor: number) => string = formateoValor;

}
