/* Tipos de Documentos
     Obligatorios: requerido=true
     Al menos uno: requerido=false, atLeastOne=true
     Opcional: requerido=false atLeastOne=false
     Documento principal: idTipoDoc tiene valor
*/

import { ETypeFile } from '@common/enum';
import { IFile } from '@common/interfaces';

export const ID_DOCTYPE = {
  hospitalario: 11,
  reembolsoIsapreFonasa: 4,
  otrosBonosAtencion: 8,
  factura: 11,
  facturaOtrasPrestaciones: 7,
}

export const DocBono: IFile = {
  idTipoArchivo: ETypeFile.DOC_BONO,
  nombre: 'Documento de bono de atención',
  prefix: 'Bono de atención',
  esRequerido: true,
  esUnico: false,
};

// Documentos Principales
export const DocReembAmbulatorioIsapreFonasa: IFile = {
  idTipoArchivo: ETypeFile.DOC_REEMB_AMBULATORIO_ISAPRE_FONASA,
  nombre: 'reembolso ambulatorio Isapre o Fonasa',
  prefix: 'Reembolso ambulatorio',
  esRequerido: false,
  esUnico: false,
  atLeastOne: true,
  idTipoDoc: ID_DOCTYPE.reembolsoIsapreFonasa,
};

export const DocAtencionIsapreFonasa: IFile = {
  idTipoArchivo: ETypeFile.DOC_ATENC_ISAPRE_FONASA,
  nombre: 'otro bono atención Isapre o Fonasa',
  prefix: 'Bono de atención',
  esRequerido: false,
  esUnico: false,
  atLeastOne: true,
  idTipoDoc: ID_DOCTYPE.otrosBonosAtencion,
};

export const DocBoletaFactura: IFile = {  // Factura otras prestaciones
  idTipoArchivo: ETypeFile.DOC_BOLETA_FACTURA,
  nombre: 'Boleta / Factura',
  esRequerido: false,
  esUnico: false,
  atLeastOne: true,
  idTipoDoc: ID_DOCTYPE.factura,
};

export const DocBoletaFacturaOtrasPrestaciones: IFile = {  // Factura Atención Dental
  idTipoArchivo: ETypeFile.DOC_BOLETA_FACTURA,
  nombre: 'Boleta / Factura',
  esRequerido: false,
  esUnico: false,
  atLeastOne: true,
  idTipoDoc: ID_DOCTYPE.facturaOtrasPrestaciones,
};
//

export const DocHospitalario: IFile = {
  idTipoArchivo: ETypeFile.DOC_HOSPITALARIO,
  nombre: 'archivo con todos los documentos',
  prefix: 'Hospitalización',
  esRequerido: true,
  esUnico: true,
};

export const DocAtencionDental: IFile = {
  idTipoArchivo: ETypeFile.DOC_ATENCION_DENTAL,
  nombre: 'archivo con todos los documentos',
  prefix: 'Documentos dentales',
  esRequerido: true,
  esUnico: true,
};

export const RecetaOptica: IFile = {
  idTipoArchivo: ETypeFile.RECETA_OPTICA,
  nombre: 'Receta óptica',
  esRequerido: true,
  esUnico: false,
};

export const RecetaMedica: IFile = {
  idTipoArchivo: ETypeFile.RECETA_MEDICA,
  nombre: 'Receta médica',
  esRequerido: true,
  esUnico: false,
};

export const RecetaMedicaOpcional: IFile = {
  idTipoArchivo: ETypeFile.RECETA_MEDICA,
  nombre: 'Receta médica',
  esRequerido: false,
  esUnico: false,
};

export const FormularioDental: IFile = {
  idTipoArchivo: ETypeFile.FORMULARIO_DENTAL,
  nombre: 'Formulario reembolso o presupuesto dental',
  prefix: 'Formulario reembolso',
  esRequerido: true,
  esUnico: false,
};

export const DocDiagnostico: IFile = {
  idTipoArchivo: ETypeFile.DOC_DIAGNOSTICO,
  nombre: 'Documento de diagnóstico',
  esRequerido: true,
  esUnico: false,
};

export const DocAdicional: IFile = {
  idTipoArchivo: ETypeFile.DOC_ADICIONAL,
  nombre: 'documentos adicionales',
  esRequerido: false,
  esUnico: false,
};

export const DocReingreso: IFile = {
  idTipoArchivo: ETypeFile.DOC_REINGRESO,
  nombre: 'Documento',
  esRequerido: true,
  esUnico: false,
};

export const DocReembolso: IFile = {
  idTipoArchivo: ETypeFile.DOC_REEMBOLSO,
  nombre:
    'bonos, reembolsos (asociados a boletas de honorarios) y facturas o notas de crédito',
  prefix: 'reembolsos o facturas',
  esRequerido: true,
  esUnico: false,
};

export const DocDetallePrograma: IFile = {
  idTipoArchivo: ETypeFile.DOC_DETALLE_PROGRAMA,
  nombre: 'detalle de la cuenta y programa médico',
  prefix: 'detalle de cuenta',
  esRequerido: true,
  esUnico: false,
};

export const DocEpicrisis: IFile = {
  idTipoArchivo: ETypeFile.DOC_EPICRISIS,
  nombre: 'epicrisis o informe médico que indique diagnóstico',
  prefix: 'epicrisis',
  esRequerido: true,
  esUnico: false,
};

export const DocProtocolo: IFile = {
  idTipoArchivo: ETypeFile.DOC_PROTOCOLO_OPERATORIO,
  nombre: 'protocolo operatorio si corresponde a intervención quirúrgica',
  prefix: 'protocolo operatorio',
  esRequerido: false,
  esUnico: false,
};

export const DocDeclaracion: IFile = {
  idTipoArchivo: ETypeFile.DOC_DECLARACION_HECHOS,
  nombre: 'declaración simple de los hechos en caso de accidente',
  prefix: 'declaración',
  esRequerido: false,
  esUnico: false,
};

export const DocFacturaCompra: IFile = {
  idTipoArchivo: ETypeFile.DOC_FACTURA_COMPRA,
  nombre: 'boleta o factura con detalle de compra',
  prefix: 'factura de compra',
  esRequerido: true,
  esUnico: false,
  idTipoDoc: ID_DOCTYPE.facturaOtrasPrestaciones,
};

export const DocReembolsoDental: IFile = {
  idTipoArchivo: ETypeFile.PRESUPUESTO_DENTAL,
  nombre: 'formulario de reembolso o presupuesto dental indicando detalle de atención y fecha',
  prefix: 'Reembolso o Presupuesto Dental',
  esRequerido: true,
  esUnico: false,
};

export const AtencionHospitalariaDoc: IFile[] = [
  DocReembolso,
  DocDetallePrograma,
  DocEpicrisis,
  DocProtocolo,
  DocDeclaracion,
];

export const ConsultaMedicaDoc: IFile[] = [
  DocReembAmbulatorioIsapreFonasa,
  DocAtencionIsapreFonasa,
  DocBoletaFactura,
  DocAdicional,
];

export const MarcosLentesDoc: IFile[] = [
  RecetaMedica,
  DocReembAmbulatorioIsapreFonasa,
  DocAtencionIsapreFonasa,
  DocBoletaFactura,
  DocAdicional,
];

export const ExamenesProcedimientosDoc: IFile[] = [
  DocReembAmbulatorioIsapreFonasa,
  DocAtencionIsapreFonasa,
  DocBoletaFactura,
  DocAdicional,
];

export const CompraMedicamentosPermanentesDoc: IFile[] = [
  DocFacturaCompra,
  RecetaMedicaOpcional,
  DocAdicional
]

export const CompraMedicamentosDoc: IFile[] = [
  DocFacturaCompra,
  RecetaMedica,
  DocAdicional
]

export const AtencionDentalDoc: IFile[] = [
  DocReembolsoDental,
  DocReembAmbulatorioIsapreFonasa,
  DocAtencionIsapreFonasa,
  DocBoletaFacturaOtrasPrestaciones,
  DocAdicional,
]
