import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { environment } from '@environments/environment';
import { Claim, Historical, IClaims } from '@core/models';
import { LoadingService } from '@utils/services/loading/loading.service';
import { DataUsuarioService } from '@common/services';
import { ReimbursementStatus } from '../components/reimbursement-status/reimbursement-status.enum';
import { DateKey, MAX_CACHE_TIME_PERMITED } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class HistoricalService {
  private _historical: Historical[] = [];
  private _underEvaluacion: Historical[] = [];
  private _totalUnderEvaluacion: number = 0;
  private _totalHistorical: number = 0;
  private _recordsPerPage: number = 10;

  public get historical(): Historical[] {
    return this._historical;
  }

  public get underEvaluacion(): Historical[] {
    return this._underEvaluacion;
  }

  public get totalUnderEvaluacion(): number {
    return this._totalUnderEvaluacion;
  }

  public get totalHistorical(): number {
    return this._totalHistorical;
  }

  public underEvaluacionPerPage(pageNumber: number): Historical[] {
    return this._underEvaluacion.slice(
      (pageNumber - 1) * this._recordsPerPage,
      this._recordsPerPage * pageNumber
    );
  }

  public historicalPerPage(pageNumber: number): Historical[] {
    return this._historical.slice(
      (pageNumber - 1) * this._recordsPerPage,
      this._recordsPerPage * pageNumber
    );
  }

  public set recordsPerPage(recordsPerPage: number) {
    this._recordsPerPage = recordsPerPage;
  }

  private loadingService: LoadingService = inject(LoadingService);
  private dataUsuarioService: DataUsuarioService = inject(DataUsuarioService);

  constructor(private http: HttpClient) {}

  async getReimbursements(forceRequest: boolean = false): Promise<boolean> {
    this.loadingService.show();
    try {
      if (forceRequest || !this.loadFromCache()) {
        const dates: string[] = this.generateDates();
        const headers = new HttpHeaders();
        const { rut, dv } = this.dataUsuarioService.getUserRUT();
        const esSBS: string | null = sessionStorage.getItem('esSBS');
        const filtro = esSBS && esSBS === 'true' ? 'SBS' : 'WEB';
        const url = `${environment.URL_BFF_V2_BASE}/v1/bff/history/${rut}-${dv}?startDate=${dates[0]}&endDate=${dates[1]}&Origin=${filtro}`;

        const historyResponse = await this.http
          .get<IClaims>(url, { headers })
          .toPromise();

        this.saveInStorage();
        this.saveInDataUsuario(historyResponse);
      }
      this.loadingService.hide();
      return true;
    } catch (error: any) {
      if (error?.status === 404)  {
        this.notFoundHistory();
      }
      this.loadingService.hide();
      throw error;
    }
  }

  // Valido si han transcurrido mas de 30 minutos desde la ultima consulta al servicio de historial de claims
  loadFromCache(): boolean {
    const lastClaim = sessionStorage.getItem(DateKey);
    return lastClaim
      ? moment().diff(moment(lastClaim, moment.ISO_8601), 'minutes') <
          MAX_CACHE_TIME_PERMITED
      : false;
  }

  saveInDataUsuario(historyResponse: IClaims): void {
    let allHistory: Claim[] = historyResponse.response.claims;
    this._underEvaluacion = [];
    this._historical = [];

    allHistory.forEach((claim: Claim) => {
      const historical = new Historical(claim);
      if (
        historical.claimStatus.toLowerCase() ===
        ReimbursementStatus.underEvaluation
      ) {
        this._underEvaluacion.push(historical);
      } else {
        this._historical.push(historical);
      }
    });

    this._totalHistorical = this._historical.length;
    this._totalUnderEvaluacion = this._underEvaluacion.length;
  }

  saveInStorage(): void {
    const now = moment().toISOString();
    sessionStorage.setItem(DateKey, now);
  }

  notFoundHistory() {
    this.saveInStorage();
    this._underEvaluacion = [];
    this._historical = [];
    this._totalHistorical = 0;
    this._totalUnderEvaluacion = 0;
  }

  private generateDates(): string[] {
    const currentDate = moment();
    const lastYear = moment().subtract(1, 'year');
    return [lastYear.format('YYYYMMDD'), currentDate.format('YYYYMMDD')];
  }
}
