<div *ngIf="message" class="message__container" [class.message__container--border]="borderClass">
  <img class="message__icon" [src]="icon" [alt]="title" />

  <p class="message__title">{{ title }}</p>

  <p *ngIf="message" class="message__text" [innerHTML]="message"></p>

  <span *ngIf="link" class="message__text message__link" (click)="onAction()">
    {{ link }}
  </span>
</div>
