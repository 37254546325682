import { IBeneficiario, IUsuario, IPolizas } from '@common/interfaces';
import { Pagination, Historical } from '@core/models';

export class Usuario {
  private readonly _codigoAsegurado: number;
  private readonly _mailCliente: string;
  private readonly _nombres: string;
  private readonly _apellidos: string;
  private readonly _ctaBancaria: string;
  private readonly _codBanco: number;
  private readonly _nombreBanco: string;
  private readonly _codigoIsapre: string;
  private readonly _estado: string;
  private readonly _cargas: IBeneficiario[] = [];
  private readonly _polizas: IPolizas[] = [];
  private _esTerminosAceptados: boolean;

  constructor(datosUsuario: IUsuario, rut: string, dv: string) {
    this._codigoAsegurado = datosUsuario.insured.code;
    this._mailCliente = datosUsuario.insured.mail;
    this._nombres = datosUsuario.insured.names;
    this._apellidos = datosUsuario.insured.surnames;
    this._ctaBancaria = datosUsuario.insured.accountBank.number;
    this._codBanco = datosUsuario.insured.accountBank.bankCode;
    this._nombreBanco = datosUsuario.insured.accountBank.bankName;
    this._codigoIsapre = datosUsuario.insured.isapreCode;
    this._estado = datosUsuario.insured.status;
    this._esTerminosAceptados = datosUsuario.insured.termsConditions;

    if (datosUsuario.policies) {
      this._polizas = datosUsuario.policies.map((poliza) => {
        return {
          poliza: poliza.number,
          rutCompania: poliza.companyRut,
          rutAseguradora: poliza.insuranceCoRut,
        };
      });
    }

    const titularCarga: IBeneficiario = {
      rut: rut,
      dv: dv,
      nombres: datosUsuario.insured.names,
      apellidos: datosUsuario.insured.surnames,
      codParentesco: 0,
      parentesco: 'YO',
      estado: datosUsuario.insured.status,
      esSeleccionado: false,
      isapreCode: datosUsuario.insured.isapreCode,
    };
    this._cargas.push(titularCarga);
    this._cargas[0].coberturasPorPoliza =
      datosUsuario.insured.coverageByPolicy.map((poliza) => {
        return {
          cobertura: poliza.coverageCode,
          poliza: poliza.policyNumber,
          rutAseguradora: this._polizas.find(
            (pol) => pol.poliza === poliza.policyNumber
          )?.rutAseguradora!,
        };
      });

    if (datosUsuario.beneficiaries && datosUsuario.beneficiaries.length !== 0) {
      datosUsuario.beneficiaries.forEach((carga) => {
        const nuevaCarga: IBeneficiario = {
          rut: carga.rut,
          dv: carga.dv,
          nombres: carga.names,
          apellidos: carga.surnames,
          codParentesco: carga.relationship.code,
          parentesco: carga.relationship.name,
          estado: carga.status,
          esSeleccionado: false,
          isapreCode: carga.isapre,
        };

        this._cargas.push(nuevaCarga);
      });
    }

    if (this._cargas.length > 1) {
      for (let index = 1; index < this._cargas.length; index++) {
        this.cargas[index].coberturasPorPoliza = datosUsuario.beneficiaries[
          index - 1
        ].coverageByPolicy.map((poliza) => {
          return {
            cobertura: poliza.coverageCode,
            poliza: poliza.policyNumber,
            rutAseguradora: this._polizas.find(
              (pol) => pol.poliza === poliza.policyNumber
            )?.rutAseguradora!,
          };
        });
      }
    }

    this._cargas.forEach((carga) => {
      carga.coberturasPorPoliza?.forEach((poliza) => {
        if (
          poliza.cobertura === 95 &&
          !carga.coberturasPorPoliza?.find((poliza) => poliza.cobertura === 96)
        ) {
          carga.coberturasPorPoliza?.push({
            cobertura: 96,
            poliza: poliza.poliza,
            rutAseguradora: poliza.rutAseguradora,
          });
        } else if (
          poliza.cobertura === 96 &&
          !carga.coberturasPorPoliza?.find((poliza) => poliza.cobertura === 95)
        ) {
          carga.coberturasPorPoliza?.push({
            cobertura: 95,
            poliza: poliza.poliza,
            rutAseguradora: poliza.rutAseguradora,
          });
        }
      });
    });
  }

  public get nombreCompleto(): string {
    return this._nombres + ' ' + this._apellidos;
  }

  public get nombreAbreviado(): string {
    return this._nombres.split(' ')[0] + ' ' + this._apellidos.split(' ')[0];
  }

  public get nombres(): string {
    return this._nombres;
  }

  public get apellidos(): string {
    return this._apellidos;
  }

  public get iniciales(): string {
    return this._nombres.charAt(0) + this._apellidos.charAt(0);
  }

  public get rutCuerpo(): string {
    return this.cargas[0].rut;
  }

  public get rutDigitoVerificador(): string {
    return this.cargas[0].dv;
  }

  public get rutTitular(): string {
    return (this.cargas[0].rut + '-' + this.cargas[0].dv)
      .replace(/[.-]/g, '')
      .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
  }

  public get mailCliente(): string {
    return this._mailCliente.toLocaleLowerCase();
  }

  public get nombreBanco(): string {
    return this._nombreBanco;
  }

  public get ctaBancaria(): string {
    return this._ctaBancaria;
  }

  public get cargas(): IBeneficiario[] {
    return this._cargas;
  }

  public get codigoAsegurado(): number {
    return this._codigoAsegurado;
  }

  public get codBanco(): number {
    return this._codBanco;
  }

  public get codigoIsapre(): string {
    return this._codigoIsapre;
  }

  public get estado(): string {
    return this._estado;
  }

  public get esTerminosAceptados(): boolean {
    return this._esTerminosAceptados;
  }
  public set esTerminosAceptados(estado: boolean) {
    this._esTerminosAceptados = estado;
  }

  public reiniciarEstadoCargas(): void {
    this.cargas.forEach((carga) => {
      carga.esSeleccionado = false;
    });
  }

  public obtenerUsuarioSeleccionado(): IBeneficiario {
    const beneficiario: IBeneficiario = this.cargas.filter(
      (carga) => carga.esSeleccionado === true
    )[0];
    return beneficiario!;
  }

  public obtenerCoberturasUsuarioSeleccionado(): number[] {
    const beneficiario: IBeneficiario = this.obtenerUsuarioSeleccionado();
    const coberturas: number[] = beneficiario.coberturasPorPoliza!.map(
      (cobertura) => {
        return cobertura.cobertura;
      }
    );
    return coberturas;
  }

  public obtenerIniciales(nombres: string, apellidos: string): string {
    return nombres.charAt(0) + apellidos.charAt(0);
  }

  public obtenerNombreAbreviado(nombres: string, apellido: string): string {
    return nombres.split(' ')[0] + ' ' + apellido.split(' ')[0];
  }

  /// Retorno de Rut y Dv sin guión
  public get rutCuerpoDv(): string {
    return `${this.rutCuerpo}${this.rutDigitoVerificador}`;
  }

  public get rutTipoCuentaSinDV(): string {
    return this.cargas[0].rutAseguradora!.split('-')[0];
  }

  public filtrarRutPorBeneficiario(
    nombreCompleto: string
  ): [string, IBeneficiario] {
    const beneficiario: IBeneficiario = this.cargas.filter(
      (beneficiario) =>
        `${beneficiario.nombres} ${beneficiario.apellidos}` ===
        nombreCompleto.toUpperCase()
    )[0];
    return [`${beneficiario.rut}-${beneficiario.dv}`, beneficiario];
  }

  public get polizas(): IPolizas[] {
    return this._polizas;
  }

  public obtenerRutAseguradora(): string[] {
    const ruts: string[] = [];
    this.polizas.forEach((poliza) => {
      ruts.push(poliza.rutAseguradora);
    });
    return [...new Set(ruts)];
  }
}
