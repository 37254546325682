import { CommonModule } from '@angular/common';
import { Component, Output, EventEmitter, inject } from '@angular/core';

import { TelemetryService } from '@utils/telemetry/telemetry.service';
import { ReimbursementService } from '../../services/reimbursement.service';

@Component({
  selector: 'app-tabs-in-curse-historical',
  templateUrl: './tabs-in-curse-historical.component.html',
  styleUrls: ['./tabs-in-curse-historical.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class TabsInCurseHistoricalComponent {
  @Output() checkInCurse = new EventEmitter<boolean>();

  public inCurseChecked: boolean = true;
  private telemetryService: TelemetryService = inject(TelemetryService);
  private reimbursementService: ReimbursementService = inject(ReimbursementService);

  constructor() {
    this.telemetryService.trackEventService(this.reimbursementService.eventsTag.IN_CURSE_TAB);
  }

  isInCurse(isInCurse: boolean): void {
    this.inCurseChecked = isInCurse;
    const nameEvent: string = isInCurse
      ? this.reimbursementService.eventsTag.IN_CURSE_TAB
      : this.reimbursementService.eventsTag.HISTORICAL_TAB;
    this.telemetryService.trackEventService(nameEvent);
    this.checkInCurse.emit(isInCurse);
  }
}
