import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IArancelInfo } from '../../interfaces/IArancel';
import { ArancelService } from '../../services/arancel.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'vs-input-list',
  templateUrl: './vs-input-list.component.html',
  styleUrls: ['./vs-input-list.component.scss']
})
export class VsInputListComponent {
  @Input() nameControl = 'rutInstitucion';
  @Input() form!: UntypedFormGroup;
  public placeholder = 'Nombre Prestación';
  public maxLargo = 120;
  public tipoInput = 'text';
  public esValido = false;
  public esTieneTexto = false;
  public icono = 'check-green';
  public listadoFiltrado: IArancelInfo[] = [];

  constructor(
    private arancelService: ArancelService,
    private reembolso: ReembolsoActualService,
    private storage: StorageService,
  ) {
    if (this.reembolso.idPrestacion === 5) {
      this.placeholder = 'Nombre Medicamento';
    }
  }

  perdidaFocus(nameControl: string): void {
    setTimeout(() => {
      const input = document.getElementById(this.nameControl) as HTMLInputElement;
      if (this.form.get(nameControl)?.valid && this.arancelService.filtroCodigoArancel()) {
        input.classList.remove('input--error');
        input.classList.add('input--success');
        this.esValido = true;
      } else if (this.form.get(nameControl)?.value === '') {
        input.classList.remove('input--error');
        input.classList.remove('input--success');
        this.esValido = false;
      } else {
        input.classList.remove('input--success');
        input.classList.add('input--error');
        this.esValido = false;
      }
    }, 250);
  }

  enObtencionFocus(): void {
    this.form.get(this.nameControl)?.setValue('');
    this.arancelService.arancelSeleccionado = undefined;
    this.arancelService.desviadoOK = true;
    this.arancelService.montoHistorico = 0;
    this.esTieneTexto = false;
    this.perdidaFocus(this.nameControl);
  }

  escribiendo(): void {
    if (this.form.get(this.nameControl)?.value !== '') {
      this.esTieneTexto = true;
      const valor: string = this.form.get(this.nameControl)?.value;
      this.filtrarListado(valor);

      if (this.reembolso.idPrestacion === 3) {
        const index: number = this.listadoFiltrado.findIndex(
          arancel => arancel.CodigoArancel === this.storage.otrosDentales.CodigoArancel
        );
        if (index !== -1) {
          this.listadoFiltrado.splice(index, 1);
        }
        this.listadoFiltrado.push(this.storage.otrosDentales);
      }
    } else {
      this.esTieneTexto = false;
      this.listadoFiltrado = [];
      this.arancelService.arancelSeleccionado = undefined;
    }
  }

  filtrarListado(busqueda: string): void {
    const filtro: string = busqueda.toString().toLowerCase().trim();
    this.listadoFiltrado = this.arancelService.filtroAranceles(filtro);
  }

  seleccionarArancel(arancel: IArancelInfo): void {
    this.form.get(this.nameControl)?.setValue(arancel.Arancel);
    this.listadoFiltrado = [];
    this.arancelService.arancelSeleccionado = arancel;
  }

  trackByArancelInfo(_: number, arancel: IArancelInfo): string {
    return arancel.CodigoArancel;
  }
}
