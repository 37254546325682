import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { IDocumentEngine, INotificationDocument } from '@common/interfaces';
import {
  DataUsuarioService,
  PdfEngineService,
  StorageService,
} from '@common/services';
import { ErrorMessageComponent } from '@common/components';

@Component({
  selector: 'app-reimbursement-download',
  templateUrl: './reimbursement-download.component.html',
  styleUrls: ['./reimbursement-download.component.scss'],
  standalone: true,
  imports: [CommonModule, ErrorMessageComponent],
})
export class ReimbursementDownloadComponent {
  @Input() requestNumber!: number;
  @Input() showDownLoadLiq: boolean = false;

  public showErrorMessage: boolean = false;
  public canDownloadLiq = true;
  public canDownloadNot = true;

  private storage = inject(StorageService);
  private pdfEngineService = inject(PdfEngineService);
  private dataUsuarioService = inject(DataUsuarioService);

  // DownLoad Documents
  async downloadLiq(): Promise<void> {
    if (!this.canDownloadLiq) return;
    try {
      this.showErrorMessage = false;
      this.canDownloadLiq = false;
      const insuranceCoRut: string = this.storage.esDesdeSBS
        ? environment.RUTS.SBS
        : environment.RUTS.VS;
      const response: IDocumentEngine =
        await this.pdfEngineService.getDocumentLiq(
          this.requestNumber,
          parseInt(insuranceCoRut, 10)
        );
      this.downloadDocument(response.data, `LIQ_${this.requestNumber}`);
    } catch (error) {
      console.warn(error);
      this.showErrorMessage = true;
    } finally {
      this.canDownloadLiq = true;
    }
  }

  async downloadNotification(): Promise<void> {
    if (!this.canDownloadNot) return;
    this.canDownloadNot = false;
    this.showErrorMessage = false;
    try {
      const documentParameter: INotificationDocument = {
        BeneficiaryName:
          this.dataUsuarioService.usuarioConectado.nombreCompleto,
        BeneficiaryRut:
          this.dataUsuarioService.usuarioConectado.rutTitular.replace(
            /\./g,
            ''
          ),
        RequestNumber: this.requestNumber,
        Policy:
          +this.dataUsuarioService.usuarioConectado.cargas[0]
            .coberturasPorPoliza![0].poliza,
      };
      const response: IDocumentEngine =
        await this.pdfEngineService.getDocumentNta<INotificationDocument>(
          documentParameter
        );
      this.downloadDocument(
        response.data,
        `Nota_denuncio_${documentParameter.Policy}`
      );
    } catch (error) {
      console.warn('¡No se pudo obtener el documento!', error);
      this.showErrorMessage = true;
    } finally {
      this.canDownloadNot = true;
    }
  }

  private downloadDocument(b64: string, nameDocument: string): void {
    const linkSource: string = 'data:application/pdf;base64,' + b64;
    const downloadLink: HTMLAnchorElement = document.createElement('a');
    const filename: string = nameDocument + '.pdf';
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
  }
}
