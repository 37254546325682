import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment as ENV } from '@environments/environment';
import { Historical } from '@core/models';
import { IReentry, IFileUpload, IDocument, } from '@common/interfaces';
import { StorageService, DataUsuarioService } from '@common/services';

@Injectable({
  providedIn: 'root'
})
export class ReingresoService {
  private _esReingreso = false;
  private _archivosReingreso: BehaviorSubject<IFileUpload[]> = new BehaviorSubject<IFileUpload[]>([]);
  public archivosReingreso$: Observable<IFileUpload[]> = this._archivosReingreso.asObservable();
  private _historicoReingreso!: Historical;

  constructor(
    public storage: StorageService,
    private readonly dataUsuarioService: DataUsuarioService,
    private readonly winRef: Window
  ) { }

  public agregarArchivo(archivo: IFileUpload): void {
    this._archivosReingreso.next([...this._archivosReingreso.getValue(), archivo]);
  }

  public eliminarArchivo(id: string): void {
    this._archivosReingreso.next(this._archivosReingreso.getValue().filter(archivo => archivo.idArchivoSubido !== id));
  }

  public actualizarArchivo(archivo: IFileUpload): void {
    const archivos: IFileUpload[] = this._archivosReingreso.getValue();
    const index: number = archivos.findIndex(archivoReingreso => archivoReingreso.idArchivoSubido === archivo.idArchivoSubido);
    archivos[index] = archivo;
    this._archivosReingreso.next(archivos);
  }

  public obtenerArchivos(): IFileUpload[] {
    return this._archivosReingreso.getValue();
  }

  public reiniciarReingreso(): void {
    this.esReingreso = false;
    this._archivosReingreso.next([]);
  }

  public get esReingreso(): boolean {
    return this._esReingreso;
  }

  public set esReingreso(estado: boolean) {
    this._esReingreso = estado;
  }

  public get historicoReingreso(): Historical {
    return this._historicoReingreso;
  }

  public set historicoReingreso(value: Historical) {
    this._historicoReingreso = value;
  }

  public crearReingreso(): IReentry {
    const rutInsurance: string = this.storage.esDesdeSBS ? ENV.RUTS.SBS : ENV.RUTS.VS;

    const reentryConsignment: IReentry = {
      policy: `${this.historicoReingreso.policy}`,
      rutInsuranceCo: rutInsurance,
      codCobertura: '0063',
      fechaDenuncia: this.historicoReingreso.issueDate.toISOString(),
      rutTitular: this.dataUsuarioService.usuarioConectado.rutCuerpo,
      dvRutTitular: this.dataUsuarioService.usuarioConectado.rutDigitoVerificador,
      rutBeneficiario: `${this.historicoReingreso.beneficiaryRut}`,
      dvRutBeneficiario: this.historicoReingreso.beneficiaryDv,
      nombresBeneficiario: this.historicoReingreso.beneficiaryName,
      apellidosBeneficiario: '',
      plataforma: this.obtenerPlataforma(),
      sistemaOperativo: this.storage.osActual,
      nombresTitular: this.dataUsuarioService.usuarioConectado.nombres,
      apellidosTitular: this.dataUsuarioService.usuarioConectado.apellidos,
      folioDenuncio: this.historicoReingreso.requestNumber,
      montoTotal: this.historicoReingreso.claimedTotalAmount,
      originalInvoice: this.historicoReingreso.reEntry.originalInvoice,
      docs: this.obtenerArchivosReingreso()
    }

    return reentryConsignment;
  }

  private obtenerArchivosReingreso(): IDocument[] {
    const docReingresos: IFileUpload[] = this.obtenerArchivos();

    const documentos: IDocument[] = [];
    for (let index = 0; index < docReingresos.length; index++) {
      const archivo: IFileUpload = docReingresos[index];
      const documento: IDocument = {
        base64: archivo.base64,
        extension: archivo.extension,
        origenImagen: archivo.origen,
        pesoKB: archivo.pesoKB
      }
      documentos.push(documento);
    }
    return documentos;
  }

  private obtenerPlataforma(): string {
    const esMovil: boolean = this.winRef.innerWidth < 720;
    if (esMovil) return 'MOVIL';
    return 'DESKTOP';
  }
}
