import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '@common/components/modals/modal/modal.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {
  @Input() icon: string = 'assets/svgs/advertencia-archivos.svg';
  @Input() title: string = '';
  @Input() message: string = 'Lo sentimos, ha ocurrido un error al intentar procesar tu solicitud. <br> Por favor, inténtalo más tarde.';
  @Input() textPrimaryAction: string = 'Quiero volver a intentarlo';
  @Input() textSecondaryAction: string = 'Volver al inicio';
  @Output() primaryAction = new EventEmitter();
  @Output() secondaryAction = new EventEmitter();

  constructor(private modalService: ModalService) {}

  onPrimaryAction(): void {
    this.modalService.close();
    this.primaryAction.emit();
  }

  onSecondaryAction(): void {
    this.modalService.close();
    this.secondaryAction.emit();
  }
}
