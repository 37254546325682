export enum ETypeFile {
  DOC_REEMBOLSO,
  DOC_BONO,
  DOC_BOLETA_FACTURA,
  DOC_HOSPITALARIO,
  RECETA_OPTICA,
  RECETA_MEDICA,
  FORMULARIO_DENTAL,
  PRESUPUESTO_DENTAL,
  DOC_DIAGNOSTICO,
  DOC_ADICIONAL,
  DOC_REINGRESO,

  DOC_DETALLE_PROGRAMA,
  DOC_EPICRISIS,
  DOC_PROTOCOLO_OPERATORIO,
  DOC_DECLARACION_HECHOS,

  DOC_ATENC_ISAPRE_FONASA,
  DOC_REEMB_AMBULATORIO_ISAPRE_FONASA
}