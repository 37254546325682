import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { capitalize } from '@common/utils';
import { ReimbursementStatus } from '../reimbursement-status/reimbursement-status.enum';
import { AlertMessageComponent } from '@common/components';

@Component({
  selector: 'app-reimbursement-advertisement',
  standalone: true,
  templateUrl: './reimbursement-advertisement.component.html',
  styleUrls: ['./reimbursement-advertisement.component.scss'],
  imports: [CommonModule, AlertMessageComponent],
})
export class ReimbursementAdvertisementComponent {
  @Input() type!:
    | 'reEntry'
    | 'deductible'
    | 'ambulatory'
    | 'dental'
    | 'pharmacy';

  private reimbursementTypeMap: Record<string, string> = {
    ambulatory: capitalize(ReimbursementStatus.onlineOutpatient),
    dental: capitalize(ReimbursementStatus.dentalOnline),
    pharmacy: capitalize(ReimbursementStatus.onlinePharmacy),
  };

  advertisementLabel(): string {
    return this.reimbursementTypeMap[this.type] || '';
  }
}
