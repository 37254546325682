import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ETypeFile } from '@common/enum';
import { IPrestador, IPrestadoresService, IFile, IPrestacion, IArancelInfo } from '@common/interfaces';
import { environment as ENV } from '@environments/environment';

// TODO: separar responsabilidades

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public esOtroReembolso = false;
  public idSeleccionado: number = -1;
  public coberturaSeleccionada: number | undefined = undefined;
  public osActual = '';
  public listaIsapre: IPrestador[]=[];
  public esDesdeSBS = false;
  public correoContacto: string = ENV.CORREOS.VIDA;

  constructor(
    private http: HttpClient,
    private winRef: Window
  ) {
    this.osActual = this.obtenerSistemaOperativo();

    const esSBS: string | null = sessionStorage.getItem('esSBS');
    if (esSBS && esSBS === 'true') {
      this.esDesdeSBS = true;
      this.cardPrestaciones.forEach((prestacion) => {
        prestacion.iconoOn = prestacion.iconoOn.replace('.svg', '-sbs.svg');
      });
      this.correoContacto = ENV.CORREOS.SBS;
    }
  }

  public async ObtenerPrestadores(rut: string): Promise<void> {
    this.listaIsapre = [];
    try {
      const ssoToken: string | null = sessionStorage.getItem('ssoToken');
      if (!ssoToken) throw new Error('No existe token guardado.');

      const headers: HttpHeaders = new HttpHeaders()
        .set('x-application', ENV.CLIENT_ID);

      const prestadores: IPrestadoresService = (
        await this.http.get<IPrestadoresService>(
          `${ENV.URL_BFF_BASE}/Provider/Insured/${rut}`,
          { headers: headers }).toPromise()
      );

      prestadores.providers.forEach((provider)=>{
        const prestadoresinfo: IPrestador = {
          nombre: provider.name,
          id : provider.trassaCode
        }
        this.listaIsapre.push(prestadoresinfo);
      });
    } catch (error) {
      console.warn(error);
      this.listaIsapre = [];
    }
  }

  public filtrarCodigoPorIsapre(nombreIsapre: string): number {
    const isapre: IPrestador = this.listaIsapre.filter(isapre => isapre.nombre.toLocaleLowerCase() === nombreIsapre.toLocaleLowerCase())[0];
    return isapre.id;
  }

  public cardPrestaciones: IPrestacion[] = [
    {
      idPrestacion: 0,
      coberturaAsociada: 95,
      nombrePrestacion: 'Consulta Médica',
      iconoOff: 'assets/svgs/consulta-medica-off.svg',
      iconoOn: 'assets/svgs/consulta-medica-on.svg',
      esSeleccionada: false,
      pregunta: '¿Reembolsaste previamente en tu Isapre/Fonasa?',
      confirmar: '¿El monto total de tu copago fue mayor a UF15?',
    },
    {
      idPrestacion: 1,
      nombrePrestacion: 'Atención Hospitalaria',
      coberturaAsociada: 96,
      iconoOff: 'assets/svgs/atencion-hospitalaria-off.svg',
      iconoOn: 'assets/svgs/atencion-hospitalaria-on.svg',
      esSeleccionada: false,
      pregunta: null,
      confirmar: null,
      warning: 'Los reembolsos hospitalarios menores a 24hrs se deben ingresar como procedimiento',
    },
    {
      idPrestacion: 2,
      nombrePrestacion: 'Marcos y lentes',
      coberturaAsociada: 95,
      iconoOff: 'assets/svgs/marcos-y-lentes-off.svg',
      iconoOn: 'assets/svgs/marcos-y-lentes-on.svg',
      esSeleccionada: false,
      pregunta: null,
      confirmar: null,
    },
    {
      idPrestacion: 3,
      nombrePrestacion: 'Atención Dental',
      coberturaAsociada: 97,
      iconoOff: 'assets/svgs/atencion-dental-off.svg',
      iconoOn: 'assets/svgs/atencion-dental-on.svg',
      esSeleccionada: false,
      pregunta: null,
      confirmar: '¿Estás declarando más de una atención en tu boleta?',
    },
    {
      idPrestacion: 4,
      nombrePrestacion: 'Exámenes y procedimientos',
      coberturaAsociada: 95,
      iconoOff: 'assets/svgs/examenes-y-procedimientos-off.svg',
      iconoOn: 'assets/svgs/examenes-y-procedimientos-on.svg',
      esSeleccionada: false,
      pregunta: '¿Reembolsaste previamente en tu Isapre/Fonasa?',
      confirmar: '¿El monto total de tu copago fue mayor a UF15?',
    },
    {
      idPrestacion: 5,
      nombrePrestacion: 'Compra de Medicamentos',
      coberturaAsociada: 95,
      iconoOff: 'assets/svgs/compra-medicamentos-off.svg',
      iconoOn: 'assets/svgs/compra-medicamentos-on.svg',
      esSeleccionada: false,
      pregunta: null,
      confirmar: '¿Esta es una receta permanente?',
    },
  ];

  /* Documentos */
  /*
    Obligatorios: requerido=true
    Al menos uno: requerido=false, atLeastOne=true
    Opcional: requerido=false atLeastOne=false
  */

  public docBono: IFile = {
    idTipoArchivo: ETypeFile.DOC_BONO,
    nombre: 'Documento de bono de atención',
    prefix: 'Bono de atención',
    esRequerido: true,
    esUnico: false
  };
  public docReembAmbulatorioIsapreFonasa: IFile = {
    idTipoArchivo: ETypeFile.DOC_REEMB_AMBULATORIO_ISAPRE_FONASA,
    nombre: 'reembolso ambulatorio Isapre o Fonasa',
    prefix: 'Reembolso ambulatorio',
    esRequerido: false,
    esUnico: false,
    atLeastOne: true,
  };
  public docAtencionIsapreFonasa: IFile = {
    idTipoArchivo: ETypeFile.DOC_ATENC_ISAPRE_FONASA,
    nombre: 'otro bono atención Isapre o Fonasa',
    prefix: 'Bono de atención',
    esRequerido: false,
    esUnico: false,
    atLeastOne: true,
  };
  public docBoletaFactura: IFile = {
    idTipoArchivo: ETypeFile.DOC_BOLETA_FACTURA,
    nombre: 'Boleta / Factura',
    esRequerido: false,
    esUnico: false,
    atLeastOne: true,
  };
  public docHospitalario: IFile = {
    idTipoArchivo: ETypeFile.DOC_HOSPITALARIO,
    nombre: 'documentos hospitalarios',
    esRequerido: true,
    esUnico: false
  };
  public recetaOptica: IFile = {
    idTipoArchivo: ETypeFile.RECETA_OPTICA,
    nombre: 'Receta óptica',
    esRequerido: true,
    esUnico: false
  };
  public recetaMedica: IFile = {
    idTipoArchivo: ETypeFile.RECETA_MEDICA,
    nombre: 'Receta médica',
    esRequerido: true,
    esUnico: false
  };
  public formularioDental: IFile = {
    idTipoArchivo: ETypeFile.FORMULARIO_DENTAL,
    nombre: 'Formulario reembolso o presupuesto dental',
    prefix: 'Formulario reembolso',
    esRequerido: true,
    esUnico: false
  };
  public docDiagnostico: IFile = {
    idTipoArchivo: ETypeFile.DOC_DIAGNOSTICO,
    nombre: 'Documento de diagnóstico',
    esRequerido: true,
    esUnico: false
  };
  public docAdicional: IFile = {
    idTipoArchivo: ETypeFile.DOC_ADICIONAL,
    nombre: 'documentos adicionales',
    esRequerido: false,
    esUnico: false
  };
  public docReingreso: IFile = {
    idTipoArchivo: ETypeFile.DOC_REINGRESO,
    nombre: 'Documento',
    esRequerido: true,
    esUnico: false
  };

  public docReembolso: IFile = {
    idTipoArchivo: ETypeFile.DOC_REEMBOLSO,
    nombre: 'bonos, reembolsos (asociados a boletas de honorarios) y facturas o notas de crédito',
    prefix: 'reembolsos o facturas',
    esRequerido: true,
    esUnico: false
  };
  public docDetallePrograma: IFile = {
    idTipoArchivo: ETypeFile.DOC_DETALLE_PROGRAMA,
    nombre: 'detalle de la cuenta y programa médico',
    prefix: 'detalle de cuenta',
    esRequerido: true,
    esUnico: true
  };
  public docEpicrisis: IFile = {
    idTipoArchivo: ETypeFile.DOC_EPICRISIS,
    nombre: 'epicrisis o informe médico que indique diagnóstico',
    prefix: 'epicrisis',
    esRequerido: true,
    esUnico: true
  };
  public docProtocolo: IFile = {
    idTipoArchivo: ETypeFile.DOC_PROTOCOLO_OPERATORIO,
    nombre: 'protocolo operatorio si corresponde a intervención quirúrgica',
    prefix: 'protocolo operatorio',
    esRequerido: false,
    esUnico: true
  };
  public docDeclaracion: IFile = {
    idTipoArchivo: ETypeFile.DOC_DECLARACION_HECHOS,
    nombre: 'declaración simple de los hechos en caso de accidente',
    prefix: 'declaración',
    esRequerido: false,
    esUnico: true
  };

  public AtencionHospitalariaDoc: IFile[] = [
    this.docReembolso,
    this.docDetallePrograma,
    this.docEpicrisis,
    this.docProtocolo,
    this.docDeclaracion
  ]

  public ConsultaMedicaDoc: IFile[] = [
    this.docReembAmbulatorioIsapreFonasa,
    this.docAtencionIsapreFonasa,
    this.docBoletaFactura,
    this.docAdicional
  ]

  public MarcosLentesDoc: IFile[] = [
    this.recetaMedica,
    this.docReembAmbulatorioIsapreFonasa,
    this.docAtencionIsapreFonasa,
    this.docBoletaFactura,
    this.docAdicional
  ]

  public ExamenesProcedimientosDoc: IFile[] = [
    this.docReembAmbulatorioIsapreFonasa,
    this.docAtencionIsapreFonasa,
    this.docBoletaFactura,
    this.docAdicional
  ]

  /* Radio Button */
  public radioReembolso: RadioElement = {
    id: ETypeFile.DOC_REEMBOLSO,
    nombre: 'Reembolso ambulatorio isapre o fonasa'
  };
  public radioBono: RadioElement = {
    id: ETypeFile.DOC_BONO,
    nombre: 'Bono atención Isapre/Fonasa',
  };
  public radioBoletaFactura: RadioElement = {
    id: ETypeFile.DOC_BOLETA_FACTURA,
    nombre: 'Boleta/Factura',
  };

  public readonly codOtrosDentales = '201862019';

  public sizeWarning: string = 'Adjunta un archivo (jpg, jpeg, png, heic o pdf) que no supere los 15MB';

  public otrosDentales!: IArancelInfo;

  public marcarCardSeleccionada(prestacion: IPrestacion): void {
    this.cardPrestaciones.forEach((card) => {
      card.esSeleccionada = false;
      if (prestacion.idPrestacion === card.idPrestacion) {
        card.esSeleccionada = true;
      }
    });
  }

  public marcarCardPorId(id: number): void {
    this.cardPrestaciones.forEach((card) => {
      card.esSeleccionada = false;
      if (card.idPrestacion === id) {
        card.esSeleccionada = true;
      }
    });
  }

  public obtenerCardSeleccionada(): IPrestacion {
    return this.cardPrestaciones.filter((prestacion) => prestacion.esSeleccionada === true)[0];
  }

  public reiniciarCards(): void {
    this.cardPrestaciones.forEach((card) => {
      card.esSeleccionada = false;
    })
  }

  public obtenerSistemaOperativo(): string {
    let platform: string = this.winRef.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os: string | null = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(platform)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    } else {
      os = 'Otros';
    }

    return os;
  }
}

interface RadioElement {
  id: ETypeFile;
  nombre: string;
}
