import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService, DataUsuarioService } from '@common/services';
import { SharedModule } from '@common/shared.module';

@Component({
  selector: 'app-historical-header',
  templateUrl: './historical-header.component.html',
  styleUrls: ['./historical-header.component.scss'],
  standalone: true,
  imports: [ SharedModule ],
})
export class HistoricalHeaderComponent implements OnInit {

  @Input() requestAllowed = false;
  @Output() checkTermsAndConditions = new EventEmitter<boolean>();

  public image: string = '';

  private dataUsuario: DataUsuarioService = inject(DataUsuarioService);
  private router = inject(Router);
  private storage = inject( StorageService);

  ngOnInit() {
    this.image = this.storage.esDesdeSBS ? 'assets/svgs/reimbursement-request-sbs.svg' : 'assets/svgs/reimbursement-request.svg';
  }

  public makeRequest(): void {
    if (!this.requestAllowed) return;
    if (this.dataUsuario.usuarioConectado.esTerminosAceptados) {
      this.router.navigate(['/reembolso']);
    } else {
      this.checkTermsAndConditions.emit();
    }
  }
}
