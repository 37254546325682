import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss'],
  standalone: true,
})
export class WarningMessageComponent {
  @Input() message: string =
    'Te recomendamos que realices primero tu reembolso en tu Isapre o Fonasa, ya que si no lo haces, el monto de reembolso puede ser menor';
}
