import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { Token, IRefresh } from '@common/interfaces';
import { formatearRut } from '@common/utils/';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public esDesdeSitioPrivado = false;

  constructor(private httpClient: HttpClient) { }

  public getToken(username : string , password : string): Observable<Token> {
    const url = `${environment.URL_BFF_BASE}/BFF/Auth/Token`;
    const rut: string = formatearRut(username)
    const body: { username: string, password: string } = {
      username: rut.split('.').join(''),
      password: password,
    }
    const headers: HttpHeaders = new HttpHeaders()
      .set('x-application', this.esDesdeSitioPrivado ? environment.PRIVADO_CLIEN_ID : environment.CLIENT_ID)
      .set('x-ibm-client-id', environment.X_IBM_CLIENT_ID);

    return this.httpClient.post<Token>(url, body, { headers });
  }

  public async refreshToken(): Promise<boolean> {
    const url = `${environment.URL_BFF_BASE}/BFF/Reimbursement/RefreshToken`;
    const refreshToken: string | null = sessionStorage.getItem('refresh_token');
    const headers: HttpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-ibm-client-id', environment.X_IBM_CLIENT_ID)
      .set('x-application', this.esDesdeSitioPrivado ? environment.PRIVADO_CLIEN_ID : environment.CLIENT_ID);
    const body: { refreshToken: string | null } = {refreshToken: refreshToken};

    try {
      const response: IRefresh = await this.httpClient.post<IRefresh>(url, body, { headers: headers }).toPromise();
      if (response.httpCode === 200 && response.token) {
        sessionStorage.setItem('ssoToken', response.token.accessToken);
        sessionStorage.setItem('refresh_token', response.token.refreshToken);
        return true;
      } else { return false; }
    } catch (error) {
      console.warn(error);
      return false;
    }
  }
}
