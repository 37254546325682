<div class="calendario" [formGroup]="form">
  <input [id]="nameControl" type="text" style="display: none;">
  <ds-datepicker
    id="datepicker"
    type="single"
    [initial-label]="labelInput"
    id="fecha_on_generales"
  >
  </ds-datepicker>
  <img *ngIf="esValido" class="calendario__icono" src="assets/svgs/{{icono}}.svg">
</div>
