import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReimbursementStatusComponent } from '../reimbursement-status/reimbursement-status.component';
import { ReimbursementDownloadComponent } from '../reimbursement-download/reimbursement-download.component';
import { Historical } from '@core/models';

@Component({
  selector: 'app-reimbursement-observations',
  templateUrl: './reimbursement-observations.component.html',
  styleUrls: ['./reimbursement-observations.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReimbursementStatusComponent,
    ReimbursementDownloadComponent,
  ],
})
export class ReimbursementObservationsComponent {
  @Input() claim!: Historical;
}
