<section class="advertisement__container" *ngIf="type">
  <app-alert-message>
    <p *ngIf="type === 'reEntry'" message>
      Esta solicitud de reembolso ya ha sido reingresada anteriormente.
    </p>

    <p *ngIf="type === 'deductible'" message>
      *El <strong>deducible</strong> es la cantidad que debes pagar antes de
      que el seguro comience a cubrir los gastos médicos, según los términos
      del contrato de seguro de salud.
    </p>

    <p message
      *ngIf="
      type === 'ambulatory' || type === 'dental' || type === 'pharmacy'
      "
    >
      <strong>{{ advertisementLabel() }}</strong> es un servicio electrónico de pago
      que se gestiona al momento de la emisión del bono o boleta.
    </p>
  </app-alert-message>
</section>
