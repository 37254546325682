<div class="modal-container" [formGroup]="formArancel">
    <div class="modal-wrapper">
      <div class="contenedor-icono">
        <img (click)="cerrarModal()" class="icono-cerrar" src="assets/svgs/cerrar.svg">
      </div>
      <div class="titulo-modal-detalle">
        <span>
          Ingresa el detalle de la prestación
        </span>
      </div>

      <div class="modal--form__container">
        <div class="modal--form">
          <div class="modal--row">
            <vs-input-list [form]="formArancel" nameControl="arancel"></vs-input-list>
            <vs-input *ngIf="esRequiereSesiones" [form]="formArancel" nameControl="sesiones"></vs-input>
          </div>
          <div class="modal--row">
            <vs-input [form]="formArancel" nameControl="valor" (focusout)="verificarHistorico()" ></vs-input>
            <vs-input *ngIf="!esDocumentoBoletaFactura" [form]="formArancel" nameControl="bonificacion"></vs-input>
          </div>
        </div>

        <!--Monto a rembolsar-->
        <div class="form--total__container" [ngClass]="{'disabled': montoSolicitado === 0}">
          <span slot="text">Monto solicitado</span>
          <span slot="text">{{ formatearValor(montoSolicitado) }}</span>
        </div>

      </div>

      <!-- Mensaje de error -->
      <div class="mensaje" *ngIf="esDesviado">
        <img class="mensaje__icono" src="assets/svgs/advertencia.svg">
        <label class="mensaje__advertencia">
          El <strong>valor que ingresaste es diferente</strong> a los registrados que has ingresado anteriormente:
          <strong>{{ formatearValor(montoHistorico) }} por sesión.</strong><br>Recuerda que debes indicar la cantidad de sesiones que equivalen al valor que estás ingresando.
        </label>
      </div>
  
      <!-- botones-->
      <section class="reembolso__botones--container">
        <vs-button
        class="boton-cancelar"
        texto="Cancelar"
        type="secondary"
        size="small"
        (click)="cerrarModal()"
      ></vs-button>
      <vs-button
        class="boton-guardar"
        texto="Guardar"
        type="primary"
        size="medium"
        [disabled]="!verificarPuedeContinuar()"
        (click)="guardarArancel()"
      ></vs-button>
      </section>
    </div>
  </div>