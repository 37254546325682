import { Component, Input } from '@angular/core';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() texto = '¿Quién se atendió?';
  @Input() porcentajeProgeso: number = -1;

  constructor() { }
}
