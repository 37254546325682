<div class="historical__wrapper" *ngIf="canMakeRequests">
  <app-historical-header
    [requestAllowed]="canMakeRequests"
    (checkTermsAndConditions)="showTermsAndConditionsModal()"
  ></app-historical-header>

  <h2 class="historical__title">Historial de reembolsos</h2>

  <app-historical-table (onLoadData)="onLoadData()"></app-historical-table>
</div>

<app-terms-and-conditions-modal *ngIf="showModal" (eventRespuesta)="hideTermsAndConditionsModal($event)"></app-terms-and-conditions-modal>

<app-message-box
  *ngIf="!canMakeRequests"
  [title]="warningMessage.title"
  [message]="warningMessage.message"
  [borderClass]="false"
></app-message-box>