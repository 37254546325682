import { ModalService } from './../../services/modal.service';
import { ReingresoService } from './../../services/reingreso.service';
import { UntypedFormGroup } from '@angular/forms';
import { ETypeError } from './../../enum/ETypeError';
import { ETypeOrigin } from './../../enum/ETypeOrigin';
import { ETypeExtension } from './../../enum/ETypeExtension';
import { IFileUpload } from './../../interfaces/IFile';
import { interval, Subscription } from 'rxjs';
import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ETypeFile } from '../../enum/ETypeFile';
import { IFile } from '../../interfaces/IFile';
import { capitalize, crearID } from '../../utils/utils';
import { take } from 'rxjs/operators';
import { PDFDocument, PDFForm } from 'pdf-lib';
import * as pdfjsLib from 'pdfjs-dist';
import { TAMANO_MAXIMO_KB } from '@common/utils/constants/file';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit, OnDestroy {
  @Input() tipoArchivo: IFile = {
    idTipoArchivo: ETypeFile.DOC_REEMBOLSO,
    nombre: 'Documento de reembolso',
    esRequerido: true,
    esUnico: true,
  }
  @Input() form!: UntypedFormGroup;
  @Output() errorFound = new EventEmitter<ETypeError>();

  private _archivos$!: Subscription;
  public listadoArchivos: IFileUpload[] = [];
  public esMostrarError = false;
  public mensajeError: ETypeError = ETypeError.ERROR_EXTENSION;
  public esAbrirPreview = false;
  public archivoSelected!: IFileUpload | null;
  public showFileSizeErrorModal: boolean = false;
  public blobAdded: boolean = false;

  constructor(public reembolso: ReembolsoActualService, public modalService: ModalService, public reingreso: ReingresoService) { }

  ngOnInit(): void {
    this.checkReimbursment();
  }

  ngOnDestroy(): void {
    this._archivos$.unsubscribe();
    this.reembolso.vaciarArchivosPorTipo(this.tipoArchivo.idTipoArchivo);
  }

  checkReimbursment(): void {
    if (this.reingreso.esReingreso) {
      this._archivos$ = this.reingreso.archivosReingreso$.subscribe((archivos) => {
        this.listadoArchivos = [];
        const listado: IFileUpload[] = archivos.filter((archivo) => archivo.idTipoArchivo === this.tipoArchivo.idTipoArchivo);
        this.listadoArchivos = listado;
      });
    } else {
      this._archivos$ = this.reembolso.archivosSubidos$.subscribe((archivos) => {
        this.listadoArchivos = [];
        this.blobAdded = false;
        const listado: IFileUpload[] = archivos.filter((archivo) => {
          if (archivo.idTipoArchivo === this.tipoArchivo.idTipoArchivo) {
            this.blobAdded = true;
            this.archivoSelected = archivo;
          }
          return archivo.idTipoArchivo === this.tipoArchivo.idTipoArchivo;
        });
        this.listadoArchivos = listado;
      });

      const listado: IFileUpload[] = this.reembolso.obtenerArchivosActuales().filter((archivo) => archivo.idTipoArchivo === this.tipoArchivo.idTipoArchivo);
      this.listadoArchivos = listado;
      if (this.tipoArchivo.idTipoArchivo === ETypeFile.DOC_DIAGNOSTICO) {
        interval(200).pipe(take(1),).subscribe(_ =>
          this.form.get('documentosSubidos')?.setValue(false)
        )
      }
    }
  }

  abrirCargaArchivos(): void {
      this.esMostrarError = false;
      const input: HTMLElement | null = document.getElementById(`input__${this.tipoArchivo.idTipoArchivo}`);
      input?.click();
  }

  public async enSubidaDeArchivos(event: any): Promise<void> {
    // Condicional para archivos únicos que necesitan advertir al usuario del cambio.
    if (this.tipoArchivo.esUnico && this.reembolso.verificarArchivoUnicoPorTipo(this.tipoArchivo.idTipoArchivo)) {
      this.modalService.modalAdvertencia = true;
      setTimeout(() => {
        const botonPromise = new Promise((resolve) => {
          const botonSi: HTMLElement | null = document.getElementById("primaryActionButton");
          const botonNo: HTMLElement | null = document.getElementById("secondaryActionButton");
          const resolver: () => void = () => {
            resolve(true);
            botonSi!.removeEventListener("click", resolver);
            botonNo!.removeEventListener("click", resolver);
          }
          botonSi!.addEventListener("click", resolver);
          botonNo!.addEventListener("click", resolver);
        });

        botonPromise.then(() => {
          if (this.modalService.esPermiteSubida) {
            this.reembolso.eliminarArchivo(this.listadoArchivos[0].idArchivoSubido);
            this.procesarArchivos(event);
          } else {
            return;
          }
        });
      }, 500);
    } else {
      this.procesarArchivos(event);
    }
  }

  public eliminarArchivo(idArchivo: string): void {
    if (this.reingreso.esReingreso) {
      this.reingreso.eliminarArchivo(idArchivo);
    } else {
      this.reembolso.eliminarArchivo(idArchivo);
    }
    this.archivoSelected = null;
  }

  public togglePreview(esAbrir: boolean, archivo?: IFileUpload): void {
    this.esAbrirPreview = esAbrir;
    if (archivo && esAbrir) {
      this.modalService.archivoPreview = archivo;
      this.modalService.modalPreview = true;
    }
  }

  public toggleError(esError: boolean): void {
    this.esMostrarError = esError;
  }

  private procesarArchivos(event: any): void {
    [...event.files].forEach(async (archivo: File) => {
      let extension: string;
      if (archivo.type === '') {
        extension = archivo.name.split('.').slice(-1)[0]?.toLowerCase();
      } else {
        extension = archivo.type.split('/')[1].toLowerCase();
      }

      const esPesoPermitido: boolean = this.verificarPesoArchivo(archivo.size);
      const esExtensionPermitida: boolean = this.verificarExtension(extension);
      const esPdfConPassword: boolean = await this.verificarPdfPassword(archivo);
      if (esPesoPermitido && esExtensionPermitida && !esPdfConPassword) {
        const archivoSubido: IFileUpload | null = await this.crearArchivo(archivo, extension as ETypeExtension);
        if (archivoSubido) {
          if (this.reingreso.esReingreso) {
            this.reingreso.agregarArchivo(archivoSubido);
          } else {
            this.reembolso.agregarNuevoArchivo(archivoSubido);
          }
        }
      } else {
        if (!esPesoPermitido) {
          this.errorFound.emit(ETypeError.ERROR_MB);
        } else if (!esExtensionPermitida) {
          this.mensajeError = ETypeError.ERROR_EXTENSION;
        } else if (esPdfConPassword) {
          this.mensajeError = ETypeError.ERROR_PDF_PASSWORD;
        }
      }
    });
    const input = document.getElementById(`input__${this.tipoArchivo.idTipoArchivo}`) as HTMLInputElement;
    input.value = '';
  }

  private async crearArchivo(archivo: File, extension: ETypeExtension): Promise<IFileUpload | null> {
    try {
      const archivoSubido: IFileUpload = {
        idArchivoSubido: crearID(),
        nombreArchivo: archivo.name.slice(0, archivo.name.lastIndexOf('.')),
        extension: extension,
        origen: this.procesarOrigen(archivo.type),
        base64: (await this.transformarABase64(archivo)).split(',')[1],
        esRequerido: this.tipoArchivo.esRequerido,
        esUnico: this.tipoArchivo.esUnico,
        idTipoArchivo: this.tipoArchivo.idTipoArchivo,
        nombre: this.tipoArchivo.nombre,
        pesoKB: archivo.size / 1024,
        prefix: this.tipoArchivo.prefix,
      }
      return archivoSubido;
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  private transformarABase64(archivoBlob: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(archivoBlob);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  private procesarOrigen(extension: string): ETypeOrigin {
    return extension.split('/')[1] === 'pdf' ? ETypeOrigin.PDF : ETypeOrigin.GALERIA;
  }

  private verificarPesoArchivo(tamano: number): boolean {
    return tamano < TAMANO_MAXIMO_KB;
  }

  private verificarExtension(extension: string): boolean {
    switch (extension) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'pdf':
      case 'heic':
        return true;
      default:
        return false;
    }
  }

  private async verificarPdfPassword(pdf: File): Promise<boolean> {
    if (pdf.type !== 'application/pdf') return false;

    try {
      const arrayBuffer: ArrayBuffer = await this.readAsArrayBuffer(pdf);

      // Import worker
      pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

      const nuevopdf: pdfjsLib.PDFDocumentLoadingTask = pdfjsLib.getDocument(new Uint8Array(arrayBuffer));
      // Se intenta leer el archivo, si no se puede, es que esta protegido por contraeña y caerá al catch
      const pdfDocument: pdfjsLib.PDFDocumentProxy = await nuevopdf.promise;

      return false && pdfDocument;
    } catch (error) {
      console.error("Error leyendo pdf:", error);

      // Se debe retornar falso debido a que hay error
      //return new Promise<boolean>((resolve, reject) => {
      //   resolve(true);
      //  });
      return true;

    }
  }

  private async readAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  }

  public closeSizeErrorModal(): void {
    this.showFileSizeErrorModal = false;
  }

  public capitalize(cadena: string): string {
    return capitalize(cadena);
  }

  trackByArchivo(_: number, archivo: IFileUpload): string {
    return archivo.idArchivoSubido;
  }
}