<button
  class="boton"
  [ngClass]="{
    'boton--full': size === 'full',
    'boton--medium': size === 'medium',
    'boton--small': size === 'small',
    'boton--primary': type === 'primary' && disabled === false,
    'boton--secondary': type === 'secondary',
    'boton--disabled': disabled === true
  }"
>
  {{ texto }}
</button>
