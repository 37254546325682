import { Injectable } from '@angular/core';
import { IFileUpload } from '@common/interfaces';

@Injectable({ providedIn: 'root' })
export class ModalService {
  public modalPreview = false;
  public archivoPreview!: IFileUpload;
  public modalDetalle = false;
  public modalAdvertencia = false;
  public esPermiteSubida = true;

  constructor() { }
}