<div id="scrollTop"></div>
<app-tabs-in-curse-historical
  (checkInCurse)="onChangeTab($event)"
></app-tabs-in-curse-historical>

<ng-container *ngIf="reimbursements.length > 0">
  <!-- tabla desktop -->
  <table class="table__container">
    <tr class="table__header table__row--grid">
      <th slot="element">Fecha de solicitud</th>
      <th slot="element">N° Solicitud</th>
      <th slot="element">Nombre de beneficiario</th>
      <th slot="element" class="hide-tablet">Monto solicitado</th>
      <th slot="element">Monto reembolsado</th>
      <th slot="element">Estado</th>
    </tr>

    <tr
      *ngFor="
        let claim of reimbursements;
        let i = index;
        trackBy: trackByClaim
      "
      class="table__row table__row--grid"
      (click)="showReimbursementDetail(claim, i, 'tr')"
      [id]="'tr-'+ i"
      >
      <td slot="element">
        {{ claim.issueDate | date : "dd/MM/yyyy" }}
      </td>

      <td slot="element">
        {{ claim.requestNumber }}
      </td>

      <td slot="element">
        {{ claim.beneficiaryName | titlecase }} /
        {{ claim.beneficiaryRelationship | titlecase }}
      </td>

      <td class="hide-tablet" slot="element">
        {{ formatValue(claim.claimedTotalAmount) }}
      </td>

      <td slot="element">
        {{ formatValue(claim.paidTotalAmount) }}
      </td>

      <td class="table-status__container">
        <app-reimbursement-status
          [status]="claim.claimStatus"
        ></app-reimbursement-status>
        <img src="assets/svgs/add.svg" alt="Ver detalle" />
      </td>
    </tr>
  </table>

  <!-- tabla mobile -->
  <div class="card__container">
    <div
      *ngFor="
        let claim of reimbursements;
        let i = index;
        trackBy: trackByClaim
      "
        class="card__items"
        (click)="showReimbursementDetail(claim, i, 'div')"
        [id]="'div-' + i"
      >
        <div class="card-status__container">
          <span>
            Fecha de solicitud<br />{{ claim.issueDate | date : "dd/MM/yyyy" }}
          </span>
          <app-reimbursement-status
            [status]="claim.claimStatus"
          ></app-reimbursement-status>
        </div>

        <div class="card__data">
          <span class="card__label">Beneficiario</span>
          <span class="card__value">
            {{ claim.beneficiaryName | titlecase }} /
            {{ claim.beneficiaryRelationship | titlecase }}
          </span>
        </div>

        <div class="card__data">
          <span class="card__label">Monto solicitado</span>
          <span slot="element" class="card__value">
            {{ formatValue(claim.claimedTotalAmount) }}
          </span>
        </div>

        <p class="card__link">Ver detalles</p>
    </div>
  </div>

  <!-- Paginacion -->
  <div class="pagination__container" *ngIf="reimbursements.length">
    <ds-pagination
      [initialPage]="pageSelected"
      [totalPages]="totalPages"
      (onchange)="onChangePage($event)"
    ></ds-pagination>
    <ds-pagecounter
      [initialLabel]="pageCounterSelected.toString()"
      [source]="sourcePageCounter"
      (onchange)="onChangeRecordsPerPage($event)"
    ></ds-pagecounter>
  </div>
</ng-container>

<!-- Error -->
<app-historical-empty-message
  *ngIf="!isLoading && (reimbursements.length === 0 || isServiceError)"
  [isInCurse]="isInCurse"
  [isServiceError]="isServiceError"
  message="{{ getMessageError() }}"
  title="{{ getMessageErrorTitle() }}"
></app-historical-empty-message>

<!-- Detalle -->
<app-reimbursement-detail
  *ngIf="modalService.showModal"
  [claim]="reimbursementSelected"
  (close)="hideReimbursementDetail()"
></app-reimbursement-detail>
