import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  IDependencyTelemetry,
} from '@microsoft/applicationinsights-web';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '@environments/environment';
import { DataUsuarioService } from '@common/services';
import { formatearRut } from '@common/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private appInsights!: ApplicationInsights;
  private dropOuts = 0;
  private flowCompleted = 0;
  private flowId: string;

  constructor(
    private router: Router,
    private dataUsuario: DataUsuarioService,
  ) {
    this.initializeInsights();
    this.flowId = uuidv4();
  }

  private initializeInsights(): void {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.APPINSIGHTS_CONNECTIONSTRING,
        disableAjaxTracking: true,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: {
            errorServices: [new ErrorHandler()],
          },
        }
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((envelope) => {
      envelope.data!['platform'] = 'WEB';
    });
  }

  private generateContext(customProperties?: any): any {
    let context: IContext = {
      rut: formatearRut(this.dataUsuario.usuarioConectado.rutCuerpoDv),
      date: new Date().toLocaleString(),
      platform: window.navigator.platform,
      flowId: this.flowId
    }
    if (!customProperties ||!customProperties.hasOwnProperty('page')) {
      return { ...context, page: this.router.url, };
    } else {
      return context;
    }
  }
  public trackEvent(nameEvent: string, customProperties?: any): void {
    this.appInsights.trackEvent(
      { name: nameEvent },
      { ...customProperties }
    );
  }

  public trackFlow(nameFlow: string, customProperties?: any): void {
    customProperties = { ...customProperties, ...this.generateContext(customProperties) };
    customProperties.flowClompleted = this.flowCompleted;
    customProperties.dropOuts = this.dropOuts;
    const nameEvent = 'Flujo';
    if (nameFlow === 'Flujo completado') {
      this.flowCompleted++;
      customProperties.flowClompleted += 1;
    }
    if (nameFlow === 'Abandono') {
      this.dropOuts++;
      customProperties.dropOuts += 1;
    }
    customProperties.flow = this.dropOuts / this.flowCompleted;
    this.appInsights.trackEvent(
      { name: nameEvent },
      { accion: nameFlow, ...customProperties },
    );
  }

  public logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel }, { flowId: this.flowId });
  }

  public trackDependency(dependency: IDependencyTelemetry, response: string, customProperties?: any): void {
    this.appInsights.trackDependencyData(
      {
        ...dependency,
        properties:
        {
          flowId: this.flowId,
          response,
          ...customProperties
        }
      });
  }

  public trackPageView(namePage: string): void {
    this.appInsights.trackPageView(
      { name: namePage, properties: { flowId: this.flowId } }
    );
  }

  public trackActiveUser(): void {
    const ultimaFechaActivo: string | null = localStorage.getItem('ultimaFechaActivo');
    const fechaActual = new Date();
    if (ultimaFechaActivo !== null) {
      const fechaRegistrada = new Date(ultimaFechaActivo);
      if ((fechaActual.getMonth() !== fechaRegistrada.getMonth())) {
        this.trackEvent('usuarioActivo', this.generateContext());
        localStorage.setItem('ultimaFechaActivo', (new Date()).toUTCString());
      }
    } else {
      this.trackEvent('usuarioActivo', this.generateContext());
      localStorage.setItem('ultimaFechaActivo', (new Date()).toUTCString());
    }
  }
  public trackSatisfaction(satisfaction: number): void {
    const nameEvent = 'Survey of satisfaction';
    this.appInsights.trackEvent(
      { name: nameEvent },
      {
        ...this.generateContext()
        , satisfaction: satisfaction
      },
    );
  }
  public setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie = false): void {
    this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
  }
}

export class ErrorHandlerService extends ErrorHandler {

  constructor(private appInsightsService: AppInsightsService) {
    super();
  }

  handleError(error: Error): void {
    console.log(error);
    this.appInsightsService.logException(error);
  }
}

interface IContext {
  rut: string;
  date: string;
  platform: string;
  flowId: string;
}