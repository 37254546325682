export enum ReimbursementStatus {
  paid = 'pagado',
  partiallyPaid = 'pagado parcial',
  approved = 'aprobada',
  partiallyApproved = 'aprobado parcial',
  imedTransaction = 'transacción imed',
  pharmacyTransaction = 'transacción farmacia',
  dentalTransaction = 'transacción dental',
  ambulatoryTransaction = 'transacción ambulatorio',
  noPayment = 'sin pago',
  rejected = 'rechazada',
  underEvaluation = 'en evaluación',
  entered = 'ingresado',
  new = 'nuevo',
  withPayment = 'con pago',
  withPartialPayment = 'con pago parcial',
  onlineOutpatient = 'ambulatorio en línea',
  dentalOnline = 'dental en línea',
  onlinePharmacy = 'farmacia en línea',
}
