import { ETypeError } from './../../enum/ETypeError';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vs-error',
  templateUrl: './vs-error.component.html',
  styleUrls: ['./vs-error.component.scss']
})
export class VsErrorComponent {
  @Input() mensajeError: ETypeError = ETypeError.ERROR_MB;
  @Input() esMostrarError = false;
  @Output() eventError = new EventEmitter<boolean>();

  toggleError(): void {
    this.esMostrarError = false;
    this.eventError.emit(false);
  }
}
