import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import "@vs-design-system/ds-switch";
import { TerminosService } from '@common/services/terminos.service';

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrls: ['./terms-and-conditions-modal.component.scss']
})
export class TermsandConditionsModalComponent {
  @Output() eventRespuesta = new EventEmitter<boolean>();
  public terminosAceptados = false;
  public terminos = "";
  public showError = false;

  constructor(
    private router: Router,
    private terminosService: TerminosService,
  ) {
    this.terminos = this.terminosService.terminos;
  }

  public async respuesta(button: boolean): Promise<void> {
    if (button && this.terminosAceptados) {
      this.showError = false;
      const response: boolean = await this.terminosService.postTerms();
      if (response) {
        this.eventRespuesta.emit(button);
        this.router.navigate(['/reembolso']);
      } else {
        this.showError = true;
      }
    }
    this.eventRespuesta.emit(button);
  }

  public cambioRespuesta(event: any): void {
    this.terminosAceptados = event.detail as boolean;
  }
}
