<app-modal
  title="Detalle del reembolso"
  primaryActionBtn="Adjuntar documentos"
  [primaryActionEnabled]="claim.allowedReEntry"
  (primaryAction)="attachDocuments()"
  (close)="closeDetailModal()"
>
  <ng-container body>
    <app-reimbursement-info
      [claim]="claim"
    ></app-reimbursement-info>

    <app-reimbursement-advertisement
      *ngFor="let advertisementType of displayedAdvertisementTypes"
      [type]="advertisementType"
    ></app-reimbursement-advertisement>

    <app-reimbursement-observations
      *ngIf="!claim.underEvaluation && (claim.liquidationObservations.length || claim.additionalObservation)"
      [claim]="claim"
    ></app-reimbursement-observations>

    <app-reimbursement-download
      *ngIf="canDownLoad"
      [requestNumber]="claim.requestNumber"
      [showDownLoadLiq]="!claim.underEvaluation"
    ></app-reimbursement-download>
  </ng-container>
</app-modal>
