import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-alert-message',
  standalone: true,
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
  imports: [CommonModule],
})
export class AlertMessageComponent {
  @Input() message: string = '';
  @Input() icon: string = 'assets/svgs/info.svg';
}
