import { UntypedFormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { SharedModule } from '@common/shared.module';

@Component({
  selector: 'paso-beneficiario',
  templateUrl: './paso-beneficiario.component.html',
  styleUrls: ['./paso-beneficiario.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class PasoBeneficiarioComponent {
  @Input() form!: UntypedFormGroup;
  @Input() porcentaje = 20;
}
