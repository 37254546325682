
import { Component, Output, EventEmitter } from '@angular/core';

import { capitalize } from '@common/utils';
import { ModalService } from '@common/services/modal.service';

@Component({
  selector: 'vs-preview',
  templateUrl: './vs-preview.component.html',
  styleUrls: ['./vs-preview.component.scss']
})
export class VsPreviewComponent {
  @Output() eventPreview = new EventEmitter<boolean>();

  constructor(public modalService: ModalService) {
  }

  public cerrarPreview(): void {
    this.modalService.modalPreview = false;
    this.eventPreview.emit(false);
  }

  public capitalize(cadena: string): string {
    return capitalize(cadena);
  }
}
