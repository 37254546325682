<div class="table__container table-desktop">
  <div slot="header" class="table--header">
    <span slot="element" class="text-left size-350px">Nombre Prestacion</span>
    <span slot="element" class="text-right size-130px">N° de sesiones</span>
    <span slot="element" class="text-right size-130px">Valor prestación</span>
    <span slot="element" class="text-right size-130px">Bonificación Isapre/Fonasa</span>
    <span slot="element" class="text-right size-130px">Monto solicitado</span>
    <span class="size-130px"></span>
  </div>

  <div class="table-content">
    <ng-container *ngFor="let prestacion of prestaciones; trackBy: trackByArancel">
      <div class="table--row">
        <span slot="element" class="text-left size-350px">
          {{ prestacion.nombre }}
        </span>
        <span slot="element" class="text-right size-130px">
          {{ prestacion.sesiones !== 0 ? prestacion.sesiones : ''}}
        </span>
        <span slot="element" class="text-right size-130px">
          {{ formatearValor(prestacion.montoTotal) }}
        </span>
        <span slot="element" class="text-right size-130px">
          {{ formatearValor(prestacion.descuento) }}
        </span>
        <span slot="element" class="text-right size-130px">
          {{ formatearValor(prestacion.montoTotal - prestacion.descuento) }}
        </span>
        <span slot="element" class="size-130px text-right">
          <div (click)="eliminarArancel(prestacion)" class="size-50px">
            <img class="icono-eliminar" src="assets/svgs/trash-eliminar.svg">
          </div>
        </span>
      </div>
    </ng-container>
  </div>

  <div class="table--totalizers">
    <div class="subtotal" *ngIf="prestaciones.length > 1">
      <h6>Valor total prestaciones</h6>
      <h6>{{ formatearValor(totalPrestaciones) }}</h6>
    </div>
    <div class="subtotal" *ngIf="prestaciones.length > 1">
      <h6>Bonificación total Isapre/ Fonasa</h6>
      <h6>{{ formatearValor(totalPrestaciones - montoReembolsar) }}</h6>
    </div>
    <div class="total">
      <h6>Monto total solicitado</h6>
      <h6>{{ formatearValor(montoReembolsar) }}</h6>
    </div>
  </div>
</div>

<!-- tabla mobile -->
<div class="table-mobile table-mobile--container">
  <ng-container *ngFor="let prestacion of prestaciones; let i = index; trackBy: trackByArancel">
    <div class="table-mobile--items">
      <input type="checkbox" [id]="'collapsible-trigger' + i" />
      <div class="titulo">
        <label [for]="'collapsible-trigger' + i">
          <h5>{{ prestacion.nombre }}</h5>
          <div class="toggle-container">
            <img src="assets/svgs/dropdown-table.svg">
          </div>
        </label>
      </div>
      <div class="collapsable-content">
        <div class="content-group hidden">
          <div class="content">
            <h6 class="subtitulo">N° de sesiones</h6>
            <p class="info">{{ prestacion.sesiones }}</p>
          </div>
          <div class="content">
            <h6 class="subtitulo">Valor prestación</h6>
            <p class="info">{{ formatearValor(prestacion.montoTotal) }}</p>
          </div>
          <div class="content">
            <h6 class="subtitulo">Bonificación Isapre/Fonasa</h6>
            <p class="info">{{ formatearValor(prestacion.descuento) }}</p>
          </div>
        </div>
        <div class="content">
          <h6 class="subtitulo">Monto solicitado</h6>
          <p class="info">
            {{ formatearValor(prestacion.montoTotal - prestacion.descuento) }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="table-mobile--totalizers">
    <div class="subtotal" *ngIf="prestaciones.length > 1">
      <h6>Total prestaciones</h6>
      <h6>{{ formatearValor(totalPrestaciones) }}</h6>
    </div>
    <div class="subtotal" *ngIf="prestaciones.length > 1">
      <h6>Total bonificacion</h6>
      <h6>{{ formatearValor(totalPrestaciones - montoReembolsar) }}</h6>
    </div>
    <div class="total">
      <h6>Monto solicitado</h6>
      <h6>{{ formatearValor(montoReembolsar) }}</h6>
    </div>
  </div>
</div>
