<div class="contenedor">
  <div class="archivo">
    <label class="archivo__input">
      <input
        [id]="'input__' + tipoArchivo.idTipoArchivo"
        class="input"
        [multiple]="!tipoArchivo.esUnico"
        type="file"
        accept=".jpg,.jpeg,.png,.pdf,.heic"
        (change)="enSubidaDeArchivos($event.target)"
      />
    </label>
  </div>
  <div class="adjuntos">
    <div class="adjuntos__informacion" (click)="abrirCargaArchivos()">
      <img class="icono" src="assets/svgs/upload.svg">
      <label class="adjuntos__nombre selectable">Subir {{ tipoArchivo.nombre }}</label>
    </div>

    <div *ngFor="let archivo of listadoArchivos; trackBy: trackByArchivo" class="adjuntos__informacion blobAdded">
      <img class="icono" [class.check]="blobAdded" src="assets/svgs/check.svg"/>
      <div>
        <div class="adjuntos__blobAdded selectable" (click)="togglePreview(true, archivo)" >
          <p class="docname">{{ capitalize(tipoArchivo.prefix || tipoArchivo.nombre) }}</p>
          <p class="filename">{{ archivo.nombreArchivo }}.{{ archivo.extension }}</p>
        </div>
      </div>
      <img class="icono" src="assets/svgs/eye-preview.svg" *ngIf="blobAdded" (click)="togglePreview(true, archivo)" />
      <img class="icono" src="assets/svgs/trash-eliminar.svg" *ngIf="blobAdded" (click)="eliminarArchivo(archivo.idArchivoSubido)"/>
    </div>

  </div>
</div>