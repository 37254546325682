<div class="documentos" [formGroup]="form">
  <stepper [porcentajeProgeso]="porcentaje" [id]="3"></stepper>
  <div class="documentos__contenido" [ngStyle]="{
    'gap': this.form.get('prestacion')?.value === 1 ? '10px' : '0px 20px'
  }">
    <header id="uploadFiles" [porcentajeProgeso]="porcentaje" texto="Sube tus documentos"></header>
    <ng-container *ngIf="porcentaje >= 0">

      <div class="warning" *ngIf="this.form.get('prestacion')?.value !== 1">
        <app-alert-message [message]="storage.sizeWarning"></app-alert-message>
      </div>

      <div *ngIf="isMandatory().length" class="mandatory_type">
        <div class="doc-hospitalarios">
          <span class="descripcion">
            Para continuar necesitas subir los <strong>siguientes documentos</strong>
          </span>

          <div *ngIf="this.form.get('prestacion')?.value === 1">
            <p class="documentos">
              ▪ Bonos, reembolsos (asociados a boletas de honorarios), facturas o notas de crédito<br>
              ▪ Detalle de la cuenta y programa médico<br>
              ▪ Epicrisis o informe médico que indique diagnóstico<br>
              ▪ Protocolo operatorio si corresponde intervención quirúrgica (Opcional)<br>
              ▪ Declaración simple de los hechos en caso de accidente (Opcional)
            </p>
            <h3>¿Cómo prefieres subir los documentos?</h3>
            <div id="selectUpload" class="select-upload">
              <div (click)="selectUploadHospitalCare(true)" class="select-upload__options" [class.active]="separatedUpload">
                <span class="title">Subir por separado</span>
                <p>Tengo los documentos en archivos distintos</p>
              </div>
              <div (click)="selectUploadHospitalCare(false)" class="select-upload__options" [class.active]="!separatedUpload">
                <span class="title">Subir todo junto</span>
                <p>Tengo los documentos en un solo archivo</p>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngFor="let documento of isMandatory(); trackBy: trackByDocument">
          <div class="documentos__files transicion-de-despliegue">
            <file-uploader [tipoArchivo]="documento" [form]="form" (errorFound)="showFileError($event)"></file-uploader>
          </div>
        </ng-container>
      </div>

      <div *ngIf="atLeastOne().length" class="mandatory_type">
        <div class="doc-hospitalarios">
          <span class="descripcion">
            Por favor sube <strong>al menos uno</strong> de estos documentos
          </span>
          <ng-container *ngFor="let documento of atLeastOne(); trackBy: trackByDocument">
            <div class="documentos__files transicion-de-despliegue">
              <file-uploader [tipoArchivo]="documento" [form]="form" (errorFound)="showFileError($event)"></file-uploader>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="isOptional().length" class="mandatory_type">
        <div class="doc-hospitalarios">
          <span class="descripcion">
            Documentos opcionales
          </span>
          <ng-container *ngFor="let documento of isOptional(); trackBy: trackByDocument">
            <div class="documentos__files transicion-de-despliegue">
              <file-uploader [tipoArchivo]="documento" [form]="form" (errorFound)="showFileError($event)"></file-uploader>
            </div>
          </ng-container>
        </div>
      </div>

    </ng-container>
  </div>

  <input type="checkbox" nameControl="documentoPrincipal" style="display: none;">
</div>

<app-error-modal
  *ngIf="showWarningModal || showFileSizeErrorModal"
  title="{{ showWarningModal ? 'Se eliminarán los documentos cargados' : 'El archivo supera el peso permitido' }}"
  message="{{ showWarningModal ? 'Estás modificando la forma de cargar los archivos. A partir de ahora el o los documentos que fueron cargados previamente serán eliminados.' : 'Puedes tomar una foto o adjuntar un archivo (jpg, jpeg, png, heic o pdf) que no supere los 15MB.' }}"
  textPrimaryAction="{{ showWarningModal ? 'Continuar' : 'Volver a cargar' }}"
  textSecondaryAction="Cancelar"
  (primaryAction)="showWarningModal ? deleteFilesHospitalCare() : closeSizeErrorModal()"
  (secondaryAction)="showWarningModal ? closeWarningModal() : closeSizeErrorModal()"
></app-error-modal>