import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StorageService } from '@common/services/storage.service';
import { IPrestacion } from '@common/interfaces/IPrestacion';

@Component({
  selector: 'app-summary-services-provided',
  templateUrl: './summary-services-provided.component.html',
  styleUrls: ['./summary-services-provided.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SummaryServicesProvidedComponent {
  @Input() cardPrestacion!: IPrestacion;
  @Input() idSeleccion: number = -1;

  constructor(public storage: StorageService) {}
}
