<app-modal
  [showHeader]="false"
  [borderClass]="false"
  size="small"
  alignActionBtn="center"
  alignContent="center"
  [primaryActionEnabled]="true"
  [primaryActionBtn]="textPrimaryAction"
  [secondaryActionBtn]="textSecondaryAction"
  (primaryAction)="onPrimaryAction()"
  (secondaryAction)="onSecondaryAction()"
>
  <ng-container body>
    <img class="modal__icon" [src]="icon">
    <h5 *ngIf="title" class="modal__title">{{ title }}</h5>
    <p class="modal__message" [innerHTML]="message"></p>
  </ng-container>
</app-modal>