import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { IConsignment, IResponseConsignment, IReentry, IReentryResponse } from '@common/interfaces';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReembolsoService {
  private ssoToken!: string;
  private headers!: HttpHeaders;

  constructor(private http: HttpClient) { }

  async postConsignment(consignment: IConsignment): Promise<IResponseConsignment | void> {
    const request: { data: IConsignment } = { data: consignment };
    this.ssoToken = sessionStorage.getItem('ssoToken')!;
    this.headers = new HttpHeaders()
    try {
      const data: IResponseConsignment = await this.http
        .post<IResponseConsignment>(
          `${environment.URL_BFF_BASE}/client/consignment`,
          request,
          { headers: this.headers }
        )
        .toPromise();
      return data;
    } catch (error) {
      console.log('error', error);
      if ((error as HttpErrorResponse).status >= 500) return localStorage.setItem('httpStatus', `${(error as HttpErrorResponse).status}`);
      if ((error as any).status === 0) return localStorage.setItem('httpStatus', `401`);
      return localStorage.setItem('httpStatus', `500`);
    }
  }

  async postReentry(consignment: IReentry): Promise<IReentryResponse> {
    return await this.http.post<IReentryResponse>(`${environment.URL_BFF_BASE}/BFF/Reimbursement/Reentry`, { ...consignment }).toPromise();
  }
}
