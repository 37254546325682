import { Component, OnInit, OnDestroy } from '@angular/core';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import {
  IntValidator,
  filtrarNombreDoc,
  limpiarRut,
  RutValidator,
  REGEX_NUMEROS_POSITIVOS,
  REGEX_RUT,
} from '@common/utils';
import { Reembolso } from '@common/models';
import { IResumenReembolso } from '@common/interfaces';
import {
  ArancelService,
  ModalService,
  AppInsightsService,
  StorageService,
  DataUsuarioService,
  ReembolsoActualService,
} from '@common/services';
import {
  PasoPrestacionComponent,
  PasoArancelesComponent,
  PasoBeneficiarioComponent,
  PasoDatosComponent,
  PasoDocumentosComponent,
  ServicesProvidedDetailComponent,
} from './components';

import { SummaryComponent } from './components';
import { SharedModule } from '@common/shared.module';
import { ModalsModule } from '@common/components/modals/modals.module';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
  standalone: true,
  imports: [
    PasoArancelesComponent,
    PasoBeneficiarioComponent,
    PasoDatosComponent,
    PasoDocumentosComponent,
    PasoPrestacionComponent,
    SummaryComponent,
    ServicesProvidedDetailComponent,
    SharedModule,
    ModalsModule,
  ],
})
export class RequestComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public cambiosFormulario$!: Subscription;
  public porcentajeBeneficiario = 20;
  public porcentajePrestacion: number = -1;
  public porcentajeDatos: number = -1;
  public porcentajeDocumentos: number = -1;
  public porcentajeAranceles: number = -1;
  public esPuedeFinalizar = false;
  public esHospitalario = false;
  public esMedicamentos = false;
  public esMostrarResumen = false;
  public esOtroReembolso = false;
  public nombreBeneficiario = '';

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private reembolso: ReembolsoActualService,
    private dataUsuario: DataUsuarioService,
    private storage: StorageService,
    private readonly rutValidator: RutValidator,
    private readonly intValidator: IntValidator,
    private router: Router,
    private arancelService: ArancelService,
    private appInsightsService: AppInsightsService,
    public modalService: ModalService
  ) {
    this.form = this.formBuilder.group({
      persona: ['', [Validators.required]],
      prestacion: ['', [Validators.required]],
      pregunta: ['', [Validators.required]],
      isapreFonasa: ['', [Validators.required]],
      rutInstitucion: [
        '',
        [
          Validators.required,
          Validators.maxLength(12),
          Validators.pattern(REGEX_RUT),
          this.rutValidator.esRutValido,
        ],
      ],
      nBoleta: [
        '',
        [Validators.required, Validators.pattern(REGEX_NUMEROS_POSITIVOS)],
      ],
      fechaAtencion: ['', [Validators.required]],
      montoHospitalario: [
        '',
        [Validators.required, this.intValidator.esIntValido],
      ],
      montoMedicamentos: [
        '',
        [Validators.required, this.intValidator.esIntValido],
      ],
      confirmar: ['', [Validators.required]],
      documentoPrincipal: ['', [Validators.required]],
      documentosSubidos: ['', [Validators.required]],
      aranceles: ['', [Validators.required]],
    });

    if (!this.dataUsuario.usuarioConectado) {
      this.router.navigate(['/']);
      return;
    }
  }

  async ngOnInit(): Promise<void> {
    this.appInsightsService.trackPageView('Solicitud');
    await this.storage.ObtenerPrestadores(
      this.dataUsuario.usuarioConectado.rutCuerpoDv
    );
    this.cambiosFormulario$ = this.form.valueChanges.subscribe(
      (input: IFormulario) => {
        let estadoPasoUno = false,
          estadoPasoDos = false,
          estadoPasoTres = false,
          estadoPasoCuatro = false,
          estadoPasoCinco = false;

        const prestacion = parseInt(input.prestacion, 10);

        // Selección Beneficiario.
        estadoPasoUno = this.verificarPasoUno(input);
        // Selección Prestación + Pregunta.
        if (estadoPasoUno) {
          estadoPasoDos = this.verificarPasoDos(input);
        }

        // Ingreso de Datos + Pregunta.
        if (estadoPasoUno && estadoPasoDos) {
          estadoPasoTres = this.verificarPasoTres(input);
        }

        // Subida de Documentos.
        if (estadoPasoUno && estadoPasoDos && estadoPasoTres) {
          estadoPasoCuatro = this.verificarPasoCuatro(input);
        }

        if (
          estadoPasoUno &&
          estadoPasoDos &&
          estadoPasoTres &&
          estadoPasoCuatro
        ) {
          if (prestacion === 1 || prestacion === 5) {
            this.esPuedeFinalizar = true;
          } else {
            this.arancelService.obtenerAranceles(
              this.dataUsuario.usuarioConectado.rutCuerpoDv,
              this.reembolso.idPrestacion
            );
            estadoPasoCinco = this.verificarPasoCinco(input);

            if (estadoPasoCinco) this.esPuedeFinalizar = true;
            else this.esPuedeFinalizar = false;
          }
        } else {
          this.esPuedeFinalizar = false;
        }
      }
    );
  }

  ngOnDestroy(): void {
    try {
      this.form.reset();
      this.cambiosFormulario$.unsubscribe();
    } catch (error) {
      console.warn('¡Suscripción no existe!');
    }
  }

  public irResumen(): void {
    this.esMostrarResumen = true;
    this.crearRegistroReembolso();
  }

  public reactivarFormulario(event: boolean): void {
    this.esMostrarResumen = event;
  }

  public crearNuevoReembolso(event: boolean): void {
    if (event) {
      setTimeout(() => {
        this.esMostrarResumen = false;
        this.form.get('prestacion')?.setValue(-2);
        this.form.get('pregunta')?.setValue('');
        this.esOtroReembolso = true;
        this.nombreBeneficiario =
          this.reembolso.reembolsos[0].beneficiarioSeleccionado.nombres +
          ' ' +
          this.reembolso.reembolsos[0].beneficiarioSeleccionado.apellidos;
        setTimeout(() => {
          this.storage.marcarCardPorId(this.storage.idSeleccionado);
          this.form.get('prestacion')?.setValue(this.storage.idSeleccionado);
          this.reembolso.vaciarArchivos();
          this.reembolso.vaciarAranceles();
          this.resetearFormulario();
        }, 200);
      }, 100);
    }
  }

  public cancelar(): void {
    this.router.navigate(['/historial']);
    this.storage.idSeleccionado = -1;
    this.storage.coberturaSeleccionada = undefined;
    this.storage.esOtroReembolso = false;
    this.storage.reiniciarCards();
    this.reembolso.reiniciarServicio();
    this.appInsightsService.trackFlow('Abandono', {});
  }

  private verificarPasoUno(input: IFormulario): boolean {
    this.porcentajeBeneficiario = 20;
    if (input.persona) {
      this.porcentajeBeneficiario += 80;
      return true;
    }
    return false;
  }

  private verificarPasoDos(input: IFormulario): boolean {
    this.porcentajePrestacion = 10;
    this.esHospitalario = false;
    this.esMedicamentos = false;

    const prestacion = parseInt(input.prestacion, 10);

    if (prestacion > -1) {
      this.porcentajePrestacion += 45;

      // Revisión pregunta «consulta médica» y «exámenes y procedimientos».
      if (prestacion === 0 || prestacion === 4) {
        if (input.pregunta === true || input.pregunta === false)
          this.porcentajePrestacion += 45;
      } else if (prestacion === 1) {
        this.esHospitalario = true;
        this.porcentajePrestacion += 45;
      } else if (prestacion === 5) {
        this.esMedicamentos = true;
        this.porcentajePrestacion += 45;
      } else {
        this.porcentajePrestacion += 45;
      }
      this.reembolso.esReembolsoPreviamente = this.form.get('pregunta')?.value;
      if (this.porcentajePrestacion === 100) return true;
    }
    this.porcentajeDatos = -1;
    this.porcentajeDocumentos = -1;
    this.porcentajeAranceles = -1;
    return false;
  }

  private verificarPasoTres(input: IFormulario): boolean {
    this.porcentajeDatos = 10;

    // Caso Hospitalario
    if (this.form.get('prestacion')?.value === 1) {
      return this.checkHospitalario();
      // Caso Compra de Medicamentos
    } else if (this.form.get('prestacion')?.value === 5) {
      return this.checkMedicamentos(input);
    } else {
      this.checkDatos();

      // Revisión prestaciones sin segunda pregunta.
      if (this.form.get('prestacion')?.value === 2) {
        return this.checkSegundaPregunta();
      } else {
        if (this.porcentajeDatos < 90) {
          this.porcentajeDocumentos = -1;
          this.porcentajeAranceles = -1;
          return false;
        }
        // Revisión «consulta» y «exámenes», copago > UF15.
        if (
          this.form.get('prestacion')?.value === 0 ||
          this.form.get('prestacion')?.value === 4
        ) {
          return this.setCopago(input);
        }
        // Revisión «atención dental», más de una atención.
        else if (
          this.form.get('prestacion')?.value === 3 &&
          input.confirmar !== undefined
        ) {
          this.reembolso.esMasDeUnaAtencion = input.confirmar;
          this.porcentajeDatos += 10;
          return true;
        }
        this.porcentajeDocumentos = -1;
        this.porcentajeAranceles = -1;
        return false;
      }
    }
  }

  private verificarPasoCuatro(input: IFormulario): boolean {
    this.porcentajeDocumentos = 10;

    const prestacion = parseInt(input.prestacion, 10);

    // Caso hospitalario sin selección de documentos.
    if (prestacion === 1) {
      this.form.get('documentoPrincipal')?.setValue(3, { emitEvent: false });
      this.porcentajeDocumentos += 10;
      if (input.documentosSubidos) {
        this.porcentajeDocumentos += 80;
        return true;
      } else {
        return false;
      }
    } else {
      if (input.documentoPrincipal > -1) {
        this.porcentajeDocumentos += 10;
        if (
          input.documentosSubidos === true &&
          this.reembolso.obtenerArchivosActuales().length > 0
        ) {
          this.porcentajeDocumentos += 80;
          return true;
        } else {
          this.porcentajeAranceles = -1;
          return false;
        }
      }
      return false;
    }
  }

  private verificarPasoCinco(input: IFormulario): boolean {
    this.porcentajeAranceles = 20;

    if (input.aranceles) this.porcentajeAranceles += 80;

    if (this.porcentajeAranceles === 100) return true;
    else return false;
  }

  private crearRegistroReembolso(): void {
    const idPrestacion = parseInt(this.form.get('prestacion')!.value, 10);
    const primeraPregunta: boolean = this.form.get('pregunta')!.value;
    const segundaPregunta: boolean = this.form.get('confirmar')!.value;
    let montoTotalBonificacion = 0;
    let montoTotalPrestacion = 0;
    this.reembolso.obtenerListadoAranceles().forEach((arancel) => {
      montoTotalBonificacion += arancel.descuento;
      montoTotalPrestacion += arancel.montoTotal;
    });

    let esMasDeUnaAtencion = false;
    if (this.form.get('prestacion')!.value === 3) {
      esMasDeUnaAtencion = this.form.get('confirmar')!.value;
    } else {
      this.reembolso.obtenerListadoAranceles().forEach((arancel) => {
        if (arancel.sesiones > 1) {
          esMasDeUnaAtencion = true;
        } else {
          esMasDeUnaAtencion = false;
        }
      });
    }

    if (this.reembolso.idPrestacion === 5 || this.reembolso.idPrestacion === 1)
      this.reembolso.idIsapre = 217;

    const reembolso = new Reembolso(
      this.reembolso.idPrestacion,
      this.reembolso.idIsapre,
      this.form.get('nBoleta')!.value,
      this.form.get('fechaAtencion')!.value
        ? this.form.get('fechaAtencion')!.value
        : new Date(),
      filtrarNombreDoc(this.form.get('documentoPrincipal')?.value),
      limpiarRut(this.form.get('rutInstitucion')!.value),
      this.dataUsuario.usuarioConectado.filtrarRutPorBeneficiario(
        this.form.get('persona')?.value
      )[1],
      this.reembolso.obtenerArchivosActuales(),
      this.reembolso.obtenerListadoAranceles(),
      this.crearResumenReembolso(),
      this.crearResumenReembolso().montoSolicitado,
      montoTotalBonificacion,
      montoTotalPrestacion,
      esMasDeUnaAtencion
    );

    if (idPrestacion === 0 || idPrestacion === 4) {
      reembolso.esPasoPorIsapre = primeraPregunta;
      reembolso.esCopagoMayor15UF = segundaPregunta;

      if (
        filtrarNombreDoc(this.form.get('documentoPrincipal')?.value) ===
          'Documento de boleta o factura' &&
        this.reembolso.idIsapre === 211
      ) {
        reembolso.esPasoPorIsapre = true;
      }
    } else {
      reembolso.esPasoPorIsapre = true;
      reembolso.esCopagoMayor15UF = false;
    }

    if (idPrestacion === 5) {
      reembolso.esRecetaPermanente = segundaPregunta;
    } else {
      reembolso.esRecetaPermanente = false;
    }

    this.reembolso.reembolsos.push(reembolso);
  }

  private crearResumenReembolso(): IResumenReembolso {
    const nombrePrestacion: string =
      this.storage.cardPrestaciones[+this.form.get('prestacion')!.value]
        .nombrePrestacion;
    const fecha: Date = this.form.get('fechaAtencion')!.value
      ? (this.form.get('fechaAtencion')!.value as Date)
      : new Date();
    let valorPrestaciones = 0;
    let montoSolicitado = 0;
    if (
      this.form.get('prestacion')!.value !== 1 &&
      this.form.get('prestacion')!.value !== 5
    ) {
      this.reembolso.obtenerListadoAranceles().forEach((arancel) => {
        valorPrestaciones += arancel.montoTotal;
        montoSolicitado += arancel.montoTotal - arancel.descuento;
      });
    } else if (this.form.get('prestacion')!.value === 5) {
      valorPrestaciones = +this.form
        .get('montoMedicamentos')!
        .value.replace(/\D/g, '');
      montoSolicitado = +this.form
        .get('montoMedicamentos')!
        .value.replace(/\D/g, '');
    } else {
      valorPrestaciones = +this.form
        .get('montoHospitalario')!
        .value.replace(/\D/g, '');
      montoSolicitado = +this.form
        .get('montoHospitalario')!
        .value.replace(/\D/g, '');
    }

    const resumenReembolso: IResumenReembolso = {
      nombrePrestacion,
      fecha,
      valorPrestaciones,
      montoSolicitado,
    };

    return resumenReembolso;
  }

  private resetearFormulario(): void {
    this.form.get('pregunta')?.setValue('');
    this.form.get('documentosSubidos')?.setValue('');
    this.form.get('confirmar')?.setValue(undefined);
    this.form.get('aranceles')?.setValue('');
    this.form.get('fechaAtencion')?.setValue('');
    this.form.get('isapreFonasa')?.setValue('');
    this.form.get('nBoleta')?.setValue('');
    this.form.get('rutInstitucion')?.setValue('');
  }

  public ocultarModalDetalles(event: boolean): void {
    this.modalService.modalDetalle = event;
  }

  public esperaRespuestaModal(esPermitirSubida: boolean): void {
    this.modalService.modalAdvertencia = false;
    this.modalService.esPermiteSubida = esPermitirSubida;
  }

  checkHospitalario(): boolean {
    if (
      this.form.get('montoHospitalario')?.valid &&
      this.form.get('montoHospitalario')?.value !== '$0' &&
      this.form.get('montoHospitalario')?.value !== '$'
    ) {
      this.porcentajeDatos += 90;
      return true;
    } else {
      this.porcentajeDocumentos = -1;
      return false;
    }
  }

  checkMedicamentos(input: IFormulario): boolean {
    if (this.form.get('rutInstitucion')?.valid) this.porcentajeDatos += 20;
    if (this.form.get('nBoleta')?.valid) this.porcentajeDatos += 20;
    if (
      this.form.get('montoMedicamentos')?.valid &&
      this.form.get('montoMedicamentos')?.value !== '$0' &&
      this.form.get('montoMedicamentos')?.value !== '$'
    )
      this.porcentajeDatos += 20;
    if (this.form.get('fechaAtencion')?.valid) this.porcentajeDatos += 20;

    // Revisión «compra medicamentos», receta permanente.
    if (input.confirmar === true || input.confirmar === false) {
      this.reembolso.esMasDeUnaAtencion = input.confirmar;
      this.porcentajeDatos += 10;
      if (this.porcentajeDatos === 100) {
        return true;
      }
    }

    this.porcentajeDocumentos = -1;
    this.porcentajeAranceles = -1;
    return false;
  }

  checkDatos(): void {
    if (this.form.get('isapreFonasa')?.valid) this.porcentajeDatos += 20;
    if (this.form.get('rutInstitucion')?.valid) this.porcentajeDatos += 20;
    if (this.form.get('nBoleta')?.valid) this.porcentajeDatos += 20;
    if (this.form.get('fechaAtencion')?.valid) this.porcentajeDatos += 20;
  }

  checkSegundaPregunta(): boolean {
    this.porcentajeDatos += 10;
    if (this.porcentajeDatos === 100) {
      return true;
    } else {
      this.porcentajeDocumentos = -1;
      this.porcentajeAranceles = -1;
      return false;
    }
  }

  setCopago(input: IFormulario): boolean {
    if (input.confirmar === true || input.confirmar === false) {
      this.reembolso.esCopagoMayor15UF = input.confirmar;
      this.porcentajeDatos += 10;
      return true;
    }
    return false;
  }
}

interface IFormulario {
  persona: string;
  prestacion: string;
  pregunta: boolean;
  isapreFonasa: string;
  rutInstitucion: string;
  nBoleta: string;
  fechaAtencion: Date;
  montoHospitalario: string;
  confirmar: boolean;
  documentoPrincipal: number;
  documentosSubidos: boolean;
  aranceles: boolean;
}
