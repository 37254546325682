<div id="scrollTop" class="contenedor" [ngClass]="{'ocultar': esMostrarResumen}">

  <p class="header" *ngIf="!esOtroReembolso; else otro">
    Solicitud de reembolso
  </p>
  <ng-template #otro>
    <p class="header-continuacion">
      <strong>Agregar otro reembolso para:</strong>&nbsp;{{ nombreBeneficiario | titlecase }}
    </p>
  </ng-template>
  <div class="divider"></div>

  <div class="pasos">
    <paso-beneficiario [ngClass]="{'ocultar': esOtroReembolso}" [form]="form" [porcentaje]="porcentajeBeneficiario"></paso-beneficiario>

    <paso-prestacion [form]="form" [porcentaje]="porcentajePrestacion"></paso-prestacion>

    <paso-datos [form]="form" [porcentaje]="porcentajeDatos"></paso-datos>

    <paso-documentos [form]="form" [porcentaje]="porcentajeDocumentos"></paso-documentos>

    <paso-aranceles *ngIf="!esHospitalario && !esMedicamentos" [form]="form" [porcentaje]="porcentajeAranceles"></paso-aranceles>

    <div class="pasos__botones" *ngIf="esPuedeFinalizar">
      <vs-button texto="Cancelar" type="secondary" size="small" (click)="cancelar()"></vs-button>
      <vs-button texto="Siguiente" type="primary" size="medium" (click)="irResumen()"></vs-button>
    </div>
  </div>

</div>

<app-warning-replace-file-modal *ngIf="modalService.modalAdvertencia" (eventArchivo)="esperaRespuestaModal($event)"></app-warning-replace-file-modal>

<vs-preview></vs-preview>

<app-services-provided-detail (eventGuardar)="ocultarModalDetalles($event)" [form]="form" *ngIf="modalService.modalDetalle"></app-services-provided-detail>

<div class="resumen" *ngIf="esMostrarResumen">
  <app-summary (eventVolver)="reactivarFormulario($event)" (eventCrearNuevo)="crearNuevoReembolso($event)"></app-summary>
</div>