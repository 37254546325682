import { Subscription } from 'rxjs';
import { StorageService } from '@common/services/storage.service';
import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { formateoValor } from '../../utils/utils';

@Component({
  selector: 'vs-input',
  templateUrl: './vs-input.component.html',
  styleUrls: ['./vs-input.component.scss']
})
export class VsInputComponent implements OnInit, OnDestroy {
  @Input() nameControl = 'rutInstitucion';
  @Input() form!: UntypedFormGroup;
  public placeholder = 'Rut institución/prestador';
  public maxLargo = 50;
  public tipoInput = 'text';
  public esValido = false;
  public esTieneTexto = false;
  public icono = 'check-green';
  public inputSub!: Subscription;

  constructor(private reembolso: ReembolsoActualService, private storage: StorageService) { }

  ngOnInit(): void {
    switch (this.nameControl) {
      case 'rutInstitucion':
        this.placeholder = 'Rut institución/prestador donde te atendiste';
        if (this.reembolso.idPrestacion === 5) {
          this.placeholder = 'Rut farmacia';
        }
        this.maxLargo = 12;
        break;
      case 'nBoleta':
        this.placeholder = 'Número boleta o factura';
        this.maxLargo = 10;
        this.tipoInput = 'tel';
        break;
      case 'montoHospitalario':
      case 'montoMedicamentos':
        this.placeholder = 'Monto solicitado';
        this.maxLargo = 12;
        break;
      case 'sesiones':
        this.placeholder = 'Número de sesiones';
        this.maxLargo = 2;
        break;
      case 'valor':
        this.placeholder = 'Valor';
        if (this.reembolso.idPrestacion === 5) {
          this.placeholder = 'Valor medicamento';
        }
        this.maxLargo = 12;
        break;
      case 'bonificacion':
        this.placeholder = 'Bonificación Isapre/Fonasa';
        if (this.reembolso.idPrestacion === 5) {
          this.placeholder = 'Descuento institución';
        }
        this.maxLargo = 12;
        break;
    }

    this.blurMac();
    this.checkInputBonificacion();
  }

  ngOnDestroy(): void {
    try {
      this.form.get(this.nameControl)?.setValue('', { emitEvent: false });
      this.inputSub.unsubscribe();
    } catch (error) { }
  }

  perdidaFocus(nameControl: string): void {
    const input = document.getElementById(nameControl) as HTMLInputElement;
    const valorInput: string = this.form.get(nameControl)?.value;
    if (this.form.get(nameControl)?.value === '') {
      this.estadoLimpio(input);
    } else if (nameControl === 'montoHospitalario' || nameControl === 'valor' || nameControl === 'montoMedicamentos') {
      if (valorInput === '$0' || valorInput === '$') {
        this.estadoInvalido(input);
      } else { this.estadoValido(input); }
    } else if (nameControl === 'bonificacion') {
      const bonificacion = parseInt((this.form.get(nameControl)?.value).replace(/\D/g,''), 10);
      const valor = parseInt((this.form.get('valor')?.value).replace(/\D/g,''), 10);
      if (bonificacion >= valor) this.estadoInvalido(input);
      else this.estadoValido(input);
    } else if (this.form.get(nameControl)?.valid) {
      this.estadoValido(input);
    } else {
      this.estadoInvalido(input);
    }
  }

  private estadoValido(input: HTMLInputElement): void {
    input.classList.remove('input--error');
    input.classList.add('input--success');
    this.esValido = true;
  }

  private estadoInvalido(input: HTMLInputElement): void {
    input.classList.remove('input--success');
    input.classList.add('input--error');
    this.esValido = false;
  }

  private estadoLimpio(input: HTMLInputElement): void {
    input.classList.remove('input--error');
    input.classList.remove('input--success');
    this.esValido = false;
  }

  escribiendo(): void {
    if (this.form.get(this.nameControl)?.value !== '') {
      this.esTieneTexto = true;
      if (this.nameControl === 'rutInstitucion') {
        const rutSinFormato: string = this.form.get('rutInstitucion')?.value;
        const rutFormateado: string = rutSinFormato.replace(/[.-]/g, '').replace( /^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4')
        this.form.controls['rutInstitucion'].setValue(rutFormateado);
      } else {
        if (this.nameControl !== 'sesiones' && this.nameControl !== 'nBoleta') {
          const monto = parseInt((this.form.get(this.nameControl)?.value).replace(/\D/g,''), 10);
          this.form.controls[this.nameControl].setValue(formateoValor(monto));

          this.updateFormBonificacion()
        }
      }
    } else {
      this.esTieneTexto = false;
    }
  }

  checkInputBonificacion(): void {
    if (this.form && this.nameControl === 'bonificacion') {
      this.inputSub = this.form.get('bonificacion')!.valueChanges.subscribe(() => {
        this.perdidaFocus(this.nameControl);
        const bonificacion = parseInt((this.form.get('bonificacion')?.value).replace(/\D/g,''), 10);
        const valor = parseInt((this.form.get('valor')?.value).replace(/\D/g,''), 10);
        if (valor > bonificacion) {
          this.esValido = true;
        }
      });
    }
  }

  blurMac(): void {
    if (this.storage.osActual === 'Mac' || this.storage.osActual === 'iOS') {
      setTimeout(() => {
        const input = document.getElementById(this.nameControl) as HTMLInputElement;
        input.addEventListener('blur', () => {
          this.perdidaFocus(this.nameControl);
        });
      }, 100);
    }
  }

  updateFormBonificacion(): void {
    if (this.nameControl === 'valor' && this.form.get('bonificacion')?.value !== '') {
      this.form.controls['bonificacion'].setValue(this.form.get('bonificacion')?.value);
    }
  }
}
