<div class="titulo" *ngIf="esTieneTexto">{{ placeholder }}</div>
<div class="contenedor" [formGroup]="form">
  <div class="contenedor__lupa" [ngClass]="{'contenedor__lupa--success': esValido}">
    <img src="assets/svgs/lupa.svg" />
  </div>
  <input
    [id]="nameControl"
    class="input"
    [type]="tipoInput"
    [maxlength]="maxLargo"
    [placeholder]="placeholder"
    [formControlName]="nameControl"
    (change)="perdidaFocus(nameControl)"
    (keyup)="escribiendo()"
    (focus)="enObtencionFocus()"
  />
  <img
    *ngIf="esValido"
    class="input__estado"
    src="assets/svgs/{{ icono }}.svg"
  />
  <div class="contenedor__lista" *ngIf="listadoFiltrado.length > 0">
    <ng-container *ngFor="let arancel of listadoFiltrado; trackBy: trackByArancelInfo">
        <div class="item" *ngIf="arancel" (click)="seleccionarArancel(arancel)" (touchend)="seleccionarArancel(arancel)">
          {{arancel.Arancel | titlecase}}
        </div>
    </ng-container>
  </div>
</div>

