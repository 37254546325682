import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class MessageBoxComponent {
  @Input() title = '¡Ha ocurrido un error!';
  @Input() message?: string;
  @Input() icon: string = 'assets/svgs/warning-circle.svg';
  @Input() link?: string;
  @Input() borderClass: boolean = true;
  @Output() action = new EventEmitter();

  onAction(): void {
    this.action.emit();
  }
}
