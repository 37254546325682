<div class="download__container">
  <div class="download__link" [class.disabled]="!canDownloadNot">
    <img src="assets/svgs/download.svg" alt="Descargar nota de denuncio" />
    <a href="javascript:void(0)" (click)="downloadNotification()">Descargar nota de denuncio</a>
  </div>
  <div *ngIf="showDownLoadLiq"
    class="download__link" [class.disabled]="!canDownloadLiq">
    <img src="assets/svgs/download.svg" alt="Descargar documento de liquidación" />
    <a href="javascript:void(0)" (click)="downloadLiq()">Descargar documento de liquidación</a>
  </div>
</div>
<app-error-message
  *ngIf="showErrorMessage"
  alt="Error en la descarga"
  message="Ocurrió un error y no se pudo completar la descarga"
></app-error-message>
