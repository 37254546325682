<section class="modal-errores">
    <div class="modal-error--container">
        <ds-icon color="actionTertiary" size="44" icon-name="bi-clock"></ds-icon>
        <div class="modal-error--content">
            <div class="error-time">
                <p class="text">Tu sesión expirará en</p>
                <p class="time">02:23 minutos</p>
            </div>
            <div class="question">¿Deseas continuar?</div>
        </div>
        <div class="modal-error--buttons">
            <button class="button-secondary">Salir</button>
            <button class="button-primary">Continuar</button>
        </div>
    </div>
</section>