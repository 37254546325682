import { ErrorHandler, Injectable, inject } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';

import { formatearRut } from '@common/utils';
import { DataUsuarioService } from '@common/services';
import { environment } from '@environments/environment';
import { IContext } from '../interfaces/context-telemetry';
import { ITelemetryService } from '../interfaces/ITelemetry-service';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService implements ITelemetryService{
  private flowId: string;
  private dataUser: DataUsuarioService = inject(DataUsuarioService);
  private router: Router = inject(Router);
  constructor() {
    this.initializeInsights();
    this.flowId = uuidv4();
  }
  private initializeInsights(): void {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.APPINSIGHTS_CONNECTIONSTRING,
        disableAjaxTracking: true,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: {
            errorServices: [new ErrorHandler()],
          },
        }
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((envelope) => {
      envelope.data!['platform'] = 'WEB';
    });
  }
  private appInsights!: ApplicationInsights;
  public trackEvent(nameEvent: string, customProperties?: any): void {
    this.appInsights.trackEvent(
      { name: nameEvent },
      { ...customProperties }
    );
  }

  trackEventService(nameEvent: string): void {
    this.trackEvent(nameEvent, this.generateContext());
  }

  private generateContext(customProperties?: any): any {
    let context: IContext = {
      rut: formatearRut(this.dataUser.usuarioConectado.rutCuerpoDv),
      date: new Date().toLocaleString(),
      platform: this.getPlatform(),
      flowId: this.flowId
    }
    if (!customProperties || !customProperties.hasOwnProperty('page')) {
      return { ...context, page: this.router.url, };
    } else {
      return context;
    }
  }

  getPlatform(): string {
    if ('userAgentData' in navigator && (navigator as any).userAgentData.platform) {
      return (navigator as any).userAgentData.platform;
    } else if (navigator.platform) {
      return navigator.platform;
    } else {
      return 'unknown';
    }
  }
}
