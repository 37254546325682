<div class="documentos" [formGroup]="form">
  <stepper [porcentajeProgeso]="porcentaje" [id]="3"></stepper>
  <div class="documentos__contenido" [ngStyle]="{
    'gap': this.form.get('prestacion')?.value === 1 ? '10px' : '0px 20px'
  }">
    <header id="uploadFiles" [porcentajeProgeso]="porcentaje" texto="Sube tus documentos"></header>
    <ng-container *ngIf="porcentaje >= 0">

      <div class="separator" *ngIf="this.form.get('prestacion')?.value !== 1"></div>

      <question
        *ngIf="this.form.get('prestacion')?.value === 5"
        containerClass="shadow"
        radioColor="green"
        [form]="form"
        question="¿Es una receta permanente?"
      ></question>

      <div *ngIf="isMandatory().length" class="mandatory_type">
        <div class="doc-hospitalarios">
          <span class="descripcion" *ngIf="prestacionSeleccionada?.idPrestacion !== 3 && prestacionSeleccionada?.idPrestacion !== 2 && prestacionSeleccionada?.idPrestacion !== 5">
            Para continuar necesitas subir los <strong>siguientes documentos</strong>
          </span>

          <span class="descripcion" *ngIf="prestacionSeleccionada?.idPrestacion === 5">
            Por favor sube <strong>los siguientes</strong> documentos
          </span>

          <div *ngIf="prestacionSeleccionada?.idPrestacion === 1 || prestacionSeleccionada?.idPrestacion === 3">
            <ul *ngIf="prestacionSeleccionada?.idPrestacion === 1" class="documentos">
              <li>Bonos, reembolsos (asociados a boletas de honorarios), facturas o notas de crédito</li>
              <li>Detalle de la cuenta y programa médico</li>
              <li>Epicrisis o informe médico que indique diagnóstico</li>
              <li>Protocolo operatorio si corresponde intervención quirúrgica <strong>(Opcional)</strong></li>
              <li>Declaración simple de los hechos en caso de accidente <strong>(Opcional)</strong></li>
            </ul>
            <h3>¿Cómo prefieres subir los documentos?</h3>
            <div id="selectUpload" class="select-upload">
              <div (click)="selectUploadHospitalCare(true)" class="select-upload__options" [class.active]="separatedUpload">
                <span class="title">Subir por separado</span>
                <p>Tengo los documentos en archivos distintos</p>
              </div>
              <div (click)="selectUploadHospitalCare(false)" class="select-upload__options" [class.active]="!separatedUpload">
                <span class="title">Subir todo junto</span>
                <p>Tengo los documentos en un solo archivo</p>
              </div>
            </div>
          </div>

          <span class="descripcion" *ngIf="(prestacionSeleccionada?.idPrestacion === 3 && separatedUpload) || prestacionSeleccionada?.idPrestacion === 2">
            Por favor sube el <strong>siguiente</strong> documento
          </span>
        </div>

        <ng-container *ngFor="let documento of isMandatory(); trackBy: trackByDocument">
          <div class="documentos__files transicion-de-despliegue">
            <file-uploader [tipoArchivo]="documento" [form]="form" (errorFound)="showFileError($event)"></file-uploader>
          </div>
        </ng-container>
      </div>

      <div *ngIf="atLeastOne().length" class="mandatory_type">
        <div class="doc-hospitalarios">
          <span class="descripcion">
            Por favor sube <strong>al menos uno</strong> de estos documentos
          </span>
          <ng-container *ngFor="let documento of atLeastOne(); trackBy: trackByDocument">
            <div class="documentos__files transicion-de-despliegue">
              <file-uploader [tipoArchivo]="documento" [form]="form" (errorFound)="showFileError($event)"></file-uploader>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="isOptional().length" class="mandatory_type">
        <div class="doc-hospitalarios">
          <span class="descripcion">
            Documentos opcionales
          </span>
          <ng-container *ngFor="let documento of isOptional(); trackBy: trackByDocument">
            <div class="documentos__files transicion-de-despliegue">
              <file-uploader [tipoArchivo]="documento" [form]="form" (errorFound)="showFileError($event)"></file-uploader>
            </div>
          </ng-container>
        </div>
      </div>

    </ng-container>
  </div>
</div>

<app-error-modal
  *ngIf="showWarningModal || showFileSizeErrorModal"
  title="{{ showWarningModal ? 'Se eliminarán los documentos cargados' : 'El archivo supera el peso permitido' }}"
  message="{{ showWarningModal ? 'Estás modificando la forma de cargar los archivos. A partir de ahora el o los documentos que fueron cargados previamente serán eliminados.' : 'Adjunta un archivo jpg, jpeg, png, heic o pdf que <b>no supere los 15MB<b>' }}"
  textPrimaryAction="{{ showWarningModal ? 'Continuar' : 'Volver a cargar' }}"
  textSecondaryAction="Cancelar"
  (primaryAction)="showWarningModal ? deleteFilesHospitalCare() : closeSizeErrorModal()"
  (secondaryAction)="showWarningModal ? closeWarningModal() : closeSizeErrorModal()"
></app-error-modal>