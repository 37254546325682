import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { StorageService, ReingresoService, ReembolsoService } from '@common/services';
import { Historical } from '@core/models';
import { IReentry, IReentryResponse } from '@common/interfaces';
import { HistoricalService } from '@claims/features/historical/services/historical.service';
import { ModalsModule } from '@common/components/modals/modals.module';
import { SharedModule } from '@common/shared.module';
import { DocReingreso } from '@common/constants';

@Component({
  selector: 'app-reingreso',
  templateUrl: './reingreso.component.html',
  styleUrls: ['./reingreso.component.scss'],
  standalone: true,
  imports: [
    ModalsModule,
    SharedModule,
  ]
})
export class ReingresoComponent implements OnInit, OnDestroy {
  public porcentajeReingreso = 1;
  public archivosSubidos!: Subscription;
  public esCargando = false;
  public esErrorReingreso = false;
  public esMostrarReingresoExitoso = false;
  public reingresoReingresable: Historical;

  public folio = '';
  public ctaBancaria = '';
  public nombreBanco = '';
  public docReingreso = DocReingreso;

  constructor(
    private router: Router,
    public storage: StorageService,
    private reingreso: ReingresoService,
    private readonly reembolsoService: ReembolsoService,
    private readonly historicalService: HistoricalService
  ) {
    this.reingresoReingresable = this.reingreso.historicoReingreso;
  }

  ngOnInit(): void {
    this.archivosSubidos = this.reingreso.archivosReingreso$.subscribe(
      (archivos) => {
        if (archivos.length > 0) this.porcentajeReingreso = 100;
        else this.porcentajeReingreso = 1;
      }
    );
  }

  ngOnDestroy(): void {
    try {
      this.archivosSubidos.unsubscribe();
    } catch {
      console.warn('¡Subscripción no existe!');
    }
  }

  public volverHistorial(): void {
    this.router.navigate(['historial']);
    this.reingreso.reiniciarReingreso();
  }

  public async enviarReingreso(): Promise<void> {
    if (this.esCargando) return;
    this.esCargando = true;

    const consignment: IReentry = this.reingreso.crearReingreso();

    try {
      const respuesta: IReentryResponse =
        await this.reembolsoService.postReentry(consignment);
      await this.historicalService.getReimbursements(true);
      this.folio = respuesta.reentry.nuevaSolicitud;
      this.ctaBancaria = respuesta.accountBankNumber;
      this.nombreBanco = respuesta.accountBankName;
      this.esMostrarReingresoExitoso = true;
    } catch (error) {
      this.esErrorReingreso = true;
    } finally {
      this.esCargando = false;
    }
  }

  continue(): void {
    this.esErrorReingreso = false;
  }

  cancelar(): void {
    this.router.navigate(['historial']);
  }
}
