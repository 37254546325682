import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { AppRoutingModule } from './app.routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@common/shared.module';
import { ClientInterceptor } from '@common/interceptor/web-interceptor';
import { AppInsightsService, ArancelService } from '@common/services';
import { ReingresoComponent } from '@claims/features/reingreso/reingreso.component';
import { LoadingComponent } from '@utils/components/loading/loading.component';

@NgModule({
  declarations: [AppComponent, LoadingComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
  ],
  exports: [CommonModule, BrowserModule, AppRoutingModule],
  providers: [
    ArancelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientInterceptor,
      multi: true,
    },
    AppInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    { provide: Window, useValue: window },
    { provide: Document, useValue: document },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
