import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Usuario } from '@common/models';
import { IUsuario } from '@common/interfaces';
import { LoadingService } from '@utils/services/loading/loading.service';

@Injectable({
  providedIn: 'root',
})
export class DataUsuarioService {
  private _usuarioConectado!: Usuario;
  totalInCurse: number = 0;
  initialTotalInCurse: number = 0;
  defaultOffset: number = 40;

  loadingService: LoadingService = inject(LoadingService);

  constructor(private http: HttpClient) {}

  async InsuredData(rut: string): Promise<boolean> {
    const rutSinDV: string = rut.split('.').join('').split('-')[0];
    const rutDv: string = rut.split('.').join('').split('-')[1];
    const headers: HttpHeaders = new HttpHeaders();
    const origin: string =
      sessionStorage.getItem('esSBS') === 'true' ? 'SBS' : 'WEB';
    const url = `${environment.URL_BFF_BASE}/V2/insured/${rutSinDV}${rutDv}?Origin=${origin}`;

    try {
      const usuarioConectadoV2: IUsuario = await this.http
        .get<IUsuario>(url, { headers: headers })
        .toPromise();

      this.usuarioConectado = new Usuario(usuarioConectadoV2, rutSinDV, rutDv);

      if (this.usuarioConectado) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.warn(error);
      return false;
    }
  }

  public get usuarioConectado(): Usuario {
    return this._usuarioConectado;
  }

  public set usuarioConectado(usuario: Usuario) {
    this._usuarioConectado = usuario;
  }

  public getUserRUT(): { rut: string, dv: string } {
    return {
      rut: this._usuarioConectado.rutCuerpo,
      dv: this._usuarioConectado.rutDigitoVerificador,
    };
  }
}
