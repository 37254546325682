import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { ReimbursementObservationsComponent } from '../reimbursement-observations/reimbursement-observations.component';
import { ReimbursementInfoComponent } from '../reimbursement-info/reimbursement-info.component';
import { ReimbursementAdvertisementComponent } from '../reimbursement-advertisement/reimbursement-advertisement.component';
import { ReimbursementDownloadComponent } from '../reimbursement-download/reimbursement-download.component';
import { ReimbursementStatus } from '../reimbursement-status/reimbursement-status.enum';
import { Historical } from '@core/models';
import { ReingresoService } from '@common/services';
import { ReimbursementService } from '../../services/reimbursement.service';
import { ModalsModule } from '@common/components/modals/modals.module';

@Component({
  selector: 'app-reimbursement-detail',
  templateUrl: './reimbursement-detail.component.html',
  styleUrls: ['./reimbursement-detail.component.scss'],
  standalone: true,
  imports: [
    ReimbursementInfoComponent,
    ReimbursementAdvertisementComponent,
    ReimbursementObservationsComponent,
    ReimbursementDownloadComponent,
    CommonModule,
    ModalsModule,
  ],
})
export class ReimbursementDetailComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  @Input() claim!: Historical;

  public canDownLoad = true;

  displayedAdvertisementTypes: (
    | 'reEntry'
    | 'deductible'
    | 'ambulatory'
    | 'dental'
    | 'pharmacy'
  )[] = [];

  showClaimsStatusFlag: {
    [key: string]: 'ambulatory' | 'dental' | 'pharmacy';
  } = {
    [ReimbursementStatus.onlineOutpatient]: 'ambulatory',
    [ReimbursementStatus.dentalOnline]: 'dental',
    [ReimbursementStatus.onlinePharmacy]: 'pharmacy',
    [ReimbursementStatus.ambulatoryTransaction]: 'ambulatory',
    [ReimbursementStatus.dentalTransaction]: 'dental',
    [ReimbursementStatus.pharmacyTransaction]: 'pharmacy',
  };

  private router = inject(Router);
  private reingresoService = inject(ReingresoService);
  private reimbursementService = inject(ReimbursementService);

  ngOnInit() {
    this.canDownLoad = this.reimbursementService.canDownLoad();
    this.setDisplayedAdvertisementTypes(this.claim);
  }

  setDisplayedAdvertisementTypes(claim: Historical): void {
    if (claim.hasBeenReEntry) {
      this.displayedAdvertisementTypes.push('reEntry');
    }
    if (
      Object.keys(this.showClaimsStatusFlag).includes(
        claim.claimStatus.toLocaleLowerCase()
      )
    ) {
      this.displayedAdvertisementTypes.push(
        this.showClaimsStatusFlag[claim.claimStatus.toLocaleLowerCase()]
      );
    }
    if (claim.deductibleTotalAmount) {
      this.displayedAdvertisementTypes.push('deductible');
    }
  }

  closeDetailModal(): void {
    this.close.emit(true);
  }

  attachDocuments() {
    this.closeDetailModal();
    this.reingresoService.historicoReingreso = this.claim;
    this.reingresoService.esReingreso = true;
    this.router.navigate(['/reingreso']);
  }
}
