
import { ReimbursementStatus } from '@claims/features/historical/components/reimbursement-status/reimbursement-status.enum';
import { Claim, IAttentions, ReEntry } from './claims.interface';

export class Historical {

  private readonly _claimStatus: string;
  private readonly _issueDate: Date;
  private readonly _claimTypeRecord: string;
  private readonly _claimedTotalAmount: number;
  private readonly _paidTotalAmount: any;
  private readonly _deductibleTotalAmount: any;
  private readonly _beneficiaryName: string;
  private readonly _beneficiaryRelationship: string;
  private readonly _beneficiaryRut: number;
  private readonly _beneficiaryDv: string;
  private readonly _requestNumber: number;
  private readonly _paymentDate: Date | null;
  private readonly _policy: number;
  private readonly _allowedReEntry: boolean;
  private readonly _hasBeenReEntry: boolean;
  private readonly _reEntry: ReEntry;
  private readonly _attentions: IAttentions[];
  private readonly _liquidationObservations: string[];
  private readonly _additionalObservation: string;
  private readonly _isMultiple: boolean;
  private readonly _underEvaluation: boolean;
  private readonly _isSettled: boolean;
  private readonly _provider: string;
  private readonly _documentNumber: string;

  constructor(claim: Claim) {
    this._claimStatus = claim.claimStatus;
    this._issueDate = new Date(claim.issueDate);
    this._claimTypeRecord = claim.claimTypeRecord?.name  || 'Prestación Sin Nombre';
    this._claimedTotalAmount = claim.claimedTotalAmount;
    this._paidTotalAmount = claim.paidTotalAmount;
    this._deductibleTotalAmount = claim?.deductibleTotalAmount || 0;
    this._beneficiaryName = claim.beneficiary.name;
    this._beneficiaryRelationship = claim.beneficiary.relationship;
    this._beneficiaryRut = claim.beneficiary.rut;
    this._beneficiaryDv = claim.beneficiary.dv;
    this._requestNumber = claim.requestNumber;
    this._paymentDate =
      claim.paymentDate &&
      new Date(claim.paymentDate).getFullYear() !== 1
        ? new Date(claim.paymentDate)
        : null;
    this._policy = claim.policy;
    this._allowedReEntry = claim.allowedReEntry;
    this._hasBeenReEntry = claim.hasBeenReEntry;
    this._reEntry = claim.reEntry;
    this._attentions = claim.attentions;
    this._liquidationObservations = claim.liquidationObservations;
    this._additionalObservation = claim.additionalObservation;
    this._isMultiple = claim.attentions?.length > 1  || false;
    this._underEvaluation = claim.claimStatus.toLowerCase() == ReimbursementStatus.underEvaluation;
    this._provider = claim.attentions[0].providerName;
    this._documentNumber = claim.attentions[0].documentNumber;
    this._isSettled = this.isSettledClaim(claim.claimStatus);
  }

  private isSettledClaim(claimStatus: string): boolean {
    const shouldShowPaymentDate: string[] = [
      ReimbursementStatus.withPayment,
      ReimbursementStatus.withPartialPayment,
      ReimbursementStatus.onlineOutpatient,
      ReimbursementStatus.dentalOnline,
      ReimbursementStatus.onlinePharmacy,
    ];

    return shouldShowPaymentDate.includes(claimStatus.toLowerCase());
  }

  public get claimStatus(): string {
    return this._claimStatus;
  }

  public get issueDate(): Date {
    return this._issueDate;
  }

  public get claimTypeRecord(): string {
    return this._claimTypeRecord;
  }

  public get claimedTotalAmount(): number {
    return this._claimedTotalAmount;
  }

  public get paidTotalAmount(): number {
    return this._paidTotalAmount;
  }

  public get deductibleTotalAmount(): number {
    return this._deductibleTotalAmount;
  }

  public get beneficiaryName(): string {
    return this._beneficiaryName;
  }

  public get beneficiaryRelationship(): string {
    return this._beneficiaryRelationship;
  }

  public get beneficiaryRut(): string {
    return this._beneficiaryRut.toString();
  }

  public get beneficiaryDv(): string {
    return this._beneficiaryDv;
  }

  public get requestNumber(): number {
    return this._requestNumber;
  }

  public get paymentDate(): Date | null {
    return this._paymentDate;
  }

  public get policy(): number {
    return this._policy;
  }

  public get allowedReEntry(): boolean {
    return this._allowedReEntry;
  }

  public get hasBeenReEntry(): boolean {
    return this._hasBeenReEntry;
  }

  public get reEntry(): ReEntry {
    return this._reEntry;
  }

  public get attentions(): IAttentions[] {
    return this._attentions;
  }

  public get liquidationObservations(): string[] {
    return this._liquidationObservations;
  }

  public get additionalObservation(): string {
    return this._additionalObservation;
  }

  public get isMultiple(): boolean {
    return this._isMultiple;
  }

  public get underEvaluation(): boolean {
    return this._underEvaluation;
  }

  public get isSettled(): boolean {
    return this._isSettled;
  }

  public get provider(): string {
    return this._provider;
  }

  public get documentNumber(): string {
    return this._documentNumber;
  }
}
