import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReingresoComponent } from '@claims/features/reingreso/reingreso.component';
import { LoginComponent } from '@/auth/login/login.component';
import { AuthComponent } from '@/auth/auth/auth.component';
import { HistoricalComponent } from '@claims/features/historical/historical.component';
import { RequestComponent } from '@claims/features/request/request.component';
import { LoggedInUserGuard } from '@core/guards/logged-in-user.guard';

const routes: Routes = [
  { path: '', component: AuthComponent },
  { path: 'historial', component: HistoricalComponent, canActivate: [LoggedInUserGuard] },
  { path: 'reembolso', component: RequestComponent },
  { path: 'reingreso', component: ReingresoComponent },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
