import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { decode } from 'js-base64';

import { environment } from '@environments/environment';
import { ITerms, IBaseResponseService } from '@common/interfaces';

@Injectable({
  providedIn: 'root'
})
export class TerminosService {
  public terminos = '';
  constructor(private http: HttpClient) {
  }

  public async getTerms(): Promise<void> {
    const header = new HttpHeaders({
      'x-applicantion': environment.CLIENT_ID,
    });
    try {
      const response: ITerms = await this.http.get<ITerms>(`${environment.URL_BFF_BASE}/TermsConditions`, {headers: header}).toPromise();
      if(response.httpCode === 200){
        this.terminos = decode(response.base64);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  public async postTerms(): Promise<boolean> {
    const header = new HttpHeaders({
      'x-applicantion': environment.CLIENT_ID,
    });
    try{
      const response: IBaseResponseService = await this.http.post<IBaseResponseService>(`${environment.URL_BFF_BASE}/TermsConditions`, {}, {headers: header}).toPromise();
      if (response.httpCode === 200) {
        return true;
      } else { return false; }
    } catch (error) {
      console.warn(error);
      return false;
    }
  };
}
