import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { timer } from 'rxjs';

import '@vs-design-system/ds-pagination';

import { Historical, SourcePagination } from '@core/models';
import { TelemetryService } from '@utils/telemetry/telemetry.service';
import { TabsInCurseHistoricalComponent } from '../tabs-in-curse-historical/tabs-in-curse-historical.component';
import { ReimbursementStatusComponent } from '../reimbursement-status/reimbursement-status.component';
import { HistoricalEmptyMessageComponent } from '../historical-empty-message/historical-empty-message.component';
import { ReimbursementDetailComponent } from '../reimbursement-detail/reimbursement-detail.component';
import { HistoricalService } from '../../services/historical.service';
import { formateoValor } from '@common/utils';
import { ModalService } from '@common/components/modals';
import { ReimbursementService } from '../../services/reimbursement.service';
import { GENERIC_ERROR_MESSAGE } from '@common/constants';

@Component({
  selector: 'app-historical-table',
  templateUrl: './historical-table.component.html',
  styleUrls: ['./historical-table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TabsInCurseHistoricalComponent,
    ReimbursementStatusComponent,
    ReimbursementDetailComponent,
    HistoricalEmptyMessageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HistoricalTableComponent implements OnInit {
  @Output() onLoadData = new EventEmitter<boolean>();

  public reimbursements: Historical[] = [];
  public formatValue: (valor: number) => string = formateoValor;
  public isLoading = true;
  public isInCurse = true;
  public isServiceError: boolean = false;
  public reimbursementSelected: Historical = {} as Historical;

  public pageSelected: number = 1;
  public totalPages: number = 1;
  public totalRecords: number = 0;
  public recordsPerPage: number = 10;
  public sourcePageCounter: SourcePagination[] = [{ label: '0', value: '0' }];
  public pageCounterSelected: number = 10;
  public reimbursementRowSelected = '';

  private telemetryService: TelemetryService = inject(TelemetryService);
  private historicalService = inject(HistoricalService);
  public modalService = inject(ModalService);
  private reimbursementService: ReimbursementService = inject(ReimbursementService);

  async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<void> {
    try {
      await this.getData();
      this.setRecordsPerPage(10);
      this.setTotalRecords();
      this.setSourcePageCounter();
    } catch (error) {
      this.isServiceError = true;
    } finally {
      this.isLoading = false;
      this.onLoadData.emit();
    }
  }

  async getData(): Promise<void> {
    await this.getReimbursements();
    if (this.isInCurse) this.getInCurseData();
    else this.getHistoricalData();
  }

  async getReimbursements(): Promise<void> {
    this.isServiceError = false;
    this.isLoading = true;
    await this.historicalService
      .getReimbursements()
      .then(() => {  this.isLoading = false; })
      .catch((error: { status?: number }) => {
        if (error.status === 404) {
          this.historicalService.notFoundHistory();
        } else {
          this.isServiceError = true;
        }
        this.isLoading = false;
      });
  }

  getInCurseData(): void {
    this.reimbursements = this.historicalService.underEvaluacionPerPage(
      this.pageSelected
    );
  }

  getHistoricalData(): void {
    this.reimbursements = this.historicalService.historicalPerPage(
      this.pageSelected
    );
  }

  setRecordsPerPage(value: number): void {
    this.recordsPerPage = value;
    this.historicalService.recordsPerPage = this.recordsPerPage;
  }

  setTotalRecords(): void {
    this.totalRecords = this.isInCurse
      ? this.historicalService.totalUnderEvaluacion
      : this.historicalService.totalHistorical;
  }

  setSourcePageCounter(): void {
    const pageSize: number = 10;
    const totalPages: number = Math.ceil(this.totalRecords / pageSize);

    this.sourcePageCounter = Array.from({ length: totalPages }, (_, index) => {
      const labelValue =
        index === totalPages - 1 ? this.totalRecords : (index + 1) * pageSize;
      return {
        label: String(labelValue),
        value: String(labelValue),
      };
    });

    this.totalPages = Math.ceil(this.totalRecords / this.recordsPerPage);
    this.setPageCounterSelected();
  }

  setPageCounterSelected(): void {
    this.pageCounterSelected =
      this.totalRecords < this.recordsPerPage
        ? this.totalRecords
        : this.recordsPerPage;
  }

  // Events
  async onChangePage(event: any): Promise<void> {
    if (this.pageSelected == event.detail) return;
    this.pageSelected = event.detail;
    await this.getData();
  }

  async onChangeRecordsPerPage(event: any): Promise<void> {
    if (event.detail[0] && event.detail[0].value) {
      this.setRecordsPerPage(parseFloat(event.detail[0].value));
      this.pageSelected = 1;
      await this.getData();
      this.setSourcePageCounter();
    }
  }

  async onChangeTab(isIncurse: boolean): Promise<void> {
    this.isInCurse = isIncurse;
    this.reimbursements = [];
    this.setRecordsPerPage(10);
    this.pageSelected = 1;
    await timer(10).toPromise();
    await this.getData();
    this.setTotalRecords();
    this.setSourcePageCounter();
  }

  showReimbursementDetail(claim: Historical, i: number = 0, type: string = ''): void {
    this.reimbursementSelected = claim;
    this.modalService.open();
    const nameTaggingEvent: string = this.isInCurse
      ? this.reimbursementService.eventsTag.SHOW_IN_CURSE_DETAIL
      : this.reimbursementService.eventsTag.SHOW_HISTORICAL_DETAIL;
    this.telemetryService.trackEventService(nameTaggingEvent);

    this.scrollToSelectedRow('scrollTop');
    this.reimbursementRowSelected = `${type}-${i}`;
  }

  hideReimbursementDetail(): void {
    this.modalService.close();
    this.reimbursementSelected = {} as Historical;
    this.scrollToSelectedRow(this.reimbursementRowSelected);
  }

  scrollToSelectedRow(scrollTo: string) {
    const trElement = document.querySelector(`#${scrollTo}`);
    if (trElement) {
      trElement.scrollIntoView({ 
        behavior: 'auto', 
        block: this.reimbursementRowSelected.includes('tr') ? 'center' : 'end' 
      });
    }
  }

  // Errores
  getMessageError(): string {
    return this.isServiceError
      ? GENERIC_ERROR_MESSAGE.message || ''
      : 'Aún no has realizado ningún reembolso. Cuando lo hagas, aparecerá aquí.';
  }

  getMessageErrorTitle(): string {
    return this.isServiceError
      ? GENERIC_ERROR_MESSAGE.title
      : `No se encontraron reembolsos${this.isInCurse ? ' en curso' : ''}`;
  }

  // Utils
  trackByClaim(_: number, claim: Historical): number {
    return claim.requestNumber;
  }
}
