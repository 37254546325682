import { StorageService } from '@common/services/storage.service';
import { Component, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements AfterViewInit {
  @Input() porcentajeProgeso: number = -1;
  @Input() id = 0;

  constructor(public storage: StorageService) { }

  ngAfterViewInit(): void {
    this.cambiaEstadoBarra(this.porcentajeProgeso);
  }

  ngOnChanges(): void {
    try {
      this.cambiaEstadoBarra(this.porcentajeProgeso);
    } catch (error) { }
  }

  cambiaEstadoBarra(porcentaje: number): void {
    const colorProgreso: string = !this.storage.esDesdeSBS ? '#EF6F00' : '#628E37';
    const stepper = document.getElementById(`stepper__bar--${this.id}`) as HTMLDivElement;
    if (porcentaje === -1) {
      stepper.classList.remove('stepper__bar--completed');
      stepper.style.backgroundImage = '';
      stepper.classList.add('stepper__bar--waiting');
    } else if (porcentaje > -1 && porcentaje < 100) {
      stepper.classList.remove('stepper__bar--waiting');
      stepper.classList.remove('stepper__bar--completed');
      stepper.style.backgroundImage = `linear-gradient(180deg, ${colorProgreso} 0%, ${colorProgreso} ${porcentaje}%, #D2D9DF ${porcentaje}%, #D2D9DF 100%)`;
    } else if (porcentaje === 100) {
      stepper.classList.remove('stepper__bar--waiting');
      stepper.style.backgroundImage = '';
      stepper.classList.add('stepper__bar--completed');
    }
  }
}
