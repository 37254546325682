import { Component, Input } from '@angular/core';

@Component({
  selector: 'vs-button',
  templateUrl: './vs-button.component.html',
  styleUrls: ['./vs-button.component.scss']
})
export class VsButtonComponent {
  @Input() texto = 'Siguiente';
  @Input() size = 'medium';
  @Input() type = 'secondary';
  @Input() disabled = false;
}
