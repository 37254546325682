import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReimbursementStatus } from './reimbursement-status.enum';
import { capitalize } from '@common/utils';

@Component({
  selector: 'app-reimbursement-status',
  templateUrl: './reimbursement-status.component.html',
  styleUrls: ['./reimbursement-status.component.scss'],
  standalone: true,
  imports: [ CommonModule ]
})
export class ReimbursementStatusComponent implements OnInit {
  @Input() status!: string;

  label: string = '';

  private reimbursementStatusMap: Record<string, string> = {
    [ReimbursementStatus.underEvaluation]: 'evaluation',
    [ReimbursementStatus.noPayment]: 'rejected',
    [ReimbursementStatus.rejected]: 'rejected',
  };

  ngOnInit(): void {
    this.label = capitalize(this.status);
    this.status = this.reimbursementStatusMap[this.status.toLocaleLowerCase()] || 'check';
  }
}
