import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ETypeFile } from '@common/enum';
import { IPrestador, IPrestadoresService, IPrestacion, IArancelInfo } from '@common/interfaces';
import { environment as ENV } from '@environments/environment';

// TODO: separar responsabilidades

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public esOtroReembolso = false;
  public idSeleccionado: number = -1;
  public coberturaSeleccionada: number | undefined = undefined;
  public osActual = '';
  public listaIsapre: IPrestador[]=[];
  public esDesdeSBS = false;
  public correoContacto: string = ENV.CORREOS.VIDA;

  public readonly codOtrosDentales = '201862019';
  public sizeWarning: string = 'Adjunta un archivo (jpg, jpeg, png, heic o pdf) que no supere los 15MB';
  public otrosDentales!: IArancelInfo;

  constructor(
    private http: HttpClient,
    private winRef: Window
  ) {
    this.osActual = this.obtenerSistemaOperativo();

    const esSBS: string | null = sessionStorage.getItem('esSBS');
    if (esSBS && esSBS === 'true') {
      this.esDesdeSBS = true;
      this.cardPrestaciones.forEach((prestacion) => {
        prestacion.iconoOn = prestacion.iconoOn.replace('.svg', '-sbs.svg');
      });
      this.correoContacto = ENV.CORREOS.SBS;
    }
  }

  public async ObtenerPrestadores(rut: string): Promise<void> {
    this.listaIsapre = [];
    try {
      const ssoToken: string | null = sessionStorage.getItem('ssoToken');
      if (!ssoToken) throw new Error('No existe token guardado.');

      const headers: HttpHeaders = new HttpHeaders()
        .set('x-application', ENV.CLIENT_ID);

      const prestadores: IPrestadoresService = (
        await this.http.get<IPrestadoresService>(
          `${ENV.URL_BFF_BASE}/Provider/Insured/${rut}`,
          { headers: headers }).toPromise()
      );

      prestadores.providers.forEach((provider)=>{
        const prestadoresinfo: IPrestador = {
          nombre: provider.name,
          id : provider.trassaCode
        }
        this.listaIsapre.push(prestadoresinfo);
      });
    } catch (error) {
      console.warn(error);
      this.listaIsapre = [];
    }
  }

  public filtrarCodigoPorIsapre(nombreIsapre: string): number {
    const isapre: IPrestador = this.listaIsapre.filter(isapre => isapre.nombre.toLocaleLowerCase() === nombreIsapre.toLocaleLowerCase())[0];
    return isapre.id;
  }

  public cardPrestaciones: IPrestacion[] = [
    {
      idPrestacion: 0,
      coberturaAsociada: 95,
      nombrePrestacion: 'Consulta Médica',
      iconoOff: 'assets/svgs/consulta-medica-off.svg',
      iconoOn: 'assets/svgs/consulta-medica-on.svg',
      esSeleccionada: false,
      pregunta: '¿Reembolsaste previamente en tu Isapre/Fonasa?',
      confirmar: '¿El monto total de tu copago fue mayor a UF15?',
    },
    {
      idPrestacion: 1,
      nombrePrestacion: 'Atención Hospitalaria',
      coberturaAsociada: 96,
      iconoOff: 'assets/svgs/atencion-hospitalaria-off.svg',
      iconoOn: 'assets/svgs/atencion-hospitalaria-on.svg',
      esSeleccionada: false,
      pregunta: null,
      confirmar: null,
      warning: 'Los reembolsos hospitalarios menores a 24hrs se deben ingresar como procedimiento',
    },
    {
      idPrestacion: 2,
      nombrePrestacion: 'Marcos y lentes',
      coberturaAsociada: 95,
      iconoOff: 'assets/svgs/marcos-y-lentes-off.svg',
      iconoOn: 'assets/svgs/marcos-y-lentes-on.svg',
      esSeleccionada: false,
      pregunta: null,
      confirmar: null,
    },
    {
      idPrestacion: 3,
      nombrePrestacion: 'Atención Dental',
      coberturaAsociada: 97,
      iconoOff: 'assets/svgs/atencion-dental-off.svg',
      iconoOn: 'assets/svgs/atencion-dental-on.svg',
      esSeleccionada: false,
      pregunta: null,
      confirmar: '¿Estás declarando más de una atención en tu boleta?',
    },
    {
      idPrestacion: 4,
      nombrePrestacion: 'Exámenes y procedimientos',
      coberturaAsociada: 95,
      iconoOff: 'assets/svgs/examenes-y-procedimientos-off.svg',
      iconoOn: 'assets/svgs/examenes-y-procedimientos-on.svg',
      esSeleccionada: false,
      pregunta: '¿Reembolsaste previamente en tu Isapre/Fonasa?',
      confirmar: '¿El monto total de tu copago fue mayor a UF15?',
    },
    {
      idPrestacion: 5,
      nombrePrestacion: 'Compra de Medicamentos',
      coberturaAsociada: 95,
      iconoOff: 'assets/svgs/compra-medicamentos-off.svg',
      iconoOn: 'assets/svgs/compra-medicamentos-on.svg',
      esSeleccionada: false,
      pregunta: null,
      confirmar: '¿Esta es una receta permanente?',
    },
  ];

  public marcarCardSeleccionada(prestacion: IPrestacion): void {
    this.cardPrestaciones.forEach((card) => {
      card.esSeleccionada = false;
      if (prestacion.idPrestacion === card.idPrestacion) {
        card.esSeleccionada = true;
      }
    });
  }

  public marcarCardPorId(id: number): void {
    this.cardPrestaciones.forEach((card) => {
      card.esSeleccionada = false;
      if (card.idPrestacion === id) {
        card.esSeleccionada = true;
      }
    });
  }

  public obtenerCardSeleccionada(): IPrestacion {
    return this.cardPrestaciones.filter((prestacion) => prestacion.esSeleccionada === true)[0];
  }

  public reiniciarCards(): void {
    this.cardPrestaciones.forEach((card) => {
      card.esSeleccionada = false;
    })
  }

  public obtenerSistemaOperativo(): string {
    let platform: string = this.winRef.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os: string | null = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(platform)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    } else {
      os = 'Otros';
    }

    return os;
  }
}