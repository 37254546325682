<div class="prestacion">
  <stepper [porcentajeProgeso]="porcentaje" [id]="1"></stepper>
  <div class="prestacion__contenido">
    <header [porcentajeProgeso]="porcentaje" texto="Selecciona una prestación"></header>
    <ng-container *ngIf="porcentaje >= 0" >
      <!-- Cards. -->
      <div class="cards" [ngClass]="{'contenido--despliegue': porcentaje >= 0}">
        <ng-container *ngFor="let prestacion of prestaciones; trackBy: trackByPrestacion">
          <app-services-provided-card
            [form]="form"
            [cardPrestacion]="prestacion"
            (click)="seleccionCard(prestacion)"
          ></app-services-provided-card>
        </ng-container>
      </div>
      <!-- Pregunta. -->
      <div *ngIf="prestacionSeleccionada && prestacionSeleccionada.pregunta" class="pregunta question-despliegue">
        <question
          [form]="form"
          formControlName="pregunta"
          [question]="prestacionSeleccionada.pregunta"
        ></question>
        <div *ngIf="(prestacionSeleccionada.idPrestacion === 0 || prestacionSeleccionada.idPrestacion === 4) && this.form.get('pregunta')?.value === false">
          <app-warning-message></app-warning-message>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="warning__container" *ngIf="prestacionSeleccionada && prestacionSeleccionada.warning">
  <app-alert-message [message]="prestacionSeleccionada.warning"></app-alert-message>
</div>