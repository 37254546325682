<div class="modal_preview_container" *ngIf="this.modalService.modalPreview">
  <div class="modal_preview_doc">
    <div class="modal_preview_content" *ngIf="this.modalService.archivoPreview.base64 && this.modalService.archivoPreview.base64 !==''">
      <div class="modal_preview_header" (click)="cerrarPreview()">
        <img src="assets/svgs/cerrar.svg">
      </div>
      <div class="document--viewer">
        <ng-container [ngSwitch]="this.modalService.archivoPreview.extension">
          <ng-container *ngSwitchCase="'pdf'">
            <img class="default-image" src="assets/svgs/PDFICON.svg" alt="imagen pdf" width="100%">
          </ng-container>
          <ng-container *ngSwitchCase="'heic'">
            <img class="default-image" src="assets/svgs/HEICICON.svg" alt="imagen heic" width="100%">
          </ng-container>
          <ng-container *ngSwitchDefault>
            <img [src]="'data:image/'+this.modalService.archivoPreview.extension+';base64,'+this.modalService.archivoPreview.base64" width="100%" />
          </ng-container>
        </ng-container>
      </div>
      <div class="document--text">
        <span class="title_file_preview">
          {{ capitalize(this.modalService.archivoPreview.prefix || this.modalService.archivoPreview.nombre) }}
        </span>
        <span class="subtitle_file_preview">
          {{ this.modalService.archivoPreview.nombreArchivo }}
        </span>
      </div>
    </div>
  </div>
</div>