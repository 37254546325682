import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { environment } from '@environments/environment';
import { AppInsightsService, AuthenticationService } from '@common/services';
import { deleteKeys, deleteNestedKeys } from '@common/utils';

@Injectable()

export class ClientInterceptor implements HttpInterceptor {

  constructor(
    private appInsightsService: AppInsightsService,
    private auth: AuthenticationService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = new Date().getTime();
    const ssoToken: string | null = sessionStorage.getItem('ssoToken');
    let requestBody: any = req.body;
    let cloned: HttpRequest<any> = req.clone();
    if (ssoToken && !req.headers.has('Content-Type')) {
      const clientId = req.url.includes('/v1/bff/history/') ? environment.X_IBM_CLIENT_ID_BFF_V2 : environment.X_IBM_CLIENT_ID;
      cloned = req.clone({
        headers: req.headers
          .set('Authorization', `Bearer ${ssoToken}`)
          .set('x-ibm-client-id', clientId)
          .set('x-application', this.auth.esDesdeSitioPrivado ? environment.PRIVADO_CLIEN_ID : environment.CLIENT_ID)
      });
    }
    return next.handle(cloned).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        const elapsed = new Date().getTime() - startTime;
        if (typeof req.body === 'object') {
          requestBody = JSON.stringify(deleteNestedKeys(requestBody, ['base64']));
        }
        this.appInsightsService.trackDependency(
          {
            id: uuidv4(),
            name: cloned.url,
            duration: elapsed,
            responseCode: event.status,
          },
          JSON.stringify(deleteKeys(event.body, ['claims', 'tariffs', 'providers', 'base64'])),
          {
            request: requestBody
          }
        );
      }
      return event;
    }), catchError((error: HttpErrorResponse) => {
      const elapsed = new Date().getTime() - startTime;
      if (typeof req.body === 'object') {
        requestBody = JSON.stringify(deleteNestedKeys(requestBody, ['base64']));
      }
      this.appInsightsService.trackDependency(
        {
          id: uuidv4(),
          name: cloned.url,
          duration: elapsed,
          responseCode: error.status,
          success: false,
        },
        JSON.stringify(error.error),
        {
          request: requestBody,
          codeDescription: this.mapHttpCodeToStatus(error.status)
        }
      );
      throw error;
    }));
  }

  private mapHttpCodeToStatus(httpCode: number): string {
    const statusMap: {[key: number]: string} = {
      0: 'NETWORK_ERROR',
      200: 'SUCCESS',
      400: 'REQUEST_ERROR',
      401: 'AUTHENTICATION_ERROR',
      404: 'DATA_NOT_FOUND',
      500: 'SERVICE_ERROR'
    };

    return statusMap[httpCode] || 'UNKNOWN_STATUS';
  }
}
