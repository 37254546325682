import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import {
  ErrorModalComponent,
  ModalComponent,
  SuccessModalComponent,
  TermsandConditionsModalComponent,
  TokenModalComponent,
  WarningReplaceFileModalComponent,
} from '.';

const COMPONENTS = [
  TokenModalComponent,
  ErrorModalComponent,
  WarningReplaceFileModalComponent,
  TermsandConditionsModalComponent,
];

const STANDALONE_COMPONENTS = [ModalComponent, SuccessModalComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, ...STANDALONE_COMPONENTS],
  exports: [...COMPONENTS, ...STANDALONE_COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalsModule {}
