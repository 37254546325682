<div class="reingreso__header">
  <div class="header">
    <p class="titulo">Solicitud de reingreso</p>
    <div class="boton" (click)="volverHistorial()">
      <img class="icono" src="assets/svgs/izquierda.svg" />
      <p class="label">Volver atrás</p>
    </div>
  </div>
  <div class="divisor"></div>
</div>

<div class="reingreso">
  <stepper [porcentajeProgeso]="porcentajeReingreso" [id]="1"></stepper>
  <section class="archivos">
    <header [porcentajeProgeso]="1" texto="Adjunta los documentos solicitados"></header>
    <p class="descripcion">
      {{reingresoReingresable.additionalObservation || 'para completar tu reingreso'}}
    </p>
    <vs-file [tipoArchivo]="storage.docReingreso" ></vs-file>
  </section>
</div>

<div class="finalizar" *ngIf="porcentajeReingreso === 100">
  <vs-spinner *ngIf="esCargando"></vs-spinner>
  <vs-button texto="Solicitar reingreso" type="primary" (click)="enviarReingreso()" [disabled]="esCargando"></vs-button>
</div>

<vs-preview></vs-preview>

<app-success-modal
  *ngIf="esMostrarReingresoExitoso"
  [numeroSolicitado]="folio"
  [ctaBancaria]="ctaBancaria"
  [nombreBanco]="nombreBanco"
></app-success-modal>

<app-error-modal
  *ngIf="esErrorReingreso"
  (primaryAction)="continue()"
  (secondaryAction)="cancelar()"
></app-error-modal>
