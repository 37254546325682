<div class="question question__display" [formGroup]="form">
  <div class="question__container  {{ containerClass }}" >
    <p class="question__text {{ containerClass }}">{{ question }}</p>
    <div class="answers__container">
      <label
        class="answer {{ radioColor }}"
        *ngFor="let item of answers; trackBy: trackByAnswer"
      >
        <label [for]="item.value">{{ item.label }}</label>
        <input
          type="radio"
          [checked]="form.get(formControlName)?.value === item.value"
          [value]="item.value"
          [formControlName]="formControlName"
        />
        <span (click)="onChange(item)" class="checkmark"></span>
      </label>
    </div>
  </div>
</div>
