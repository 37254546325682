<div class="titulo" *ngIf="esValido">{{ tituloInput }}</div>
<div class="select" [formGroup]="form" (click)="abrirSeleccion()">
  <div class="select__lista" *ngIf="esListaAbierta">
    <div class="select__header">
      {{ placeholder }}
      <div class="select__divider"></div>
    </div>
    <div class="select__items">
      <div *ngFor="let item of listado; trackBy: trackByLista" class="item" (click)="seleccionarItem(item)">
        {{ item.nombre | titlecase }}
      </div>
    </div>
  </div>
  <img class="select__chevron" [ngClass]="{'select__chevron--success': esValido}" src="assets/svgs/arrow-down.svg" />
  <input
    [ngClass]="{'input--success': esValido }"
    [id]="nameControl"
    class="input"
    type="text"
    readonly
    [placeholder]="placeholder"
    [formControlName]="nameControl"
    (change)="perdidaFocus(nameControl)"
  />
  <img *ngIf="esValido" class="select__estado" src="assets/svgs/{{icono}}.svg" />
</div>