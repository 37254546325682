import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { DataUsuarioService } from '@common/services';

export const LoggedInUserGuard = () => {
  const router = inject(Router);
  const dataUsuarioService = inject(DataUsuarioService);

  if (dataUsuarioService.usuarioConectado) return true;
  router.navigate(['']);
  return false;
};
