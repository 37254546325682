<div class="login__container">
  <div class="card__container">
    <img *ngIf="!fromSBS; else SBS" src="assets/svgs/logo.svg" class="card__logo" />
    <ng-template #SBS>
      <img src="assets/img/logo-sbs.png" class="card__logo--sbs" />
    </ng-template>

    <span class="card__title">Acceso Clientes</span>
    <div class="card__row">
      <div class="card__label card__col">
        <label for="username">RUT</label>
        <label for="password">Contraseña</label>
      </div>
      <div class="card__input card__col">
        <input type="text" (focus)="onFocus()" (keyup)="onKeyPress($event)" maxlength="12" [(ngModel)]="rut"/>
        <input type="password" autocomplete="off" (focus)="onFocus()" maxlength="20" [(ngModel)]="password"/>
      </div>
    </div>
    <div *ngIf="showError">
      <p class="card__error">Ha ocurrido un error al intentar iniciar sesión.</p>
    </div>
    <div class="card__button">
      <vs-button (click)="initSession()" type="primary" texto="Iniciar Sesión" [disabled]="disabledLogin"></vs-button>
    </div>
  </div>
</div>
