import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';

export interface IAnswer {
  label: string;
  value: boolean;
}

@Component({
  selector: 'question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class QuestionComponent {
  @Input() form!: UntypedFormGroup;
  @Input() question: string = 'Probando';
  @Input() answers: IAnswer[] = [
    { label: 'Si', value: true },
    { label: 'No', value: false },
  ];
  @Input() containerClass: '' | 'shadow' = '';
  @Input() radioColor: 'green' | 'orange' = 'orange';
  @Input() formControlName: string = 'confirmar';
  @Output() changeAnswers = new EventEmitter<IAnswer>();

  onChange(item: IAnswer) {
    this.changeAnswers.emit(item);
  }

  trackByAnswer(_: number, answer: IAnswer): string {
    return answer.label;
  }
}
