import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IFile, IFileUpload, IArancel } from '@common/interfaces';
import { ETypeFile } from '@common/enum';
import { Reembolso } from '@common/models';
import { TAMANO_MAXIMO_KB } from '@common/utils/constants/file';

@Injectable({
  providedIn: 'root'
})
export class ReembolsoActualService {
  private _idIsapre = 0;
  private _idPrestacion = 0;
  private _esReembolsoPreviamente: boolean | undefined = undefined;
  private _esCopagoMayor15UF: boolean | undefined = undefined;
  private _esMasDeUnaAtencion: boolean | undefined = undefined;
  private _esRecetaPermanente: boolean | undefined = undefined;
  private _archivosSubidos: BehaviorSubject<IFileUpload[]> = new BehaviorSubject<IFileUpload[]>([]);;
  public archivosSubidos$: Observable<IFileUpload[]> = this._archivosSubidos.asObservable();
  private _listadoAranceles: BehaviorSubject<IArancel[]> = new BehaviorSubject<IArancel[]>([]);
  public listadoAranceles$: Observable<IArancel[]> = this._listadoAranceles.asObservable();
  private _reembolsos: Reembolso[] = [];

  constructor() { }

  public get idIsapre(): number {
    return this._idIsapre;
  }
  public set idIsapre(value: number) {
    this._idIsapre = value;
  }

  public get idPrestacion(): number {
    return this._idPrestacion;
  }
  public set idPrestacion(value: number) {
    this._idPrestacion = value;
  }

  public get esReembolsoPreviamente(): boolean | undefined {
    return this._esReembolsoPreviamente;
  }
  public set esReembolsoPreviamente(value: boolean | undefined) {
    this._esReembolsoPreviamente = value;
  }

  public get esCopagoMayor15UF(): boolean | undefined {
    return this._esCopagoMayor15UF;
  }
  public set esCopagoMayor15UF(value: boolean | undefined) {
    this._esCopagoMayor15UF = value;
  }

  public get esMasDeUnaAtencion(): boolean | undefined {
    return this._esMasDeUnaAtencion;
  }
  public set esMasDeUnaAtencion(value: boolean | undefined) {
    this._esMasDeUnaAtencion = value;
  }

  public get esRecetaPermanente(): boolean | undefined {
    return this._esRecetaPermanente;
  }
  public set esRecetaPermanente(value: boolean | undefined) {
    this._esRecetaPermanente = value;
  }

  public obtenerArchivosActuales(): IFileUpload[] {
    return this._archivosSubidos.getValue();
  }
  public agregarNuevoArchivo(archivo: IFileUpload): void {
    this._archivosSubidos.next([...this._archivosSubidos.getValue(), archivo]);
  }
  public vaciarArchivos(): void {
    this._archivosSubidos.next([]);
  }
  public vaciarArchivosPorTipo(idTipoArchivo: ETypeFile): void {
    const archivosActuales: IFileUpload[] = this.obtenerArchivosActuales();
    const listadoFiltrado: IFileUpload[] = archivosActuales.filter((archivos) => archivos.idTipoArchivo !== idTipoArchivo);
    this._archivosSubidos.next(listadoFiltrado);
  }
  public eliminarArchivo(idArchivo: string): void {
    const archivosActuales: IFileUpload[] = this.obtenerArchivosActuales();
    const listadoFiltrado: IFileUpload[] = archivosActuales.filter((archivos) => archivos.idArchivoSubido !== idArchivo);
    this._archivosSubidos.next(listadoFiltrado);
  }
  public verificarArchivoUnico(): boolean {
    const archivosActuales: IFileUpload[] = this.obtenerArchivosActuales();
    const listadoFiltrado: IFileUpload[] = archivosActuales.filter((archivos) => archivos.esUnico === true);
    if (listadoFiltrado.length > 0) return true;
    return false;
  }
  public verificarArchivoUnicoPorTipo(idTipoArchivo: number): boolean {
    const archivosActuales: IFileUpload[] = this.obtenerArchivosActuales();
    const listadoFiltrado: IFileUpload[] = archivosActuales.filter(
      (archivos) =>
        archivos.esUnico === true && archivos.idTipoArchivo === idTipoArchivo
    );
    return (listadoFiltrado.length > 0);
  }

  public pesoTotalValido(): boolean {
    const archivosActuales: IFileUpload[] = this.obtenerArchivosActuales();
    let pesoTotal = 0;
    archivosActuales.forEach((archivo) => { pesoTotal += archivo.pesoKB});
    return pesoTotal < TAMANO_MAXIMO_KB;
  }

  public validarSubidaCompleta(tipoDocumentos: IFile[]): boolean {
    if (tipoDocumentos.length === 0) return false;
    const archivosActuales: IFileUpload[] = this.obtenerArchivosActuales();
    const idArchivosSubidos: ETypeFile[] = [];

    archivosActuales.forEach((doc) => idArchivosSubidos.push(doc.idTipoArchivo));
    const actualFilesIDs: number[] = archivosActuales.map((item) => item.idTipoArchivo);

    // Borrar: Si se requieren documentos obligatorios, validamos que esten todos
    if (tipoDocumentos.filter((item) => item.esRequerido).length > 0) {
      const validateMandatory = tipoDocumentos.every((item) => {
        return item.esRequerido
          ? actualFilesIDs.includes(item.idTipoArchivo)
          : true;
      });
      if (!validateMandatory) return false;
    }

    // Borrar: Si se requieren documentos opcionales, validamos que exista al menos uno
    if (tipoDocumentos.filter((item) => item.atLeastOne).length > 0) {
      const validateAtLeastOne = tipoDocumentos.some((item) => {
        return !item.esRequerido && item.atLeastOne
          ? actualFilesIDs.includes(item.idTipoArchivo)
          : false;
      });
      if (!validateAtLeastOne) return false;
    }

    return true;
  }
  public filtrarArchivoUnico(): IFile | null {
    if (this.idPrestacion === 1) return null;
    const archivosActuales: IFileUpload[] = this.obtenerArchivosActuales();
    return archivosActuales.filter((archivos) => archivos.esUnico === true)[0];
  }

  public obtenerListadoAranceles(): IArancel[] {
    return this._listadoAranceles.getValue();
  }
  public agregarArancel(arancel: IArancel): void {
    this._listadoAranceles.next([...this._listadoAranceles.getValue(), arancel])
  }
  public vaciarAranceles(): void {
    this._listadoAranceles.next([]);
  }
  public eliminarArancel(arancelEliminar: IArancel): void {
    const listadoAranceles: IArancel[] = this.obtenerListadoAranceles();
    const listadoFiltrado: IArancel[] = listadoAranceles.filter((arancel) => arancel.id !== arancelEliminar.id);
    this._listadoAranceles.next(listadoFiltrado);
  }

  public get reembolsos(): Reembolso[] {
    return this._reembolsos;
  }
  public set reembolsos(value: Reembolso[]) {
    this._reembolsos = value;
  }
  public eliminarUltimoReembolso(): void {
    this.reembolsos.pop();
  }
  public obtenerGastoTotal(): number {
    let montoTotalTodosGastos = 0;
    this.reembolsos.forEach((reembolso) => {
      montoTotalTodosGastos += reembolso.getMontoTotalSolicitado;
    });
    return montoTotalTodosGastos;
  }

  public reiniciarServicio(): void {
    this.idIsapre = 0;
    this.idPrestacion = 0;
    this.esReembolsoPreviamente = undefined;
    this.esCopagoMayor15UF = undefined;
    this.esMasDeUnaAtencion = undefined;
    this.esRecetaPermanente = undefined;
    this.vaciarArchivos();
    this.vaciarAranceles();
    this.reembolsos = [];
  }
}