<div
  [id]="cardPrestacion.idPrestacion"
  class="card"
  [ngClass]="{
    'card--seleccionada': cardPrestacion.esSeleccionada,
    'card--seleccionada--sbs':
      storage.esDesdeSBS && cardPrestacion.esSeleccionada
  }"
>
  <img
    class="card__icono"
    [ngClass]="{ 'card__icono--off': !cardPrestacion.esSeleccionada }"
    [src]="
      cardPrestacion.esSeleccionada
        ? cardPrestacion.iconoOn
        : cardPrestacion.iconoOff
    "
  />
  <p
    class="card__texto"
    [ngClass]="{
      'card__texto--seleccionada': cardPrestacion.esSeleccionada,
      'card__texto--seleccionada--sbs':
        storage.esDesdeSBS && cardPrestacion.esSeleccionada
    }"
  >
    {{ cardPrestacion.nombrePrestacion }}
  </p>
  <input type="number" style="display: none" />
</div>
