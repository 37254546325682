<div class="prestacion">
  <stepper [porcentajeProgeso]="porcentaje" [id]="1"></stepper>
  <div class="prestacion__contenido">
    <header [porcentajeProgeso]="porcentaje" texto="Selecciona una prestación"></header>
    <ng-container *ngIf="porcentaje >= 0" >
      <!-- Cards. -->
      <div class="cards" [ngClass]="{'contenido--despliegue': porcentaje >= 0}">
        <ng-container *ngFor="let prestacion of prestaciones; trackBy: trackByPrestacion">
          <app-services-provided-card
            [form]="form"
            [cardPrestacion]="prestacion"
            (click)="seleccionCard(prestacion)"
          ></app-services-provided-card>
        </ng-container>
      </div>
      <!-- Pregunta. -->
      <div *ngIf="prestacionSeleccionada && prestacionSeleccionada.pregunta" class="pregunta question-despliegue" [formGroup]="form" >
        <div class="pregunta__contenedor">
          <ng-container *ngIf="prestacionSeleccionada.pregunta">
            <p class="pregunta__texto">{{ prestacionSeleccionada.pregunta }}</p>
            <div class="radio__contenedor">
              <label class="container" *ngFor="let item of pregunta; trackBy: trackByPregunta">
                <label [for]="item.valor">{{ item.label }}</label>
                <input type="radio" [checked]="this.form.get('pregunta')?.value === item.valor" [value]="item.valor" formControlName="pregunta">
                <span (click)="checkRadio(item)" class="checkmark"></span>
              </label>
            </div>
          </ng-container>
        </div>
        <div *ngIf="(prestacionSeleccionada.idPrestacion === 0 || prestacionSeleccionada.idPrestacion === 4) && this.form.get('pregunta')?.value === false">
          <app-warning-message></app-warning-message>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="warning__container" *ngIf="prestacionSeleccionada && prestacionSeleccionada.warning">
  <app-alert-message [message]="prestacionSeleccionada.warning"></app-alert-message>
</div>