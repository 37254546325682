import { Component, inject } from '@angular/core';

import {
  AppInsightsService,
  DataUsuarioService,
  RefreshService,
  TerminosService,
} from '@common/services';
import {
  HistoricalHeaderComponent,
  HistoricalTableComponent,
} from './components';
import { SharedModule } from '@common/shared.module';
import { ReimbursementService } from './services/reimbursement.service';
import { MessageBoxComponent } from '@common/components';
import { CROSS_ENTRY_MESSAGE } from './constants/warning-message';
import { ModalsModule } from '@common/components/modals/modals.module';

@Component({
  selector: 'app-historical',
  templateUrl: './historical.component.html',
  styleUrls: ['./historical.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    HistoricalTableComponent,
    HistoricalHeaderComponent,
    MessageBoxComponent,
    ModalsModule
  ],
})
export class HistoricalComponent {
  public showModal = false;
  public canMakeRequests = false;
  public warningMessage = CROSS_ENTRY_MESSAGE;

  private dataUsuarioService = inject(DataUsuarioService);
  private refreshService = inject(RefreshService);
  private terminosService = inject(TerminosService);
  private appInsightsService = inject( AppInsightsService);
  private reimbursementService = inject(ReimbursementService);

  constructor() {
    try {
      this.canMakeRequests = this.reimbursementService.canMakeRequest();
      this.refreshService.iniciarTiempoSesion();
    } catch (error) {
      console.warn('¡No hay usuario conectado!');
    }
  }

  async onLoadData(): Promise<void> {
    await this.terminosService.getTerms();
    this.appInsightsService.trackPageView(this.reimbursementService.eventsTag.PAGE_VIEW);
    this.appInsightsService.setAuthenticatedUserContext(
      this.dataUsuarioService.usuarioConectado.iniciales,
      this.dataUsuarioService.usuarioConectado.rutCuerpo,
      true
    );
  }

  showTermsAndConditionsModal(): void {
    this.showModal = true;
  }

  hideTermsAndConditionsModal(response: boolean): void {
    if (!response) {
      this.showModal = false;
    }
  }
}
