export default class Utils {
  public static async transformarABase64(file: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  public static formatearFecha(fecha: string): string | Date {
    const formato: string = this.generarFormatoFecha();
    if (formato === 'MM/DD/YYYY') {
      const arrayFecha: string[] = fecha.replace(/-/g, '/').split('/');
      const dia: string =
        +arrayFecha[1] > 9 ? arrayFecha[1] : `0${arrayFecha[1]}`;
      const mes: string =
        +arrayFecha[0] > 9 ? arrayFecha[0] : `0${arrayFecha[0]}`;
      const fechaEscogida = new Date();
      fechaEscogida.setFullYear(+arrayFecha[2]);
      fechaEscogida.setMonth(parseInt(mes, 10) - 1);
      fechaEscogida.setDate(parseInt(dia, 10));
      return fechaEscogida;
    } else if (formato === 'DD/MM/YYYY') {
      const arrayFecha: string[] = fecha.replace(/-/g, '/').split('/');
      const fechaEscogida = new Date();
      fechaEscogida.setFullYear(+arrayFecha[2]);
      fechaEscogida.setMonth(+arrayFecha[1] - 1);
      fechaEscogida.setDate(+arrayFecha[0]);
      return fechaEscogida;
    } else {
      return fecha.replace(/-/g, '/');
    }
  }

  private static generarFormatoFecha(): string {
    let fechaLocal = new Date(2022, 11, 31).toLocaleDateString();
    fechaLocal = fechaLocal
      .replace('31', 'DD')
      .replace('12', 'MM')
      .replace('2022', 'YYYY');
    return fechaLocal.replace(/-/g, '/');
  }

  public static generarFecha(): string[] {
    const objetoFecha = new Date();
    const anoAnterior = objetoFecha.getFullYear() - 3;

    const fechaActual: string = objetoFecha.toISOString().split('T')[0];
    objetoFecha.setFullYear(anoAnterior);
    const fechaInicio: string = objetoFecha.toISOString().split('T')[0];

    return [fechaInicio, fechaActual];
  }
}

const formatter = new Intl.NumberFormat('es-CL');

export function formateoValor(valor: number): string {
  if (valor < 1) return '$0';
  return '$' + formatter.format(valor);
}

export function capitalizar(palabra: string): string {
  return palabra
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function crearID(): string {
  return '_' + Math.random().toString(36).substring(2, 9);
}

export function limpiarRut(rut: string): string {
  return rut.replace(/\./g, '');
}

export function formatearRut(rut: string): string {
  return rut
    .replace(/[.-]/g, '')
    .replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
}

export function deleteKeys(obj: any, keys: string[]): void {
  const newObj: any = { ...obj };
  for (let key of keys) {
    delete newObj[key];
  }
  return newObj;
}

export function deleteNestedKeys(obj: any, keys: string[]): any {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (keys.includes(key)) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        deleteNestedKeys(obj[key], keys);
      }
    }
  }
  return obj;
}

export function mapCoberturas(idPrestacion: number): string {
  switch (idPrestacion) {
    case 0:
    case 2:
    case 4:
    case 5:
      return '0060';
    case 1:
      return '0063';
    case 3:
      return '0062';
    default:
      return '';
  }
}

export function reemplazarApostrofes(str: string): string {
  const regex = /'/g;
  return str.replace(regex, ' ');
}
