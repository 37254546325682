
export function codAranceles(idPrestacion: number): TPrestaciones {
  switch (idPrestacion) {
    case 0:
      return 'CONSULTA';
    case 2:
      return 'OPTICA';
    case 3:
      return 'DENTAL';
    case 4:
      return 'EXAMENES Y PROCEDIMIENTOS';
    default:
      return 'CONSULTA';
  }
}

export type TPrestaciones = 'CONSULTA' | 'DENTAL' | 'EXAMENES Y PROCEDIMIENTOS' | 'OPTICA';