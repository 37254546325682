import { Component } from '@angular/core';

@Component({
  selector: 'vs-spinner',
  styleUrls: ['./vs-spinner.component.scss'],
  template: `
    <div class="spinner">
      <svg viewBox="0 0 100 100">
        <g class="position">
          <g class="circle1">
            <circle id="Oval-1" cx="50" cy="50" r="40"></circle>
          </g>
        </g>
        <g class="position">
          <g class="circle2">
            <circle id="Oval-2" cx="50" cy="50" r="20"></circle>
          </g>
        </g>
      </svg>
    </div>
  `
})
export class VsSpinnerComponent {}
