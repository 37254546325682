import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class RutValidator {
  esRutValido = (control: AbstractControl) => {
    const rut: string = control.value;
    if (rut) {
      let valor: string = rut.replace(/\./g, '');
      valor = valor.replace('-', '');

      const cuerpo: string = valor.slice(0, -1);
      let dv: string = valor.slice(-1).toUpperCase();

      if (cuerpo.length < 7) {
        return { rutInvalido: true };
      }

      let suma = 0;
      let multiplo = 2;

      for (let i = 1; i <= cuerpo.length; i++) {
        let index = multiplo * parseInt(valor.charAt(cuerpo.length - i), 10);
        suma = suma + index;
        if (multiplo < 7) {
          multiplo = multiplo + 1;
        } else {
          multiplo = 2;
        }
      }

      let dvEsperado = 11 - (suma % 11);
      let dvObtenido: string | number ;

      if (dv === 'K' && dvEsperado === 10) {
        return null;
      }

      dvObtenido = dv === 'K' ? 10 : dv;
      dvObtenido = parseInt(dv, 10) === 0 ? 11 : dv;

      if (dvEsperado != dvObtenido) {
        return { rutInvalido: true };
      }
    }
    return null;
  };
}
