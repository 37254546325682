import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ETypeFile } from '@common/enum/ETypeFile';
import { SharedModule } from '@common/shared.module';
import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { StorageService } from '@common/services/storage.service';
import { IFile, IFileUpload } from '@common/interfaces/IFile';
import { ModalsModule } from '@common/components/modals/modals.module';
import { AlertMessageComponent, WarningMessageComponent } from '@common/components';
import { ModalService } from '@common/components/modals';
import { ETypeError } from '@common/enum/ETypeError';
import { AtencionHospitalariaDoc, ConsultaMedicaDoc, DocHospitalario, ExamenesProcedimientosDoc, MarcosLentesDoc, CompraMedicamentosPermanentesDoc, CompraMedicamentosDoc, AtencionDentalDoc, DocAtencionDental } from '@common/constants';
import { IPrestacion } from '@common/interfaces/IPrestacion';
import { QuestionComponent } from '../../question/question.component';

@Component({
  selector: 'paso-documentos',
  templateUrl: './paso-documentos.component.html',
  styleUrls: ['./paso-documentos.component.scss'],
  standalone: true,
  imports: [SharedModule, ModalsModule, AlertMessageComponent, WarningMessageComponent, QuestionComponent],
})
export class PasoDocumentosComponent implements OnInit, OnDestroy  {
  @Input() form!: UntypedFormGroup;
  @Input() porcentaje: number = -1;
  public esMostrarTooltip = false;
  private _cambiosFormulario$!: Subscription;
  private _archivos$!: Subscription;
  public documentos: IFile[] = [];
  public archivosActuales: IFileUpload[] = [];
  public esCopago: boolean | undefined;
  public separatedUpload: boolean = true;
  public showWarningModal: boolean = false;
  public showFileSizeErrorModal: boolean = false;

  public prestacionSeleccionada: IPrestacion | null = null;

  constructor(
    public storage: StorageService,
    private reembolso: ReembolsoActualService,
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.separatedUpload = true;
    this._cambiosFormulario$ = this.form.valueChanges.subscribe(() => {
      const idPrestacion = parseInt(this.form.get('prestacion')?.value, 10);
      this.prestacionSeleccionada = this.storage.obtenerCardSeleccionada();
      this.agregarTipoDocumentos(idPrestacion);
    });

    this._archivos$ = this.reembolso.archivosSubidos$.subscribe((archivos) => {
      setTimeout(() => {
        this.reembolso.setDocumentoPrincipal();
        const pesoTotalValido = this.reembolso.pesoTotalValido();
        this.showFileSizeErrorModal = !pesoTotalValido;
        this.form.get('documentosSubidos')?.setValue(this.validarSubidaCompleta() && pesoTotalValido);
      }, 200);
    });

    this.form.get('confirmar')?.valueChanges.subscribe((recetaPermanente) => {
      this.reembolso.esRecetaPermanente = recetaPermanente;
      this.agregarTipoDocumentos(5);
    });
  }

  ngOnDestroy(): void {
    try {
      this._cambiosFormulario$.unsubscribe();
      this._archivos$.unsubscribe();
    } catch (error) { console.warn('¡Suscripción no existe!'); }
  }

  public toggleTooltip(esMostrar: boolean): void {
    this.esMostrarTooltip = esMostrar;
  }

  public isMandatory(): IFile[] {
    return this.documentos.length > 0
      ? this.documentos.filter(file => file.esRequerido)
      : [];
  }

  public atLeastOne(): IFile[] {
    return this.documentos.length > 0
      ? this.documentos.filter(file => file.atLeastOne)
      : [];
  }

  public isOptional(): IFile[] {
    return this.documentos.length > 0
      ? this.documentos.filter(file => !file.esRequerido && !file.atLeastOne)
      : [];
  }

  private agregarTipoDocumentos(idPrestacion: number): void {
    if (idPrestacion === 0) {
      this.documentos = [...ConsultaMedicaDoc];
    } else if (idPrestacion === 1) {
      this.documentos = this.separatedUpload ? [...AtencionHospitalariaDoc] : [ DocHospitalario ];
    } else if (idPrestacion === 2) {
      this.documentos = [...MarcosLentesDoc];
    } else if (idPrestacion === 3) {
      this.documentos = this.separatedUpload ? [...AtencionDentalDoc] : [ DocAtencionDental ];
    } else if (idPrestacion === 4) {
      this.documentos = [...ExamenesProcedimientosDoc];
    } else if (idPrestacion === 5 && this.reembolso.esRecetaPermanente !== undefined) {
        this.documentos = this.reembolso.esRecetaPermanente ? [...CompraMedicamentosPermanentesDoc] : [...CompraMedicamentosDoc];
    } else {
      this.documentos = [];
    }
  }

  private validarSubidaCompleta(): boolean {
    return this.reembolso.validarSubidaCompleta(this.documentos);
  }

  public selectUploadHospitalCare(separatedUpload: boolean): void {
    if (this.separatedUpload === separatedUpload) return;
    this.showWarningModal = this.reembolso.obtenerArchivosActuales()?.length > 0
    if (!this.showWarningModal) this.separatedUpload = separatedUpload;
    this.agregarTipoDocumentos(1);
    if (this.showWarningModal) this.modalService.scrollTo('scrollTop');
  }

  public deleteFilesHospitalCare(): void {
    this.separatedUpload = !this.separatedUpload;
    this.agregarTipoDocumentos(1);
    this.reembolso.vaciarArchivos();
    this.closeWarningModal();
  }

  public closeWarningModal() {
    this.showWarningModal = false;
    this.modalService.scrollTo('selectUpload');
  }
  public closeSizeErrorModal(): void {
    this.showFileSizeErrorModal = false;
  }

  public showFileError(error: ETypeError): void {
    if (error === ETypeError.ERROR_MB) {
      this.showFileSizeErrorModal = true;
    }
  }

  trackByDocument(_: number, documento: IFile): ETypeFile {
    return documento.idTipoArchivo;
  }
}
