import { Injectable } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  private readonly _esFuncionalidadActiva: boolean = true;
  private readonly _tiempoExpiracion: number = 1200;
  private readonly _tiempoRefresco: number = (this.tiempoExpiracion - 330);
  private _tiempoTranscurrido = 0;
  private _temporizador$!: Observable<number>;
  private _temporizador!: Subscription;

  constructor(private sso: AuthenticationService) { }

  public async iniciarTiempoSesion(): Promise<void> {
    if (!this.esFuncionalidadActiva) return;
    this.temporizador$ = interval(1000);
    this._temporizador = this.temporizador$.subscribe(async () => {
      this.tiempoTranscurrido += 1;
      if (this.tiempoTranscurrido === this.tiempoRefresco) {
        await this.refrescarSesion();
      }
    });
  }

  private limpiarTemporizador(): void {
    this._temporizador.unsubscribe();
    this.tiempoTranscurrido = 0;
  }

  private async refrescarSesion(): Promise<void> {
    const esRefreshExitoso: boolean = await this.sso.refreshToken();
    if (esRefreshExitoso) console.info('Sesión refrescada exitosamente.');
    this.limpiarTemporizador();
    if (esRefreshExitoso) {
      this.iniciarTiempoSesion();
    }
  }

  public get tiempoExpiracion(): number {
    return this._tiempoExpiracion;
  }

  public get tiempoRefresco(): number {
    return this._tiempoRefresco;
  }

  public get tiempoTranscurrido(): number {
    return this._tiempoTranscurrido;
  }

  public set tiempoTranscurrido(value: number) {
    this._tiempoTranscurrido = value;
  }

  public get temporizador$(): Observable<number> {
    return this._temporizador$;
  }

  public set temporizador$(value: Observable<number>) {
    this._temporizador$ = value;
  }

  public get esFuncionalidadActiva(): boolean {
    return this._esFuncionalidadActiva;
  }
}