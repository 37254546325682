import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import '@vs-design-system/ds-datepicker';
import { ReembolsoActualService } from '../../services/reembolso-actual.service';

@Component({
  selector: 'vs-calendar',
  templateUrl: './vs-calendar.component.html',
  styleUrls: ['./vs-calendar.component.scss']
})
export class VsCalendarComponent implements OnInit, OnDestroy {
  @Input() nameControl = 'fechaAtencion';
  @Input() form!: UntypedFormGroup;
  public handlerFunctionClickDatePicker: any;
  public icono = 'check-green';
  public esValido = false;
  public labelInput = 'Fecha de atención';

  constructor(private reembolso: ReembolsoActualService) { }

  ngOnInit(): void {
    if (this.reembolso.idPrestacion === 5) {
      this.labelInput = 'Fecha de compra';
    }
    window.addEventListener('onSelectDate', (event: any) => {
      const calendario: HTMLElement | null = document.getElementById('fecha_on_generales');
      let formatoLocal: string;
      formatoLocal = this.generarFormatoFecha();
      const fechaSeleccionada: Date = moment(event.detail.init, formatoLocal).toDate();
      if (fechaSeleccionada <= new Date()) {
        calendario?.setAttribute('state', 'success');
        this.esValido = true;
        this.form.get(this.nameControl)?.setValue(fechaSeleccionada);
      } else {
        calendario?.setAttribute('state', 'error');
        this.esValido = false;
      }
    });
    this.desactivarRangoFechas();
    this.cambiarLabelCalendario();
  }

  ngOnDestroy(): void {
    if (window.removeAllListeners) window.removeAllListeners();
    try {
      this.form.get(this.nameControl)?.setValue('', { emitEvent: false });
    } catch (error) { }
  }

  desactivarRangoFechas(): void {
    let hoyTimeStamp = (new Date()).getTime()
    let haceUnAnnioStamp = Number(hoyTimeStamp) - 31557600000;
    let today = Number(hoyTimeStamp);
    this.handlerFunctionClickDatePicker = (ev: any) => {
      const funcionValidadora: () => void = () => {
        let ldp = document.querySelector('.litepicker') || document.getElementById('fecha_on_generales')
        if (ldp) {
          let allDays: Element[] = Array.from(ldp.querySelectorAll('.day-item'))
          allDays.forEach((dayItem: any) => {
            if (Number(dayItem.getAttribute('data-time')) < haceUnAnnioStamp || Number(dayItem.getAttribute('data-time')) > today) {
              dayItem.onclick = void 0
              dayItem.onclick = void (0)
              dayItem.setAttribute('disabled', '')
              dayItem.style.pointerEvents = "none"
              dayItem.style.color = "rgb(180,180,180)";
            }
          });
        }
      }

      if (document.getElementById('fecha_on_generales')) {
        funcionValidadora();
      }
      if (ev.target.className == 'button-previous-month' || ev.target.className == 'button-next-month') {

        funcionValidadora();
      }
    }
    document.addEventListener('click', this.handlerFunctionClickDatePicker)
  }

  private cambiarLabelCalendario(): void {
    const calendario: HTMLElement | null = document.getElementById('fecha_on_generales');
    if (calendario) {
      setTimeout(() => {
        const label = calendario.getElementsByTagName('label')[0] as HTMLLabelElement;
        label.innerText = this.labelInput;
      }, 0);
    }
  }

  private generarFormatoFecha(): string {
    let fechaLocal = (new Date(2022, 11, 31)).toLocaleDateString();
    fechaLocal = fechaLocal.replace("31", "DD").replace("12", "MM").replace("2022", "YYYY");
    return fechaLocal.replace(/-/g, '/');
  }
}
