import { ETypeFile } from './../../enum/ETypeFile';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'vs-radio',
  templateUrl: './vs-radio.component.html',
  styleUrls: ['./vs-radio.component.scss']
})
export class VsRadioComponent {
  @Input() nameControl = 'documentoPrincipal';
  @Input() form!: UntypedFormGroup;
  @Input() radio: RadioElement = {
    id: ETypeFile.DOC_REEMBOLSO,
    nombre: 'Documento reembolso Isapre o Fonasa',
  }
}

interface RadioElement {
  id: ETypeFile;
  nombre: string;
}