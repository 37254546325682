<section class="modal-pantalla-final">
  <div class="wrapper-final">
    <div class="modal--container">

      <div class="icon">
        <img src="/assets/svgs/final-header-icon.svg" alt="">
      </div>

      <div class="content">
        <!-- Header. -->
        <div class="title">
          <label class="header">
            Recibimos con éxito tu solicitud de {{ tipoSolicitud }}
          </label>
          <p>
            {{ prestacionesAsociadas }} a favor de {{ beneficiario | titlecase }}
          </p>
          <div class="linea-division"></div>
        </div>

        <div class="info">
          <!-- Solo un reembolso y distinto de hospitalario. -->
          <div class="detalles" *ngIf="esMostrarDatosCompletos">
            <div class="detalles--column">
              <div class="info-row">
                <label class="texto-descriptivo">
                  Rut Prestador:
                </label>
                <label class="texto-valor">
                  {{ rutPrestador || '11.111.111-1' }}
                </label>
              </div>
              <div class="info-row">
                <label class="texto-descriptivo">
                  Documento:
                </label>
                <label class="texto-valor">
                  {{ tipoDocumento || 'Documento dummy' }}
                </label>
              </div>
              <div class="info-row">
                <label class="texto-descriptivo">
                  N° {{ detalleNumero }}:
                </label>
                <label class="texto-valor">
                  {{ numeroSolicitado }}
                </label>
              </div>
            </div>
            <!--Fin Detalles izquierda  -->
            <!--Detalles derecha  -->
            <div class="detalles--column">
              <div class="info-row">
                <label class="texto-descriptivo">
                  Fecha emisión:
                </label>
                <label class="texto-valor">
                  {{ fechaActual | date:'dd/MM/yy' }}
                </label>
              </div>
              <div class="info-row">
                <label class="texto-descriptivo">
                  Monto solicitado:
                </label>
                <label class="texto-valor">
                  {{ formatearValor(montoTotalSolicitado) }}
                </label>
              </div>
            </div>
          </div>

          <!-- Hospitalario o más de un reembolso. -->
          <div class="detalles" *ngIf="!esMostrarDatosCompletos">
            <!--Detalles izquierda  -->
            <div class="detalles--column">
              <div class="info-row">
                <label class="texto-descriptivo">
                  N° {{ detalleNumero }}:
                </label>
                <label class="texto-valor">
                  {{ numeroSolicitado }}
                </label>
              </div>
              <div class="info-row">
                <label class="texto-descriptivo">
                  Fecha emisión:
                </label>
                <label class="texto-valor">
                  {{ fechaActual | date: 'dd/MM/yyyy'}}
                </label>
              </div>
              <div class="info-row" *ngIf="!this.reingreso.esReingreso">
                <label class="texto-descriptivo">
                  Monto solicitado:
                </label>
                <label class="texto-valor">
                  {{ formatearValor(montoTotalSolicitado) }}
                </label>
              </div>
            </div>
            <!--Fin Detalles izquierda  -->
          </div>

          <!-- Datos bancarios  -->
          <div class="data-bank">
            <label class="sub-header">
              Tu reembolso se depositará en la siguiente cuenta bancaria:
            </label>
            <div class=" detalles--column">
              <div class="info-row">
                <label class="texto-descriptivo">
                  Banco:
                </label>
                <label class="texto-valor">
                  {{ nombreBanco | titlecase }}
                </label>
              </div>
              <div class="info-row">
                <label class="texto-descriptivo">
                  Cuenta:
                </label>
                <label class="texto-valor">
                  {{ ctaBancaria || 000123456789 }}
                </label>
              </div>
            </div>
          </div>
          <div class="mail">
            <label class="anuncio-mail">
              Pronto recibirás en tu correo el resultado de tu solicitud. <br>
              Ante cualquier consulta escríbenos a
              <a [href]="'mailto:'+this.storage.correoContacto">{{ this.storage.correoContacto }}</a>
            </label>
          </div>
        </div>
        <!--Fin Detalles  -->

        <!-- botón final-->
        <section class="reembolso__botones--container">
          <vs-button (click)="finalizar()" texto="Volver al inicio" type="primary" size="medium"></vs-button>
        </section>
      </div>
    </div>
  </div>
</section>
