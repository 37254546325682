import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { StorageService } from '@common/services/storage.service';
import { IPrestacion } from '@common/interfaces/IPrestacion';
import { SharedModule } from '@common/shared.module';

@Component({
  selector: 'paso-datos',
  templateUrl: './paso-datos.component.html',
  styleUrls: ['./paso-datos.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class PasoDatosComponent implements OnInit, OnDestroy {
  @Input() form!: UntypedFormGroup;
  @Input() porcentaje: number = -1;
  public cambiosFormulario$!: Subscription;
  public prestacionSeleccionada: IPrestacion | null = null;
  public pregunta: IPregunta[] = [
    { label: 'Sí', valor: true },
    { label: 'No', valor: false },
  ];

  constructor(
    private storage: StorageService,
    private reembolso: ReembolsoActualService,
  ) { }

  ngOnInit(): void {
    this.cambiosFormulario$ = this.form.valueChanges.subscribe((input) => {
      this.prestacionSeleccionada = this.storage.obtenerCardSeleccionada();
      if (input.isapreFonasa) {
        this.reembolso.idIsapre = this.storage.filtrarCodigoPorIsapre(input.isapreFonasa)
      };
    });
  }

  ngOnDestroy(): void {
    try {
      this.cambiosFormulario$.unsubscribe();
    } catch (error) { console.warn('¡Suscripción no existe!'); }
  }

  trackByPregunta(_: number, pregunta: IPregunta): string {
    return pregunta.label;
  }
}

interface IPregunta {
  label: string;
  valor: boolean;
}