import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DataUsuarioService } from '@common/services/data-usuario.service';
import { IPrestacion } from '@common/interfaces/IPrestacion';
import { ReembolsoActualService } from '@common/services/reembolso-actual.service';
import { StorageService } from '@common/services/storage.service';
import { SharedModule } from '@common/shared.module';
import { ServicesProvidedComponent } from '../../services-provided-card/services-provided-card.component';
import { AlertMessageComponent, WarningMessageComponent } from '@common/components';

@Component({
  selector: 'paso-prestacion',
  templateUrl: './paso-prestacion.component.html',
  styleUrls: ['./paso-prestacion.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    AlertMessageComponent,
    WarningMessageComponent,
    ServicesProvidedComponent],
})
export class PasoPrestacionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form!: UntypedFormGroup;
  @Input() porcentaje: number = -1;
  public cambiosFormulario$!: Subscription;
  public prestaciones: IPrestacion[] = this.storage.cardPrestaciones;
  public prestacionSeleccionada: IPrestacion | null = null;
  public preguntaPrestacion: boolean | undefined = undefined;
  public pregunta: IPregunta[] = [
    { label: 'Sí', valor: true },
    { label: 'No', valor: false },
  ];
  private _beneficiarioActual = '';

  constructor(
    private storage: StorageService,
    private reembolso: ReembolsoActualService,
    private dataUsuario: DataUsuarioService
  ) {}

  ngOnInit(): void {
    this.cambiosFormulario$ = this.form.valueChanges.subscribe((input) => {
      if (input.persona !== '' && this._beneficiarioActual !== input.persona) {
        setTimeout(() => {
          const coberturas: number[] =
            this.dataUsuario.usuarioConectado.obtenerCoberturasUsuarioSeleccionado();
          this.prestaciones = this.storage.cardPrestaciones.filter(
            (prestacion) => coberturas!.includes(prestacion.coberturaAsociada)
          );
          this._beneficiarioActual = input.persona;
          this.form.get('prestacion')?.setValue('');
          this.storage.reiniciarCards();
          this.prestacionSeleccionada = null;
        }, 100);
      } else {
        this.prestacionSeleccionada = this.storage.obtenerCardSeleccionada();
        if (this.prestacionSeleccionada) {
          this.reembolso.idPrestacion =
            this.prestacionSeleccionada.idPrestacion;
        }
      }
    });
  }

  ngOnChanges(): void {
    if (this.storage.coberturaSeleccionada) {
      this.prestaciones = this.prestaciones.filter(
        (prestacion) =>
          prestacion.coberturaAsociada === this.storage.coberturaSeleccionada
      );
    }
  }

  ngOnDestroy(): void {
    try {
      this.cambiosFormulario$.unsubscribe();
    } catch (error) {
      console.warn('¡Suscripción no existe!');
    }
  }

  seleccionCard(prestacion: IPrestacion): void {
    if (this.reembolso.idPrestacion !== prestacion.idPrestacion) {
      this.reembolso.vaciarArchivos();
    }
    this.storage.marcarCardSeleccionada(prestacion);
    if (
      (this.prestacionSeleccionada?.idPrestacion === 0 ||
        this.prestacionSeleccionada?.idPrestacion === 4) &&
      (prestacion.idPrestacion === 0 || prestacion.idPrestacion === 4)
    ) {
      this.form.get('prestacion')?.setValue(prestacion.idPrestacion);
    } else {
      this.form.get('prestacion')?.setValue(prestacion.idPrestacion);
      this.form.get('pregunta')?.setValue(undefined, { emitEvent: false });
      this.form.get('confirmar')?.setValue(undefined);
    }
    this.reembolso.idPrestacion = prestacion.idPrestacion;
    this.prestacionSeleccionada = prestacion;
  }

  verificarValor(item: IPregunta): boolean {
    return this.form.get('pregunta')?.value === item.valor;
  }

  checkRadio(radio: IPregunta): void {
    if (
      this.prestacionSeleccionada?.idPrestacion === 0 ||
      this.prestacionSeleccionada?.idPrestacion === 4
    ) {
      const documentoSeleccionado = parseInt(
        this.form.get('documentoPrincipal')?.value,
        10
      );
      if (documentoSeleccionado > -1 && radio.valor === false) {
        this.form.get('documentoPrincipal')?.setValue(2);
      }
    }
  }

  trackByPrestacion(_: number, prestacion: IPrestacion): number {
    return prestacion.idPrestacion;
  }

  trackByPregunta(_: number, item: IPregunta): boolean {
    return item.valor;
  }
}

interface IPregunta {
  label: string;
  valor: boolean;
}
