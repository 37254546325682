import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinWithNewLine'
})
export class JoinWithNewLinePipe implements PipeTransform {

  transform(value: string[]): string {
    return value.join('\n');
  }
}
