export const REIMBURSEMENT_EVENT_VS = {
  PAGE_VIEW: 'web_vs_historial',
  IN_CURSE_TAB: 'web_vs_reembolso_en_curso',
  HISTORICAL_TAB: 'web_vs_reembolso_en_historico',
  SHOW_HISTORICAL_DETAIL: 'web_vs_detalle_reembolso_en_historico',
  SHOW_IN_CURSE_DETAIL: 'web_vs_detalle_reembolso_en_curso',
};

export const REIMBURSEMENT_EVENT_SBS = {
  PAGE_VIEW: 'web_sbs_historial',
  IN_CURSE_TAB: 'web_sbs_reembolso_en_curso',
  HISTORICAL_TAB: 'web_sbs_reembolso_en_historico',
  SHOW_HISTORICAL_DETAIL: 'web_sbs_detalle_reembolso_en_historico',
  SHOW_IN_CURSE_DETAIL: 'web_sbs_detalle_reembolso_en_curso',
};

export const DateKey = 'LastTimeRequest';
export const MAX_CACHE_TIME_PERMITED = 30;
