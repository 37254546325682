import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MessageBoxComponent } from '@common/components';

@Component({
  selector: 'app-historical-empty-message',
  templateUrl: './historical-empty-message.component.html',
  styleUrls: ['./historical-empty-message.component.scss'],
  standalone: true,
  imports: [CommonModule, MessageBoxComponent],
})
export class HistoricalEmptyMessageComponent {
  @Input() title = '';
  @Input() message = '';
  @Input() isInCurse = true;
  @Input() isServiceError = false;

  getIcon(): string {
    if (this.isServiceError) return 'assets/svgs/warning-circle.svg';

    return this.isInCurse
      ? '/assets/svgs/in-curse-secondary.svg'
      : '/assets/svgs/historical-secondary.svg';
  }
}
