import {
  HttpClient, HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { IDocumentEngine, INotificationDocument } from '@common/interfaces';
import { DataUsuarioService } from '@common/services';

@Injectable({
  providedIn: 'root',
})
export class PdfEngineService {
  constructor(
    private http: HttpClient,
    private UserLogged: DataUsuarioService
  ) {}

  async getDocumentNta<T>(data: INotificationDocument): Promise<IDocumentEngine> {
    try{
      const path = `${environment.URL_BFF_BASE}/web/onclick/salud/Document/Nta/Insured/${this.UserLogged.usuarioConectado.rutCuerpoDv}?BeneficiaryRut=${data.BeneficiaryRut}&BeneficiaryName=${data.BeneficiaryName}&Policy=${data.Policy}&Number=${data.RequestNumber}`;
      return await this.getDocument<IDocumentEngine>(path);
    }
    catch(error: any) {
      throw new Error('No fue posible obtener el documento');
    }
  }

  async getDocumentLiq(numberRequest: number, insureCo: number): Promise<IDocumentEngine> {
    try{
      const path = `${environment.URL_BFF_BASE}/web/onclick/salud/Document/Liq/${this.UserLogged.usuarioConectado.rutCuerpoDv}/RequestNumber/${numberRequest}/company/${insureCo}`;
      return await this.getDocument<IDocumentEngine>(path);
    }
    catch(error: any) {
      throw new Error('No fue posible obtener el documento');
    }
  }

  private async getDocument<T>(url: string): Promise<IDocumentEngine>{
    const token = sessionStorage.getItem('ssoToken');
    const header = new HttpHeaders()
    try {
      const pdf = await this.http
        .get<IDocumentEngine>(
          `${url}`,
          { headers: header }
        )
        .toPromise();
      return pdf;
    } catch (error) {
      throw new Error('No fue posible obtener el documento');
    }
  }
}
