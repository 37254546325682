import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-warning-replace-file-modal',
  templateUrl: './warning-replace-file-modal.component.html',
  styleUrls: ['./warning-replace-file-modal.component.scss']
})
export class WarningReplaceFileModalComponent implements OnInit {
  @Input() scrollTop: string = 'scrollTop';
  @Input() scrollOnClose: string = 'uploadFiles';
  @Output() eventArchivo = new EventEmitter<boolean>();

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService.scrollTo(this.scrollTop);
  }

  allowUploadFile(allowedUpload : boolean): void {
    this.modalService.scrollTo(this.scrollOnClose);
    this.eventArchivo.emit(allowedUpload);
  }
}
