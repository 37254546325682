<div class="titulo" *ngIf="esTieneTexto">{{ placeholder }}</div>
<div class="contenedor" [formGroup]="form">
  <input
    [id]="nameControl"
    class="input"
    [type]="tipoInput"
    [maxlength]="maxLargo"
    [placeholder]="placeholder"
    [formControlName]="nameControl"
    (change)="perdidaFocus(nameControl)"
    (keyup)="escribiendo()"
  />
  <img *ngIf="esValido" class="input__estado" src="assets/svgs/{{icono}}.svg" />
</div>
